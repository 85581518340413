define("client/services/text-measurer", ["exports", "ember-text-measurer/services/text-measurer"], function (exports, _textMeasurer) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function get() {
      return _textMeasurer["default"];
    }
  });
});