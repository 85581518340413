define("client/helpers/offer-selected", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.offerSelected = offerSelected;
  function offerSelected(params) {
    var id = params[0];
    var offer = params[1];
    return id === offer.id;
  }

  exports["default"] = Ember.Helper.helper(offerSelected);
});