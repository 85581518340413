define("client/controllers/consignments/overdue", ["exports", "ic-ajax"], function (exports, _icAjax) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = Ember.Controller.extend({
    config: Ember.inject.service(),
    session: Ember.inject.service(),

    articleBarcode: "",
    articleRequestBarcode: "",

    queryParams: ["checkedInPage", "returnRequestPage", "perPage"],
    perPage: 6,
    custumflag: false,
    checkedInConsignments: [],
    checkedInConsignmentsLoading: true,
    checkedInPage: 1,
    checkedInTotalPages: 1,
    ConfirmPopus: false,
    downloadlabelPopus: false,
    downloadlabelAgentPopus: false,
    returnRequestedConsignments: [],
    returnRequestedConsignmentsLoading: true,
    returnRequestPage: 1,
    fetchpdf: "",
    consigmentNo: "",
    returnRequestTotalPages: 1,
    agentCourierName: "",
    externalOnlineConsignmentid: 2,
    checkOutWishConsignmentId: 0,
    //showWishDiscardPopup : true,

    idTypes: [{
      value: "Driver's Licence"
    }, {
      value: "Passport"
    }, {
      value: "Other"
    }],

    autoReload: (function () {
      var self = this;
      Ember.run.later(self, function () {
        (0, _icAjax["default"])({
          // url: self.get("config.apiURL") + 'consignments?state=awaitingCustomerPickup,notYetCheckedIn&page=' + this.get('checkedInPage') + '&perPage=' + this.get("perPage")+'&orderBy=time_waiting&orderDir=ASC&limited=true',
          url: self.get("config.apiURL") + "consignments?state=awaitingCustomerPickup&page=" + this.get("checkedInPage") + "&perPage=" + this.get("perPage") + "&orderBy=time_waiting&orderDir=ASC&limited=true",
          type: "GET",
          headers: {
            Authorization: "Bearer " + self.get("session.data").authenticated.jwt
          },
          crossDomain: true
        }).then(function (results) {
          self.set("checkedInConsignments", results.data);
          self.set("checkedInPage", results.page);
          self.set("checkedInTotalPages", results.totalPages);
          self.autoReload();
        })["catch"](function (error) {
          if (error && error.jqXHR && error.jqXHR.status === 401) {
            self.get("session").invalidate();
          }
        });
      }, self.get("config.autoRefreshSpeed"));
    }).on("init"),

    autoReload1: (function () {
      var self = this;
      Ember.run.later(self, function () {
        (0, _icAjax["default"])({
          url: self.get("config.apiURL") + "consignments?state=awaitingCourierPickup&page=" + this.get("returnRequestPage") + "&perPage=" + this.get("perPage"),
          type: "GET",
          headers: {
            Authorization: "Bearer " + self.get("session.data").authenticated.jwt
          },
          crossDomain: true
        }).then(function (results) {
          self.set("returnRequestedConsignments", results.data);
          self.set("returnRequestPage", results.page);
          self.set("returnRequestTotalPages", results.totalPages);
          self.autoReload1();
        })["catch"](function (error) {
          if (error && error.jqXHR && error.jqXHR.status === 401) {
            // Logout the user if a 401 response is received
            self.get("session").invalidate();
          } else {
            self.autoReload1();
          }
        });
      }, self.get("config.autoRefreshSpeed"));
    }).on("init"),

    validateSignatureOrPin: function validateSignatureOrPin() {
      return this.get("checkoutCommon.signature").length !== 0 || this.get("checkoutCommon.pin").length === 4;
    },

    actions: {
      getPageReturnRequested: function getPageReturnRequested(page) {
        var self = this;
        self.set("returnRequestedConsignmentsLoading", true);
        (0, _icAjax["default"])({
          url: self.get("config.apiURL") + "consignments?state=awaitingCourierPickup&page=" + page + "&perPage=" + this.get("perPage") + "&orderBy=time_waiting&orderDir=ASC&limited=true",
          type: "get",
          headers: {
            Authorization: "Bearer " + self.get("session.data").authenticated.jwt
          },
          crossDomain: true
        }).then(function (results) {
          self.set("returnRequestedConsignments", results.data);
          self.set("returnRequestPage", results.page);
          self.set("returnRequestTotalPages", results.totalPages);
          self.set("returnRequestedConsignmentsLoading", false);
        })["catch"](function (error) {
          if (error && error.jqXHR && error.jqXHR.status === 401) {
            // Logout the user if a 401 response is received
            self.get("session").invalidate();
          }
          self.set("returnRequestedConsignmentsLoading", false);
        });
      },

      getPageCheckedIn: function getPageCheckedIn(page) {
        var self = this;
        self.set("checkedInConsignmentsLoading", true);
        (0, _icAjax["default"])({
          // url: self.get("config.apiURL") + 'consignments?state=awaitingCustomerPickup,notYetCheckedIn&page=' + page + '&perPage=' + this.get("perPage")+'&orderBy=time_waiting&orderDir=ASC&limited=true',
          url: self.get("config.apiURL") + "consignments?state=awaitingCustomerPickup&page=" + page + "&perPage=" + this.get("perPage") + "&orderBy=time_waiting&orderDir=ASC&limited=true",
          type: "get",
          headers: {
            Authorization: "Bearer " + self.get("session.data").authenticated.jwt
          },
          crossDomain: true
        }).then(function (results) {
          self.set("checkedInConsignments", results.data);
          self.set("checkedInPage", results.page);
          self.set("checkedInTotalPages", results.totalPages);
          self.set("checkedInConsignmentsLoading", false);
        })["catch"](function (error) {
          console.log(error, "errrrr2");
          if (error && error.jqXHR && error.jqXHR.status === 401) {
            self.get("session").invalidate();
          }
          self.set("checkedInConsignmentsLoading", false);
        });
      },

      onWishCheckout: function onWishCheckout(consignmentId) {
        this.set("showWishDiscardPopup", true);
        this.set("checkOutWishConsignmentId", consignmentId);
      },

      onCloseWish: function onCloseWish() {
        this.set("showWishDiscardPopup", false);
      },

      onDiscardWishbutton: function onDiscardWishbutton() {
        var self = this;
        var ConsignmnetNum = this.get("checkOutWishConsignmentId");
        var data = { consignments: [ConsignmnetNum] };
        (0, _icAjax["default"])({
          url: this.get("config.apiURL") + "consignments/discard",
          type: "POST",
          headers: {
            Authorization: "Bearer " + this.get("session.data").authenticated.jwt
          },
          data: JSON.stringify(data),
          contentType: "application/json",
          crossDomain: true
        }).then(function (result) {
          if (result.consignments.length > 0 && result.consignments[0].success == true) {
            self.send("onCloseWish");
            self.send("reload");
          } else {
            self.controllerFor("application").send("showSpinner");
            self.controllerFor("application").send("hideSpinner", 1500);
            self.controllerFor("application").send("showError", result.consignments[0].error);
            self.send("onCloseWish");
          }
        })["catch"](function (error) {
          if (error && error.jqXHR && error.jqXHR.status === 401) {
            // Logout the user if a 401 response is received
            self.get("session").invalidate();
          }
        });
      },

      returnArticle: function returnArticle() {
        if (this.get("articleBarcode").length > 0) {
          var self = this;
          self.controllerFor("application").send("showSpinner");
          var consignmentData = {
            articleBarcode: this.get("articleBarcode")
          };
          (0, _icAjax["default"])({
            url: self.get("config.apiURL") + "articles/return",
            type: "POST",
            headers: {
              Authorization: "Bearer " + self.get("session.data").authenticated.jwt
            },
            data: JSON.stringify(consignmentData),
            contentType: "application/json",
            crossDomain: true
          }).then(function () /*result*/{
            self.send("reload");
            self.controllerFor("application").send("showSuccess");
            self.controllerFor("application").send("hideSpinner", 850);
            self.set("articleBarcode", "");
          }, function (e) {
            /*error*/
            self.send("reload");

            if (e.jqXHR.status === 401) {
              // Logout the user if a 401 response is received
              self.get("session").invalidate();
            }

            if (e.jqXHR.status === 400) {
              self.controllerFor("application").send("showError", "No return requested");
            } else if (e.jqXHR.status === 404) {
              self.controllerFor("application").send("showError", "Article barcode not found");
            } else {
              self.controllerFor("application").send("showError", "Error");
            }
            self.controllerFor("application").send("hideSpinner", 1500);
          });
        }
      },
      barcodeReturnToCourier: function barcodeReturnToCourier(articleBarcode) {
        var self = this;
        self.controllerFor("application").send("showSpinner");
        var consignmentData = {
          articleBarcode: articleBarcode
        };
        (0, _icAjax["default"])({
          url: self.get("config.apiURL") + "articles/return",
          type: "POST",
          headers: {
            Authorization: "Bearer " + self.get("session.data").authenticated.jwt
          },
          data: JSON.stringify(consignmentData),
          contentType: "application/json",
          crossDomain: true
        }).then(function () /*result*/{
          self.send("reloadReturnRequestedParcels");
          self.controllerFor("application").send("showSuccess");
          self.controllerFor("application").send("hideSpinner", 850);
          self.set("articleBarcode", "");
        }, function (e) {
          /*error*/
          self.send("reloadReturnRequestedParcels");

          if (e.jqXHR.status === 401) {
            // Logout the user if a 401 response is received
            self.get("session").invalidate();
          }

          if (e.jqXHR.status === 400) {
            self.controllerFor("application").send("showError", "No return requested");
          } else if (e.jqXHR.status === 404) {
            self.controllerFor("application").send("showError", "Article barcode not found");
          } else {
            self.controllerFor("application").send("showError", "Error");
          }
          self.controllerFor("application").send("hideSpinner", 1500);
        });
      },

      // const link = document.createElement("a");
      // link.href = url;
      // link.setAttribute("download", `zf_template_${state.selectedClient}.html`);
      // document.body.appendChild(link);
      // link.click();

      downloadpdf: function downloadpdf() {
        var a = document.createElement("a");
        a.href = this.get("fetchpdf");
        a.download = "file.pdf";
        a.dispatchEvent(new MouseEvent("click"));
        this.set("downloadlabelPopus", false);
        this.set("downloadlabelAgentPopus", false);
        this.set("consigmentNo", "");
      },
      confirmpopup: function confirmpopup() {
        this.set("ConfirmPopus", false);
        this.controllerFor("application").send("showSpinner");
        this.controllerFor("application").send("showSuccess");
        this.controllerFor("application").send("hideSpinner", 805);
      },

      consignmentRequestReturn: function consignmentRequestReturn(consignment) {
        var self = this;
        var consignmentData = {
          consignmentNumber: consignment.consignmentNumber
        };

        self.controllerFor("application").send("showSpinner");
        (0, _icAjax["default"])({
          url: self.get("config.apiURL") + "consignments/requestReturn",
          type: "POST",
          headers: {
            Authorization: "Bearer " + self.get("session.data").authenticated.jwt
          },
          data: JSON.stringify(consignmentData),
          contentType: "application/json",
          crossDomain: true
        }).then(function (result) {
          self.set("fetchpdf", result.externalOnlineLabel);
          self.set("consigmentNo", result.consignmentNumber);
          if (result && result.productType == 2 && result.externalOnlineLabel == null) {
            self.controllerFor("application").send("hideSpinner", 5);
            self.set("ConfirmPopus", true);
          } else if (result && result.productType == 1 && result.externalOnlineLabel && result.courier != "ebay" && result.cncData.enabledAgentReturns) {
            /// For New Variable Ke Basis Pe

            self.controllerFor("application").send("hideSpinner", 5);
            self.set("downloadlabelAgentPopus", true);
            //  self.set("agentCourierName", result.courierName);
            self.set("agentCourierName", result.cncData.retailer);
          } else if (result && result.productType == 2 && result.externalOnlineLabel) {
            /// Ebay

            self.controllerFor("application").send("hideSpinner", 5);
            self.set("downloadlabelPopus", true);
          }
          // else if(result && result.productType  == 2 && result.externalOnlineLabel && result.Newvariable Jo Bji){  /// For New Variable Ke Basis Pe

          //   self.controllerFor('application').send('hideSpinner',5);
          //    self.set("downloadlabelPopus", true);
          // }        
          else {
            self.controllerFor("application").send("showSuccess");
            self.controllerFor("application").send("hideSpinner", 850);
          }
          self.send("reload");
        }, function () /*error*/{
          self.controllerFor("application").send("showError", "Please try again later, or use the sidebar");
          self.controllerFor("application").send("hideSpinner", 850);
          self.send("reload");
        });
      },

      reprintLabel: function reprintLabel(consignmentid) {
        var self = this;
        self.controllerFor("application").send("showSpinner");
        (0, _icAjax["default"])({
          url: self.get("config.apiURL") + ("consignments/" + consignmentid + "/getLabelUrl"),
          type: "get",
          headers: {
            Authorization: "Bearer " + self.get("session.data").authenticated.jwt
          },
          // data: JSON.stringify({}),
          contentType: "application/json",
          crossDomain: true
        }).then(function (result) {
          window.open(result.labelUrl);
          self.controllerFor("application").send("showSuccess");
          self.controllerFor("application").send("hideSpinner", 850);
          self.send("reload");
        }, function () /*error*/{
          self.controllerFor("application").send("showError", "Please try again later, or use the sidebar");
          self.controllerFor("application").send("hideSpinner", 850);
          self.send("reload");
        });
      },

      articleRequestReturn: function articleRequestReturn() {
        var self = this;
        var articleData = {
          articleBarcode: this.get("articleRequestBarcode")
        };
        self.controllerFor("application").send("showSpinner");
        (0, _icAjax["default"])({
          url: self.get("config.apiURL") + "articles/requestReturn",
          type: "POST",
          headers: {
            Authorization: "Bearer " + self.get("session.data").authenticated.jwt
          },
          data: JSON.stringify(articleData),
          contentType: "application/json",
          crossDomain: true
        }).then(function () /*result*/{

          self.controllerFor("application").send("showSuccess");
          self.controllerFor("application").send("hideSpinner", 850);
          self.set("articleRequestBarcode", "");
          self.send("reload");
        }, function () /*error*/{
          self.controllerFor("application").send("showError", "Barcode does not match a package");
          self.controllerFor("application").send("hideSpinner", 1500);
          self.send("reload");
        })["catch"](function (error) {
          if (error && error.jqXHR && error.jqXHR.status === 401) {
            // Logout the user if a 401 response is received
            self.get("session").invalidate();
          }
        });
      },

      reload: function reload() {
        var self = this;

        function handleErrorResponse(error) {
          if (error && error.jqXHR && error.jqXHR.status === 401) {
            self.get("session").invalidate();
          }
        }

        (0, _icAjax["default"])({
          url: self.get("config.apiURL") + "consignments?state=awaitingCustomerPickup&page=1&perPage=" + this.get("perPage") + "&orderBy=time_waiting&orderDir=ASC&limited=true",
          type: "get",
          headers: {
            Authorization: "Bearer " + self.get("session.data").authenticated.jwt
          },
          crossDomain: true
        }).then(function (results) {
          self.set("checkedInConsignments", results.data);
          self.set("checkedInPage", results.page);
          self.set("checkedInTotalPages", results.totalPages);

          (0, _icAjax["default"])({
            url: self.get("config.apiURL") + "consignments?state=awaitingCourierPickup&page=1&perPage=" + self.get("perPage") + "&orderBy=time_waiting&orderDir=ASC&limited=true",
            type: "get",
            headers: {
              Authorization: "Bearer " + self.get("session.data").authenticated.jwt
            },
            crossDomain: true
          }).then(function (results) {
            self.set("returnRequestedConsignments", results.data);
            self.set("returnRequestPage", results.page);
            self.set("returnRequestTotalPages", results.totalPages);
            self.set("articleBarcode", "");
          })["catch"](handleErrorResponse);
        })["catch"](handleErrorResponse);
      },

      reloadReturnRequestedParcels: function reloadReturnRequestedParcels() {
        this.set("returnRequestedConsignmentsLoading", true);
        var self = this;
        (0, _icAjax["default"])({
          url: self.get("config.apiURL") + "consignments?state=awaitingCourierPickup&page=1&perPage=" + self.get("perPage") + "&orderBy=time_waiting&orderDir=ASC&limited=true",
          type: "get",
          headers: {
            Authorization: "Bearer " + self.get("session.data").authenticated.jwt
          },
          crossDomain: true
        }).then(function (results) {
          self.set("returnRequestedConsignments", results.data);
          self.set("returnRequestedConsignmentsLoading", false);
          self.set("returnRequestPage", results.page);
          self.set("returnRequestTotalPages", results.totalPages);
          self.set("articleBarcode", "");
        })["catch"](function (error) {
          if (error && error.jqXHR && error.jqXHR.status === 401) {
            self.get("session").invalidate();
          }
        });
      },
      //Manual override
      onManualCheckout: function onManualCheckout(consginmentId) {
        var self = this;
        self.controllerFor("application").send("showSpinner");
        (0, _icAjax["default"])({
          url: self.get("config.apiURL") + "consignments/" + consginmentId,
          type: "get",
          headers: {
            Authorization: "Bearer " + self.get("session.data").authenticated.jwt
          },
          crossDomain: true
        }).then(function (consignment) {
          var receiverNameStr = "";
          if (consignment && consignment.receiver && consignment.receiver.name && consignment.receiver.name !== "") {
            receiverNameStr = consignment.receiver.name;
          }
          if (consignment && consignment.receiver && consignment.receiver.name == "") {
            self.set("custumflag", true);
          } else {
            self.set("custumflag", false);
          }
          self.set("checkoutWorkflow", "manual");
          self.set("manualCheckout", {
            reason: "",
            consignment: consignment
          });
          self.set("checkoutCommon", {
            receiver: receiverNameStr,
            idType: "",
            idLast4: "",
            signature: "",
            pin: ""
          });
          self.set("showManualReason", true);
          self.controllerFor("application").send("hideSpinner");
        }, function () /*error*/{
          self.controllerFor("application").send("showError");
          self.controllerFor("application").send("hideSpinner", 1500);
        });
      },
      onCloseManualReason: function onCloseManualReason() {
        this.set("checkoutWorkflow", null);
        this.set("showManualReason", false);
      },
      //Common Checkout Parts
      onRequestPin: function onRequestPin(consignmentId) {
        var couponFlag = this.get("custumflag");
        this.set("showManualReason", false);
        this.set("showArticles", false);
        var phoneNo = this.get("checkoutCommon.phone");
        if (this.get("checkoutWorkflow") === "emPod" && this.get("emPodDetails.receiver_phone") && this.get("emPodDetails.receiver_phone").length > 0) {
          this.set("emPodDetails.pin_phone", this.get("emPodDetails.receiver_phone"));
          var self = this;
          self.controllerFor("application").send("showSpinner");
          (0, _icAjax["default"])({
            url: self.get("config.apiURL") + "v2/consignments/manualPinSend",
            type: "post",
            headers: {
              Authorization: "Bearer " + self.get("session.data").authenticated.jwt
            },
            data: JSON.stringify({
              phone: self.get("emPodDetails.pin_phone"),
              courier: self.get("emPodDetails.courier")
            }),
            contentType: "application/json",
            crossDomain: true
          }).then(function () /*result*/{
            self.controllerFor("application").send("hideSpinner", 0);
            self.set("showPin", true);
            self.set("showEmPod", false);
          }, function () {
            self.controllerFor("application").send("showError", "Error");
            self.controllerFor("application").send("hideSpinner", 850);
          });
        } else if (couponFlag) {
          var self = this;
          self.controllerFor("application").send("showSpinner");
          (0, _icAjax["default"])({
            url: self.get("config.apiURL") + "consignments/" + consignmentId + "/resendPin",
            type: "post",
            headers: {
              Authorization: "Bearer " + self.get("session.data").authenticated.jwt
            },
            data: JSON.stringify({
              mobile: phoneNo
            }),
            contentType: "application/json",
            crossDomain: true
          }).then(function () /*result*/{
            // console.log('resultPER', result);
            self.controllerFor("application").send("hideSpinner", 0);
            self.set("showPin", true);
            self.set("showEmPod", false);
          }, function () {
            self.controllerFor("application").send("showError", "Invalid Phone number");
            self.controllerFor("application").send("hideSpinner", 850);
            self.set("showManualReason", true);
          });
        } else {
          this.set("showPin", true);
          this.set("showEmPod", false);
        }
      },

      onClosePin: function onClosePin() {
        this.set("showPin", false);
        this.set("checkoutWorkflow", null);
      },

      onConfirmPin: function onConfirmPin() {
        if (this.validateSignatureOrPin()) {
          var self = this;
          if (this.get("checkoutWorkflow") === "emPod") {
            self.controllerFor("application").send("showSpinner");
            (0, _icAjax["default"])({
              url: self.get("config.apiURL") + "v2/consignments/manualPinValidate",
              type: "post",
              headers: {
                Authorization: "Bearer " + self.get("session.data").authenticated.jwt
              },
              data: JSON.stringify({
                phone: self.get("emPodDetails.pin_phone"),
                pin: self.get("checkoutCommon.pin")
              }),
              contentType: "application/json",
              crossDomain: true
            }).then(function () /*result*/{
              self.controllerFor("application").send("hideSpinner", 0);
              self.set("showPin", false);
              self.set("showCheckoutConfirmation", true);
            }, function () {
              self.controllerFor("application").send("showError", "Error");
              self.controllerFor("application").send("hideSpinner", 850);
            });
          } else {
            self.controllerFor("application").send("showSpinner");
            var consignmentId = this.get("checkoutWorkflow") === "manual" ? self.get("manualCheckout.consignment.id") : self.get("regularCheckout.consignment.id");
            (0, _icAjax["default"])({
              url: self.get("config.apiURL") + "consignments/" + consignmentId + "/validatePin",
              type: "post",
              headers: {
                Authorization: "Bearer " + self.get("session.data").authenticated.jwt
              },
              data: JSON.stringify({
                pin: self.get("checkoutCommon.pin")
              }),
              contentType: "application/json",
              crossDomain: true
            }).then(function () /*result*/{
              self.controllerFor("application").send("hideSpinner", 0);
              self.set("showPin", false);
              self.set("showCheckoutConfirmation", true);
            }, function () {
              self.controllerFor("application").send("showError", "Invalid Pin");
              self.controllerFor("application").send("hideSpinner", 850);
            });
          }
        }
      },

      onRequestSignature: function onRequestSignature() {
        this.set("showEmPod", false);
        this.set("showSignature", true);
        this.set("showPin", false);
      },

      onClearSignature: function onClearSignature() {
        this.set("checkoutCommon.signature", "");
      },

      onConfirmSignature: function onConfirmSignature() {
        if (this.validateSignatureOrPin()) {
          this.set("showSignature", false);
          this.set("showCheckoutConfirmation", true);
        }
      },

      onPinResend: function onPinResend() {
        this.set("showPinResend", true);
        if (this.get("checkoutWorkflow") === "emPod" && this.get("emPodDetails.receiver_phone") && this.get("emPodDetails.receiver_phone").length > 0) {
          this.set("resendMobile", this.get("emPodDetails.receiver_phone"));
        }
        this.set("showPin", false);
      },

      onCloseSignature: function onCloseSignature() {
        this.set("showSignature", false);
        this.set("checkoutWorkflow", null);
      },

      onClosePinResend: function onClosePinResend() {
        this.set("showPinResend", false);
        this.set("checkoutWorkflow", null);
      },

      onRequestResendPin: function onRequestResendPin() {
        if (this.get("resendMobile").length > 8) {
          var self = this;
          if (this.get("checkoutWorkflow") === "emPod") {
            this.set("emPodDetails.pin_phone", this.get("resendMobile"));
            self.controllerFor("application").send("showSpinner");
            (0, _icAjax["default"])({
              url: self.get("config.apiURL") + "v2/consignments/manualPinSend",
              type: "post",
              headers: {
                Authorization: "Bearer " + self.get("session.data").authenticated.jwt
              },
              data: JSON.stringify({
                phone: self.get("emPodDetails.pin_phone"),
                courier: self.get("emPodDetails.courier")
              }),
              contentType: "application/json",
              crossDomain: true
            }).then(function () /*result*/{
              self.controllerFor("application").send("hideSpinner", 0);
              self.set("showPin", true);
              self.set("showPinResend", false);
            }, function () {
              self.controllerFor("application").send("showError", "Error");
              self.controllerFor("application").send("hideSpinner", 850);
            });
          } else {
            self.controllerFor("application").send("showSpinner");
            var consignmentId = this.get("checkoutWorkflow") === "manual" ? self.get("manualCheckout.consignment.id") : self.get("regularCheckout.consignment.id");
            (0, _icAjax["default"])({
              url: self.get("config.apiURL") + "consignments/" + consignmentId + "/resendPin",
              type: "post",
              headers: {
                Authorization: "Bearer " + self.get("session.data").authenticated.jwt
              },
              data: JSON.stringify({
                mobile: self.get("resendMobile")
              }),
              contentType: "application/json",
              crossDomain: true
            }).then(function () /*result*/{
              self.controllerFor("application").send("showSuccess", "Pin Resent");
              self.controllerFor("application").send("hideSpinner", 550);
              self.set("showPinResend", false);
              self.set("showPin", true);
            }, function () {
              self.controllerFor("application").send("showError", "Unknown Error");
              self.controllerFor("application").send("hideSpinner", 850);
            });
          }
        }
      },
      onCheckout: function onCheckout() {
        var self = this;
        if (this.get("checkoutWorkflow") === "manual" || this.get("checkoutWorkflow") === "normal") {
          var consignmentId = this.get("checkoutWorkflow") === "manual" ? this.get("manualCheckout.consignment.id") : this.get("regularCheckout.consignment.id");
          self.controllerFor("application").send("showSpinner");
          var data = this.get("checkoutWorkflow") === "manual" ? {
            pin: this.get("checkoutCommon.pin").length > 0 ? this.get("checkoutCommon.pin") : null,
            receiver: this.get("checkoutCommon.receiver"),
            identityType: this.get("checkoutCommon.idType"),
            identityLast4: this.get("checkoutCommon.idLast4"),
            reason: this.get("manualCheckout.reason"),
            articles: this.get("manualCheckout.consignment.articles").map(function (a) {
              return {
                barcode: a.barcode
              };
            }),
            signature: this.get("checkoutCommon.signature").length > 0 ? this.get("checkoutCommon.signature") : null
          } : {
            receiver: this.get("checkoutCommon.receiver"),
            identityType: this.get("checkoutCommon.idType"),
            identityLast4: this.get("checkoutCommon.idLast4"),
            pin: this.get("checkoutCommon.pin").length > 0 ? this.get("checkoutCommon.pin") : null,
            articles: this.get("regularCheckout.articles").map(function (a) {
              return {
                barcode: a.barcode
              };
            }),
            signature: this.get("checkoutCommon.signature").length > 0 ? this.get("checkoutCommon.signature") : null
          };
          (0, _icAjax["default"])({
            url: self.get("config.apiURL") + "consignments/" + consignmentId + "/checkout",
            type: "post",
            headers: {
              Authorization: "Bearer " + self.get("session.data").authenticated.jwt
            },
            data: JSON.stringify(data),
            contentType: "application/json",
            crossDomain: true
          }).then(function () /*result*/{
            self.controllerFor("application").send("showSuccess");
            self.controllerFor("application").send("hideSpinner", 850);
            self.set("showCheckoutConfirmation", false);
            self.send("reload");
          }, function () /*error*/{
            self.controllerFor("application").send("showError");
            self.controllerFor("application").send("hideSpinner", 850);
          });
        } else if (this.get("checkoutWorkflow") === "emPod") {
          self.controllerFor("application").send("showSpinner");
          (0, _icAjax["default"])({
            url: self.get("config.apiURL") + "v2/consignments/manualCheckout",
            type: "post",
            headers: {
              Authorization: "Bearer " + self.get("session.data").authenticated.jwt
            },
            data: JSON.stringify({
              consignmentDetails: {
                consignmentNumber: this.get("emPodDetails.consignmentNumber"),
                courier: this.get("emPodDetails.courier"),
                articles: this.get("emPodDetails.articles").map(function (a) {
                  return [a.barcode];
                }),
                receiverName: this.get("checkoutCommon.receiver"),
                receiverAddress: this.get("emPodDetails.receiver_address"),
                receiverPhone: this.get("emPodDetails.pin_phone"),
                receiverEmail: null
              },
              checkoutDetails: {
                receiver: this.get("checkoutCommon.receiver"),
                identityType: this.get("checkoutCommon.idType"),
                identityLast4: this.get("checkoutCommon.idLast4"),
                signature: this.get("checkoutCommon.signature").length > 0 ? this.get("checkoutCommon.signature") : null,
                pin: this.get("checkoutCommon.pin").length > 0 ? this.get("checkoutCommon.pin") : null
              }
            }),
            contentType: "application/json",
            crossDomain: true
          }).then(function () /*result*/{
            self.controllerFor("application").send("showSuccess");
            self.controllerFor("application").send("hideSpinner", 850);
            self.set("showCheckoutConfirmation", false);
            self.send("reload");
          }, function () /*error*/{
            self.controllerFor("application").send("showError");
            self.controllerFor("application").send("hideSpinner", 850);
          });
        }
      }

    }
  });
});