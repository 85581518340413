define("client/controllers/paperless/checkin", ["exports", "ic-ajax", "client/helpers/product-helper"], function (exports, _icAjax, _productHelper) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = Ember.Controller.extend({
    config: Ember.inject.service(),
    session: Ember.inject.service(),

    consignments: [],
    consignmentsLoading: true,
    duplicateEbayParcel: false,
    queryParams: ["page", "perPage"],
    page: 1,
    perPage: 6,
    totalPages: 1,

    downloadlabelPopus: false,
    cardNumber: "",
    logErrorConsignmentDetails: null,
    eBayManualCheckoutDetails: null,
    hubbedManualCheckoutDetails: null,
    enableEbayManualCheckInBool: false,
    showEbayManualBtnBool: false,
    showErrorForInfo: false,

    filterName: "",
    searchedEcpCodeArr: [],
    filterShow: false,

    idTypes: [{
      value: "Driver's Licence"
    }, {
      value: "Passport"
    }, {
      value: "Other"
    }],

    /* for delay seaching while typing */
    events: Ember.A(),

    inputTextObservor: (function () {
      Ember.run.debounce(this, this.searchText, 1000);
    }).observes("filterName"),

    searchText: function searchText() {
      //console.info(this.get('filterName')); 
      this.send("getPage", 1);
    },
    /* for delay seaching while typing ENDS */

    // http://13.237.245.207:8889/v2/consumer/paperless?page=1&perPage=6

    autoReload: (function (consignmentexternalOnlineId) {

      var self = this;
      var consignmentt = this.get("consignments");
      var singleValue = consignmentt.map(function (lockerobject) {
        return lockerobject;
      });
      var result = singleValue.filter(function (item) {
        return item.externalOnlineId === consignmentexternalOnlineId;
      });
      this.set("popvalue", result);

      Ember.run.later(self, function () {
        var url = self.get("config.apiURL") + "v2/consumer/paperless?&page=" + this.get("page") + "&perPage=" + this.get("perPage");
        if (this.get("filterName")) {
          url += "&name=" + encodeURIComponent(this.get("filterName"));
          //url += "&filter=" + this.get('filterName');
        }
        //url+="&orderBy=created&orderDir=DESC";
        (0, _icAjax["default"])({
          url: url,
          type: "GET",
          headers: {
            Authorization: "Bearer " + self.get("session.data").authenticated.jwt
          },
          crossDomain: true
        }).then(function (results) {

          self.set("consignments", results.data);
          self.set("page", results.page);
          self.set("totalPages", results.totalPages);
          self.autoReload();
        });
      }, self.get("config.autoRefreshSpeed"));
    }).on("init"),

    currentArticlesAllScanned: (function () {
      var articles = this.get("regularCheckout.articles");
      return articles.filterBy("verified", true).get("length") === articles.get("length");
    }).property("regularCheckout.articles.@each.verified"),

    validateReceiver: function validateReceiver() {
      this.set("checkoutDataErrors.receiver", !this.get("checkoutData.receiver") || this.get("checkoutData.receiver").length === 0);
      this.set("checkoutData.verified", true);

      return !this.get("checkoutDataErrors.receiver");
    },

    validateSignatureOrPin: function validateSignatureOrPin() {
      return this.get("checkoutCommon.signature").length !== 0 || this.get("checkoutCommon.pin").length === 4;
    },

    validEmPod: (function () {
      var articles = this.get("emPodDetails.articles");
      var validArticles = true;
      articles.forEach(function (a) {
        if (a.barcode.length === 0) {
          validArticles = false;
        }
      });
      if (this.get("emPodDetails.courier") === "tnt") {
        var validateBool = this.get("emPodDetails.courier") !== null && this.get("emPodDetails.courier").length > 0 && this.get("emPodDetails.consignmentNumber") !== null && this.get("emPodDetails.consignmentNumber").length > 0;
      } else {
        var validateBool = validArticles && this.get("emPodDetails.courier") !== null && this.get("emPodDetails.courier").length > 0 && this.get("emPodDetails.consignmentNumber") !== null && this.get("emPodDetails.consignmentNumber").length > 0 && this.get("checkoutCommon.receiver") !== null && this.get("checkoutCommon.receiver").length > 0 && this.get("checkoutCommon.idType") !== null && this.get("checkoutCommon.idType").length > 0 && this.get("checkoutCommon.idLast4") !== null && this.get("checkoutCommon.idLast4").length === 4 && this.get("emPodDetails.receiver_address") !== null && this.get("emPodDetails.receiver_address").length > 0 && this.get("emPodDetails.receiver_phone") !== null && this.get("emPodDetails.receiver_phone").length > 0;
      }
      return validateBool;
    }).property("checkoutCommon.receiver", "checkoutCommon.idType", "checkoutCommon.idLast4", "emPodDetails.articles.@each.barcode", "emPodDetails.courier", "emPodDetails.consignmentNumber", "emPodDetails.receiver_address", "emPodDetails.receiver_phone"),

    validEbaySearch: (function () {
      var validateBool = true && this.get("eBayManualCheckoutDetails.firstname") !== null && this.get("eBayManualCheckoutDetails.firstname") !== "" && this.get("eBayManualCheckoutDetails.firstname").length > 0 && this.get("eBayManualCheckoutDetails.lastname") !== null && this.get("eBayManualCheckoutDetails.lastname") !== "" && this.get("eBayManualCheckoutDetails.lastname").length > 0;

      return validateBool;
    }).property("eBayManualCheckoutDetails.firstname", "eBayManualCheckoutDetails.lastname"),

    validEbayManualCheckout: (function () {
      var regexOld = RegExp("eCP:[a-zA-Z0-9]{8}");
      var regex = RegExp("ebay:[a-zA-Z0-9]{7}");

      var ecpCodeStr = this.get("eBayManualCheckoutDetails.ecpCode");

      var validateBool = true && this.get("eBayManualCheckoutDetails.firstname") !== null && this.get("eBayManualCheckoutDetails.firstname") !== "" && this.get("eBayManualCheckoutDetails.firstname").length > 0 && (
      //(ecpCodeStr !== null && ecpCodeStr !== "" && ecpCodeStr.length > 0 && ecpCodeStr.length === 12 && regex.test(ecpCodeStr)) &&
      regex.test(ecpCodeStr) || regexOld.test(ecpCodeStr)) && this.get("eBayManualCheckoutDetails.lastname") !== null && this.get("eBayManualCheckoutDetails.lastname") !== "" && this.get("eBayManualCheckoutDetails.lastname").length > 0;

      return validateBool;
    }).property("eBayManualCheckoutDetails.firstname", "eBayManualCheckoutDetails.ecpCode", "eBayManualCheckoutDetails.lastname"),

    validHubbedManualCheckout: (function () {
      var regexFullName = /^[a-zA-Z]+(?:\s[a-zA-Z]+)+$/;
      var regexEmail = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      var fullNameStr = this.get("hubbedManualCheckoutDetails.fullname");
      var emailStr = this.get("hubbedManualCheckoutDetails.email");
      var phoneStr = this.get("hubbedManualCheckoutDetails.phone");
      var validateBool = true && fullNameStr !== null && fullNameStr !== "" && fullNameStr.length > 0 && regexFullName.test(fullNameStr) && this.get("hubbedManualCheckoutDetails.hubbed_id") !== null && this.get("hubbedManualCheckoutDetails.hubbed_id") !== "" && this.get("hubbedManualCheckoutDetails.hubbed_id").length > 0 && this.get("hubbedManualCheckoutDetails.barcode") !== null && this.get("hubbedManualCheckoutDetails.barcode") !== "" && this.get("hubbedManualCheckoutDetails.barcode").length > 0 && this.get("hubbedManualCheckoutDetails.consignment_number") !== null && this.get("hubbedManualCheckoutDetails.consignment_number") !== "" && this.get("hubbedManualCheckoutDetails.consignment_number").length > 0 && emailStr !== null && emailStr !== "" && emailStr.length > 0 && regexEmail.test(emailStr) && !isNaN(phoneStr);
      return validateBool;
    }).property("hubbedManualCheckoutDetails.fullname", "hubbedManualCheckoutDetails.hubbed_id", "hubbedManualCheckoutDetails.barcode", "hubbedManualCheckoutDetails.consignment_number", "hubbedManualCheckoutDetails.phone", "hubbedManualCheckoutDetails.email"),

    couriers: [],

    checkoutWorkflow: null,
    checkoutCommon: {
      receiver: "",
      idType: "",
      idLast4: "",
      signature: "",
      pin: ""
    },
    manualCheckout: {
      reason: "",
      consignment: null
    },

    actions: {
      refreshAuthToken: function refreshAuthToken(methodToCall) {
        var self = this;
        (0, _icAjax["default"])({
          url: self.get("config.apiURL") + "v2/admin/auth/validateToken",
          type: "GET",
          headers: { Authorization: "Bearer " + self.get("session.data").authenticated.jwt },
          contentType: "application/json",
          crossDomain: true
        }).then(function (response) {
          var responseData = JSON.parse(response);
          self.get("session").set("data.authenticated.dotnet_auth", responseData.responseMessage);
          self.send(methodToCall);
        }, function (error) {
          self.controllerFor("application").send("showError", "Error while submitting your request");
          self.controllerFor("application").send("hideSpinner", 850);
          self.set("logErrorConsignmentDetails", null);
          self.set("showErrorForInfo", false);
          self.set("showModal", null);
        });
      },

      openCheckinModal: function openCheckinModal() {
        this.set("showCheckinModal", true);
        this.set("showAttachLabelModal", false);
      },
      switchManualCheckin: function switchManualCheckin() {
        this.set("ebayManualCheckinSelection", !this.get("ebayManualCheckinSelection"));
      },
      closeEbayManualCheckoutModal: function closeEbayManualCheckoutModal() {
        this.set("autoPopulateBool", false);
        this.set("enableEbayManualCheckInBool", false);
        this.set("ebayManualCheckinSelection", true);
        this.set("eBayManualCheckoutDetails", { firstname: "", lastname: "", ecpCode: "" });
        this.set("hubbedManualCheckoutDetails", { fullname: "", hubbed_id: "", consignment_number: "", barcode: "", phone: "", email: "" });
        this.set("showModal", null);
      },
      showFilter: function showFilter() {
        this.set("filterShow", !this.get("filterShow"));
        if (this.get("filterName")) {
          this.set("filterName", "");
          //  this.send("getPage", 1);
        } else {
          this.set("searchedEcpCodeArr", []);
          //  this.send("getPage", 1);
        }
      },

      filter: function filter() {
        this.send("getPage", 1);
      },

      // http://13.237.245.207:8889/v2/consumer/paperless?page=1&perPage=6

      getPage: function getPage(page) {
        var self = this;
        self.set("consignmentsLoading", true);
        var url = self.get("config.apiURL") + "v2/consumer/paperless?page=" + page + "&perPage=" + this.get("perPage");
        if (this.get("filterName")) {
          url += "&filter=" + encodeURIComponent(this.get("filterName"));
        }
        (0, _icAjax["default"])({
          url: url,
          type: "get",
          headers: {
            Authorization: "Bearer " + self.get("session.data").authenticated.jwt
          },
          crossDomain: true
        }).then(function (result) {
          self.set("consignments", result.results);
          self.set("page", result.page);
          self.set("totalPages", result.totalPages);
          self.set("consignmentsLoading", false);
        }, function () {
          if (error && error.jqXHR && error.jqXHR.status === 401) {
            self.get("session").invalidate();
          } else {
            self.set("consignmentsLoading", false);
          }
        });
      },

      //EMPod
      onFullManualCheckout: function onFullManualCheckout() {
        this.set("checkoutWorkflow", "emPod");
        this.set("showEmPod", true);
        this.set("checkoutCommon", {
          receiver: "",
          idType: "",
          idLast4: "",
          signature: "",
          pin: ""
        });
        this.set("emPodDetails", {
          courier: null,
          consignmentNumber: null,
          articles: [{
            barcode: ""
          }],
          receiver_address: "",
          receiver_phone: null
        });
      },

      onCloseEmPod: function onCloseEmPod() {
        this.set("showEmPod", false);
        this.set("checkoutWorkflow", null);
      },

      onEmPodAddArticle: function onEmPodAddArticle() {
        this.get("emPodDetails").articles.pushObject({
          barcode: ""
        });
      },

      onEmPodRemoveArticle: function onEmPodRemoveArticle(index) {
        var articles = JSON.parse(JSON.stringify(this.get("emPodDetails").articles));
        articles.splice(index, 1);
        this.set("emPodDetails.articles", articles);
      },

      onCloseManualReason: function onCloseManualReason() {
        this.set("checkoutWorkflow", null);
        this.set("showManualReason", false);
      },

      onClosePrintLabelModal: function onClosePrintLabelModal() {
        this.set("showPrintLabelModal", false);
      },

      oncloseAttachLabelModal: function oncloseAttachLabelModal() {
        //this.set("checkoutWorkflow", null);
        this.set("showAttachLabelModal", false);
        this.set("showPrintLabelModal", false);
      },

      onCloseCheckinModal: function onCloseCheckinModal() {
        this.set("showPrintLabelModal", false);
        this.set("showCheckinModal", false);
        this.set("showAttachLabelModal", false);
      },

      //Regular Checkout
      onCheckoutSearch: function onCheckoutSearch() {
        var cardNumber = this.get("cardNumber");
        if (cardNumber.length > 0) {
          var self = this;
          self.controllerFor("application").send("showSpinner");
          (0, _icAjax["default"])({
            url: self.get("config.apiURL") + "consignments/cardNumberPost",
            type: "post",
            headers: {
              Authorization: "Bearer " + self.get("session.data").authenticated.jwt
            },
            crossDomain: true,
            data: cardNumber
          }).then(function (consignment) {
            self.set("checkoutCommon", {
              receiver: consignment.receiver.name,
              idType: "",
              idLast4: "",
              signature: "",
              pin: ""
            });
            self.set("regularCheckout", {
              consignment: consignment,
              articles: consignment.articles.map(function (article) {
                return {
                  barcode: article.barcode,
                  verified: false
                };
              })
            });
            self.controllerFor("application").send("hideSpinner");
            self.set("checkoutWorkflow", "normal");
            self.set("showReceiver", true);
          }, function () /*error*/{
            self.controllerFor("application").send("showError", "Reference Number Not Found!");
            self.controllerFor("application").send("hideSpinner", 1500);
          });
        }
      },

      openPrintLabelModal: function openPrintLabelModal(consignmentexternalOnlineId) {
        var consignmentt = this.get("consignments");
        var singleValue = consignmentt.map(function (lockerobject) {
          return lockerobject;
        });
        var result = singleValue.filter(function (item) {
          return item.externalOnlineId === consignmentexternalOnlineId;
        });
        this.set("popvalue", result);
        this.set("showPrintLabelModal", true);
        this.set("showAttachLabelModal", false);
      },
      downloadReprintlabel: function downloadReprintlabel(consignmentexternalOnlineId) {
        // let a = document.createElement('a');
        // a.href = this.get('fetchpdf');
        // a.download = 'file.pdf';
        // a.dispatchEvent(new MouseEvent('click'));
        // this.set('showPrintLabelModal', false);
        var sdsds = this.get("popvalue");

        var consignmentt = this.get("consignments");

        var singleValue = consignmentt.map(function (lockerobject) {
          return lockerobject;
        });

        var result = singleValue.filter(function (item) {
          return item.externalOnlineId === consignmentexternalOnlineId;
        });

        // this.set('fetchpdf', result[0].labelUrl);
        var a = document.createElement("a");
        a.href = result[0].labelUrl;
        a.download = "file.pdf";
        a.dispatchEvent(new MouseEvent("click"));
        this.set("showPrintLabelModal", false);
      },
      downloadReprint: function downloadReprint(popvalueexternalOnlineId) {
        var downloadpdf = this.get("popvalue");
        var a = document.createElement("a");
        a.href = downloadpdf[0].labelUrl;
        a.download = "file.pdf";
        a.dispatchEvent(new MouseEvent("click"));
        this.set("showAttachLabelModal", true);
      },
      closePopup: function closePopup() {
        this.set("showAttachLabelModal", false);
        this.set("showPrintLabelModal", true);
      },

      onCloseReceiver: function onCloseReceiver() {
        this.set("showReceiver", false);
        self.set("receiverFormCourier", null);
      },

      onRequestArticles: function onRequestArticles() {
        this.set("showArticles", true);
        this.set("showReceiver", false);
      },

      onCloseArticles: function onCloseArticles() {
        this.set("showArticles", false);
      },

      onVerifyArticle: function onVerifyArticle() {
        if (this.get("verifyArticle") && this.get("verifyArticle").length > 0) {
          this.set("verifyArticleErrorMsg", this.get("verifyArticle"));
          for (var i = 0; i < this.get("regularCheckout.articles").length; i += 1) {
            if (this.get("regularCheckout.articles")[i].barcode.toUpperCase() === this.get("verifyArticle").toUpperCase() || "J" + this.get("regularCheckout.articles")[i].barcode.toUpperCase() === this.get("verifyArticle").toUpperCase()) {
              this.set("regularCheckout.articles." + i + ".verified", true);
              this.set("verifyArticleErrorMsg", null);
            }
          }
          this.set("verifyArticle", null);
        }
      },

      reprintLabel: function reprintLabel(consignmentid) {
        var self = this;
        self.controllerFor("application").send("showSpinner");
        (0, _icAjax["default"])({
          url: self.get("config.apiURL") + ("consignments/" + consignmentid + "/getLabelUrl"),
          type: "get",
          headers: {
            Authorization: "Bearer " + self.get("session.data").authenticated.jwt
          },
          // data: JSON.stringify({}),
          contentType: "application/json",
          crossDomain: true
        }).then(function (result) {
          window.open(result.labelUrl);
          self.controllerFor("application").send("showSuccess");
          self.controllerFor("application").send("hideSpinner", 850);
          self.send("reload");
        }, function () /*error*/{
          self.controllerFor("application").send("showError", "Please try again later, or use the sidebar");
          self.controllerFor("application").send("hideSpinner", 850);
          self.send("reload");
        });
      },

      //Print label
      onPrintLabel: function onPrintLabel() {
        this.set("showAttachLabelModal", true);
        this.set("showPrintLabelModal", false);
        // this.set("consigmentNo", '');
      },

      openDropErrorModal: function openDropErrorModal() {
        this.set("showDropErrorModal", true);
      },

      closeDropErrorModal: function closeDropErrorModal() {
        this.set("showDropErrorModal", false);
      },

      Checkin: function Checkin() {
        var self = this;
        self.controllerFor("application").send("showSpinner");
        var popupValues = this.get("popvalue");

        (0, _icAjax["default"])({
          url: self.get("config.apiURL") + "v2/consumer/paperless/drops",
          type: "post",
          headers: {
            Authorization: "Bearer " + self.get("session.data").authenticated.jwt
          },
          data: JSON.stringify({
            courier: popupValues[0].courier,
            paperLessNumber: popupValues[0].consignmentNumber,
            externalOnlineId: popupValues[0].externalOnlineId
          }),

          contentType: "application/json",
          crossDomain: true
        }).then(function () /*result*/{
          self.set("showCheckinModal", false);
          self.set("showPrintLabelModal", false);
          self.set("showAttachLabelModal", false);
          self.send("getPage", 1);

          self.controllerFor("application").send("showSuccess");
          self.controllerFor("application").send("hideSpinner", 850);
        }, function () /*error*/{
          self.controllerFor("application").send("hideSpinner", 0);
        });
      },

      // onConfirmPin: function () {
      //   if (this.validateSignatureOrPin()) {
      //     var self = this;       
      //     if(this.get("checkoutWorkflow") === "emPod") {
      //       self.controllerFor('application').send('showSpinner');
      //       ajax({
      //         url: self.get("config.apiURL") + 'v2/consignments/manualPinValidate',
      //         type: 'post',
      //         headers: {
      //           'Authorization': 'Bearer ' + self.get('session.data').authenticated.jwt
      //         },
      //         data: JSON.stringify({
      //           phone: self.get('emPodDetails.pin_phone'),
      //           pin: self.get('checkoutCommon.pin')
      //         }),
      //         contentType: "application/json",
      //         crossDomain: true
      //       }).then(function ( /*result*/ ) {
      //         self.controllerFor('application').send('hideSpinner', 0);
      //         self.set('showPin', false);
      //         self.set("showCheckoutConfirmation", true);
      //       }, function () {
      //         self.controllerFor('application').send('showError', "Error");
      //         self.controllerFor('application').send('hideSpinner', 850);
      //       });
      //     } else {
      //       self.controllerFor('application').send('showSpinner');
      //       var consignmentId = (this.get("checkoutWorkflow") === "manual") ? self.get('manualCheckout.consignment.id') : self.get('regularCheckout.consignment.id');
      //       ajax({
      //         url: self.get("config.apiURL") + 'consignments/' + consignmentId + '/validatePin',
      //         type: 'post',
      //         headers: {
      //           'Authorization': 'Bearer ' + self.get('session.data').authenticated.jwt
      //         },
      //         data: JSON.stringify({
      //           pin: self.get('checkoutCommon.pin')
      //         }),
      //         contentType: "application/json",
      //         crossDomain: true
      //       }).then(function ( /*result*/ ) {
      //         self.controllerFor('application').send('hideSpinner', 0);
      //         self.set('showPin', false);
      //         self.set("showCheckoutConfirmation", true);
      //       }, function () {
      //         self.controllerFor('application').send('showError', "Invalid Pin");
      //         self.controllerFor('application').send('hideSpinner', 850);
      //       });
      //     }         
      //   }
      // },

      onRequestResendPin: function onRequestResendPin() {
        if (this.get("resendMobile").length > 8) {
          var self = this;
          if (this.get("checkoutWorkflow") === "emPod") {
            this.set("emPodDetails.pin_phone", this.get("resendMobile"));
            self.controllerFor("application").send("showSpinner");
            (0, _icAjax["default"])({
              url: self.get("config.apiURL") + "v2/consignments/manualPinSend",
              type: "post",
              headers: {
                Authorization: "Bearer " + self.get("session.data").authenticated.jwt
              },
              data: JSON.stringify({
                phone: self.get("emPodDetails.pin_phone"),
                courier: self.get("emPodDetails.courier")
              }),
              contentType: "application/json",
              crossDomain: true
            }).then(function () /*result*/{
              self.controllerFor("application").send("hideSpinner", 0);
              self.set("showPin", true);
              self.set("showPinResend", false);
            }, function () {
              self.controllerFor("application").send("showError", "Error");
              self.controllerFor("application").send("hideSpinner", 850);
            });
          } else {
            self.controllerFor("application").send("showSpinner");
            var consignmentId = this.get("checkoutWorkflow") === "manual" ? self.get("manualCheckout.consignment.id") : self.get("regularCheckout.consignment.id");
            (0, _icAjax["default"])({
              url: self.get("config.apiURL") + "consignments/" + consignmentId + "/resendPin",
              type: "post",
              headers: {
                Authorization: "Bearer " + self.get("session.data").authenticated.jwt
              },
              data: JSON.stringify({
                mobile: self.get("resendMobile")
              }),
              contentType: "application/json",
              crossDomain: true
            }).then(function () /*result*/{
              self.controllerFor("application").send("showSuccess", "Pin Resent");
              self.controllerFor("application").send("hideSpinner", 550);
              self.set("showPinResend", false);
              self.set("showPin", true);
            }, function () {
              self.controllerFor("application").send("showError", "Unknown Error");
              self.controllerFor("application").send("hideSpinner", 850);
            });
          }
        }
      },

      checkInTNTParcel: function checkInTNTParcel() {
        this.set("showManualReason", false);
        this.set("showArticles", false);
        if (this.get("emPodDetails.consignmentNumber") && this.get("emPodDetails.consignmentNumber").length > 0) {
          var self = this;
          self.controllerFor("application").send("showSpinner");
          (0, _icAjax["default"])({
            url: self.get("config.apiURL") + "v2/consignments/manualCheckin",
            type: "post",
            headers: {
              Authorization: "Bearer " + self.get("session.data").authenticated.jwt
            },
            data: JSON.stringify({
              consignmentDetails: {
                consignmentNumber: this.get("emPodDetails.consignmentNumber"),
                courier: this.get("emPodDetails.courier"),
                articles: [],
                receiverName: this.get("checkoutCommon.receiver"),
                receiverAddress: this.get("emPodDetails.receiver_address"),
                receiverPhone: null,
                receiverEmail: null
              }
            }),
            contentType: "application/json",
            crossDomain: true
          }).then(function () /*result*/{
            self.controllerFor("application").send("showSuccess");
            self.controllerFor("application").send("hideSpinner", 850);
            self.set("showEmPod", false);
            self.send("reload");
          }, function () {
            if (error && error.jqXHR && error.jqXHR.status === 401) {
              self.get("session").invalidate();
            } else {
              // Handle other errors
              self.controllerFor("application").send("showError", "An error occurred while checking in the TNT parcel.");
              console.error("Error:", error);
              self.controllerFor("application").send("hideSpinner", 850);
            }
            self.controllerFor("application").send("showError", "Error");
            self.controllerFor("application").send("hideSpinner", 850);
          });
        } else {
          this.set("showEmPod", false);
        }
      },
      onCheckout: function onCheckout() {
        var self = this;
        if (this.get("checkoutWorkflow") === "manual" || this.get("checkoutWorkflow") === "normal") {
          var consignmentId = this.get("checkoutWorkflow") === "manual" ? this.get("manualCheckout.consignment.id") : this.get("regularCheckout.consignment.id");
          self.controllerFor("application").send("showSpinner");
          var data = this.get("checkoutWorkflow") === "manual" ? {
            pin: this.get("checkoutCommon.pin").length > 0 ? this.get("checkoutCommon.pin") : null,
            receiver: this.get("checkoutCommon.receiver"),
            identityType: this.get("checkoutCommon.idType"),
            identityLast4: this.get("checkoutCommon.idLast4"),
            reason: this.get("manualCheckout.reason"),
            articles: this.get("manualCheckout.consignment.articles").map(function (a) {
              return {
                barcode: a.barcode
              };
            }),
            signature: this.get("checkoutCommon.signature").length > 0 ? this.get("checkoutCommon.signature") : null
          } : {
            receiver: this.get("checkoutCommon.receiver"),
            identityType: this.get("checkoutCommon.idType"),
            identityLast4: this.get("checkoutCommon.idLast4"),
            pin: this.get("checkoutCommon.pin").length > 0 ? this.get("checkoutCommon.pin") : null,
            articles: this.get("regularCheckout.articles").map(function (a) {
              return {
                barcode: a.barcode
              };
            }),
            signature: this.get("checkoutCommon.signature").length > 0 ? this.get("checkoutCommon.signature") : null
          };
          (0, _icAjax["default"])({
            url: self.get("config.apiURL") + "consignments/" + consignmentId + "/checkout",
            type: "post",
            headers: {
              Authorization: "Bearer " + self.get("session.data").authenticated.jwt
            },
            data: JSON.stringify(data),
            contentType: "application/json",
            crossDomain: true
          }).then(function () /*result*/{
            self.controllerFor("application").send("showSuccess");
            self.controllerFor("application").send("hideSpinner", 850);
            self.set("showCheckoutConfirmation", false);
            self.send("reload");
          }, function () /*error*/{
            self.controllerFor("application").send("showError");
            self.controllerFor("application").send("hideSpinner", 850);
          });
        } else if (this.get("checkoutWorkflow") === "emPod") {
          self.controllerFor("application").send("showSpinner");
          (0, _icAjax["default"])({
            url: self.get("config.apiURL") + "v2/consignments/manualCheckout",
            type: "post",
            headers: {
              Authorization: "Bearer " + self.get("session.data").authenticated.jwt
            },
            data: JSON.stringify({
              consignmentDetails: {
                consignmentNumber: this.get("emPodDetails.consignmentNumber"),
                courier: this.get("emPodDetails.courier"),
                articles: this.get("emPodDetails.articles").map(function (a) {
                  return [a.barcode];
                }),
                receiverName: this.get("checkoutCommon.receiver"),
                receiverAddress: this.get("emPodDetails.receiver_address"),
                receiverPhone: this.get("emPodDetails.pin_phone"),
                receiverEmail: null
              },
              checkoutDetails: {
                receiver: this.get("checkoutCommon.receiver"),
                identityType: this.get("checkoutCommon.idType"),
                identityLast4: this.get("checkoutCommon.idLast4"),
                signature: this.get("checkoutCommon.signature").length > 0 ? this.get("checkoutCommon.signature") : null,
                pin: this.get("checkoutCommon.pin").length > 0 ? this.get("checkoutCommon.pin") : null
              }
            }),
            contentType: "application/json",
            crossDomain: true
          }).then(function () /*result*/{
            self.controllerFor("application").send("showSuccess");
            self.controllerFor("application").send("hideSpinner", 850);
            self.set("showCheckoutConfirmation", false);
            self.send("reload");
          }, function () /*error*/{
            self.controllerFor("application").send("showError");
            self.controllerFor("application").send("hideSpinner", 850);
          });
        }
      },

      // onQuickCheckin: function (consignment){
      //   var self = this;
      //   self.controllerFor('application').send('showSpinner');
      //   var acceptCheckInData = {"articleBarcode" : null, ecpId : null };
      //   if(consignment.ebayData && consignment.ebayData.ecpId) {
      //     acceptCheckInData['ecpId'] = consignment.ebayData.ecpId;
      //   }
      //   consignment.articles.forEach(a => {
      //     acceptCheckInData['articleBarcode'] = a.barcode;
      //     ajax({
      //       url: self.get("config.apiURL") + 'articles/checkin',
      //       type: 'POST',
      //       headers: {
      //         'Authorization': 'Bearer ' + self.get('session.data').authenticated.jwt
      //       },
      //       data: JSON.stringify(acceptCheckInData),
      //       contentType: "application/json",
      //       crossDomain: true
      //     }).then(function ( /*result*/ ) {
      //       //self.send("reload");
      //       self.send("getPage", 1);
      //       self.controllerFor('application').send('showSuccess');
      //       self.controllerFor('application').send('hideSpinner', 850);
      //     }, function ( /*error*/ ) {
      //       self.controllerFor('application').send('hideSpinner', 0);
      //     });
      //   });
      // },

      reload: function reload() {
        var self = this;
        this.set("cardNumber", "");
        var urlStr = self.get("config.apiURL") + "consignments?state=awaitingCustomerPickup,notYetCheckedIn&page=1&perPage=" + this.get("perPage") + "&limited=true&orderBy=time_waiting&orderDir=DESC";
        if (this.get("filterName")) {
          urlStr += "&name=" + encodeURIComponent(this.get("filterName"));
        }
        (0, _icAjax["default"])({
          url: urlStr,
          type: "get",
          headers: {
            Authorization: "Bearer " + self.get("session.data").authenticated.jwt
          },
          crossDomain: true
        }).then(function (consignments) {
          self.set("consignments", consignments.data);
          self.set("page", consignments.page);
          self.set("totalPages", consignments.totalPages);
          self.set("consignmentNumber", "");
        });
      }
    }

  });
});