define("client/routes/application", ["exports", "ember-simple-auth/mixins/application-route-mixin"], function (exports, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = Ember.Route.extend(_applicationRouteMixin["default"], {
    session: Ember.inject.service("session"),
    config: Ember.inject.service(),

    renderTemplate: function renderTemplate() {
      if (this.get("session.isAuthenticated")) {
        this.get("session").set("data.supportSectionBool", true);
        this.get("session").get("data.printAtStore");
        this.get("session").set("data.userProducts", []);
        this.get("session").set("data.country", "Australia");
        this.controllerFor("application").set("userAbilities", this.get("session.data").authenticated.abilities);
        this.render("authenticated");
      } else {
        this.render("unauthenticated");
      }
    },

    sessionAuthenticated: function sessionAuthenticated() {
      this._super.apply(this, arguments);
      this.controllerFor("application").set("userAbilities", this.get("session.data").authenticated.abilities);
      var attemptedTransition = this.get("session").get("attemptedTransition");
      if (attemptedTransition) {
        attemptedTransition.retry();
        this.get("session").set("attemptedTransition", null);
      } else {
        this.render("authenticated");
        this.transitionTo("dashboard");
      }
    },

    actions: {
      loading: function loading(transition, originRoute) {
        // jshint ignore:line
        this.controllerFor("application").send("showSpinner");
        this.router.on("didTransition", this, function () {
          this.controllerFor("application").send("hideSpinner");
        });
      },

      invalidateSession: function invalidateSession() {
        this.get("session").invalidate();
        this.transitionTo("sign_in");
        this.render("unauthenticated");
      }
    }

  });
});