define("client/controllers/sign_in", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = Ember.Controller.extend({
    session: Ember.inject.service("session"),
    email: "",
    password: "",
    errorMessage: null,

    actions: {
      login: function login() {
        var self = this;
        this.set("errorMessage", null);
        this.get("session").authenticate("authenticator:jwt", this.get("email"), this.get("password"))["catch"](function (reason) {
          self.set("errorMessage", reason);
        });
      }
    }

  });
});