define("client/helpers/xor", ["exports", "ember-truth-helpers/helpers/xor"], function (exports, _xor) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var forExport = null;

  if (Ember.Helper) {
    forExport = Ember.Helper.helper(_xor.xorHelper);
  } else if (Ember.HTMLBars.makeBoundHelper) {
    forExport = Ember.HTMLBars.makeBoundHelper(_xor.xorHelper);
  }

  exports["default"] = forExport;
});