define("client/templates/online/checkin", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          revision: "Ember@1.13.13",
          loc: {
            source: null,
            start: {
              line: 5,
              column: 8
            },
            end: {
              line: 7,
              column: 8
            }
          },
          moduleName: "client/templates/online/checkin.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "md-focusinput", [], ["value", ["subexpr", "@mut", [["get", "consignmentNumber", ["loc", [null, [6, 32], [6, 49]]]]], [], []], "label", "Consignment/Barcode", "icon", "fa-barcode green-text", "autocomplete", "off", "autofocus", "autofocus"], ["loc", [null, [6, 10], [6, 149]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            revision: "Ember@1.13.13",
            loc: {
              source: null,
              start: {
                line: 21,
                column: 8
              },
              end: {
                line: 26,
                column: 8
              }
            },
            moduleName: "client/templates/online/checkin.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "style", "text-align:center;");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("br");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("Loading...\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["inline", "md-loader", [], ["mode", "circular", "size", "small"], ["loc", [null, [23, 12], [23, 54]]]]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                revision: "Ember@1.13.13",
                loc: {
                  source: null,
                  start: {
                    line: 37,
                    column: 16
                  },
                  end: {
                    line: 45,
                    column: 16
                  }
                },
                moduleName: "client/templates/online/checkin.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("tr");
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                dom.setAttribute(el2, "class", "dont-break-out");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                var el3 = dom.createTextNode("\n                        ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element11 = dom.childAt(fragment, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(dom.childAt(element11, [1]), 0, 0);
                morphs[1] = dom.createMorphAt(dom.childAt(element11, [3]), 0, 0);
                morphs[2] = dom.createMorphAt(dom.childAt(element11, [5]), 1, 1);
                return morphs;
              },
              statements: [["content", "consignment.number", ["loc", [null, [39, 47], [39, 69]]]], ["content", "consignment.num_articles", ["loc", [null, [40, 24], [40, 52]]]], ["inline", "moment-from-now", [["get", "consignment.time", ["loc", [null, [42, 42], [42, 58]]]], "X"], [], ["loc", [null, [42, 24], [42, 64]]]]],
              locals: ["consignment"],
              templates: []
            };
          })();
          return {
            meta: {
              revision: "Ember@1.13.13",
              loc: {
                source: null,
                start: {
                  line: 27,
                  column: 10
                },
                end: {
                  line: 48,
                  column: 10
                }
              },
              moduleName: "client/templates/online/checkin.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("table");
              dom.setAttribute(el1, "class", "striped");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("thead");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("tr");
              var el4 = dom.createTextNode("\n                  ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("th");
              var el5 = dom.createTextNode("Consignment No#");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                  ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("th");
              var el5 = dom.createTextNode("# Parcels");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                  ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("th");
              var el5 = dom.createTextNode("Checked In");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("tbody");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]), 1, 1);
              return morphs;
            },
            statements: [["block", "each", [["get", "consignments", ["loc", [null, [37, 24], [37, 36]]]]], [], 0, null, ["loc", [null, [37, 16], [45, 25]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              revision: "Ember@1.13.13",
              loc: {
                source: null,
                start: {
                  line: 48,
                  column: 10
                },
                end: {
                  line: 50,
                  column: 10
                }
              },
              moduleName: "client/templates/online/checkin.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              var el2 = dom.createTextNode("No packages scanned in");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            revision: "Ember@1.13.13",
            loc: {
              source: null,
              start: {
                line: 26,
                column: 8
              },
              end: {
                line: 51,
                column: 8
              }
            },
            moduleName: "client/templates/online/checkin.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "consignments", ["loc", [null, [27, 16], [27, 28]]]]], [], 0, 1, ["loc", [null, [27, 10], [50, 17]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          revision: "Ember@1.13.13",
          loc: {
            source: null,
            start: {
              line: 20,
              column: 6
            },
            end: {
              line: 52,
              column: 6
            }
          },
          moduleName: "client/templates/online/checkin.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "consignmentsLoading", ["loc", [null, [21, 14], [21, 33]]]]], [], 0, 1, ["loc", [null, [21, 8], [51, 15]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                revision: "Ember@1.13.13",
                loc: {
                  source: null,
                  start: {
                    line: 66,
                    column: 10
                  },
                  end: {
                    line: 79,
                    column: 10
                  }
                },
                moduleName: "client/templates/online/checkin.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "row miles-card-row");
                var el3 = dom.createTextNode("\n                ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3, "class", "col s1");
                var el4 = dom.createTextNode(" ");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                   ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("div");
                var el5 = dom.createElement("i");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3, "class", "col s11");
                var el4 = dom.createTextNode(" ");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                  ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("div");
                dom.setAttribute(el4, "class", "row miles-info");
                var el5 = dom.createElement("div");
                dom.setAttribute(el5, "class", "col s2 miles-modal-card-label");
                var el6 = dom.createTextNode("Sender: ");
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                var el5 = dom.createElement("div");
                dom.setAttribute(el5, "class", "col s10 miles-modal-card-value");
                var el6 = dom.createComment("");
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                  ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("div");
                dom.setAttribute(el4, "class", "row miles-info");
                var el5 = dom.createElement("div");
                dom.setAttribute(el5, "class", "col s2 miles-modal-card-label");
                var el6 = dom.createTextNode("Recipient: ");
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                var el5 = dom.createElement("div");
                dom.setAttribute(el5, "class", "col s10 miles-modal-card-value");
                var el6 = dom.createComment("");
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                  ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("div");
                dom.setAttribute(el4, "class", "row miles-info");
                var el5 = dom.createElement("div");
                dom.setAttribute(el5, "class", "col s2 miles-modal-card-label");
                var el6 = dom.createTextNode("Address: ");
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                var el5 = dom.createElement("div");
                dom.setAttribute(el5, "class", "col s10 miles-modal-card-value");
                var el6 = dom.createComment("");
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n              ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element4 = dom.childAt(fragment, [1]);
                var element5 = dom.childAt(element4, [1]);
                var element6 = dom.childAt(element5, [1, 2, 0]);
                var element7 = dom.childAt(element5, [3]);
                var morphs = new Array(5);
                morphs[0] = dom.createElementMorph(element4);
                morphs[1] = dom.createAttrMorph(element6, "class");
                morphs[2] = dom.createMorphAt(dom.childAt(element7, [2, 1]), 0, 0);
                morphs[3] = dom.createMorphAt(dom.childAt(element7, [4, 1]), 0, 0);
                morphs[4] = dom.createMorphAt(dom.childAt(element7, [6, 1]), 0, 0);
                return morphs;
              },
              statements: [["element", "action", ["milesSelect", ["get", "package", ["loc", [null, [67, 40], [67, 47]]]]], [], ["loc", [null, [67, 17], [67, 49]]]], ["attribute", "class", ["concat", [["subexpr", "if", [["subexpr", "eq", [["get", "milesSelected", ["loc", [null, [70, 43], [70, 56]]]], ["get", "package", ["loc", [null, [70, 57], [70, 64]]]]], [], ["loc", [null, [70, 39], [70, 65]]]], "mdi-toggle-check-box", "mdi-toggle-check-box-outline-blank"], [], ["loc", [null, [70, 34], [70, 127]]]]]]], ["content", "package.sender", ["loc", [null, [73, 147], [73, 165]]]], ["content", "package.recipient", ["loc", [null, [74, 150], [74, 171]]]], ["content", "package.address", ["loc", [null, [75, 148], [75, 167]]]]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              revision: "Ember@1.13.13",
              loc: {
                source: null,
                start: {
                  line: 65,
                  column: 8
                },
                end: {
                  line: 80,
                  column: 8
                }
              },
              moduleName: "client/templates/online/checkin.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "md-card-panel", [], ["class", ["subexpr", "if", [["subexpr", "eq", [["get", "milesSelected", ["loc", [null, [66, 41], [66, 54]]]], ["get", "package", ["loc", [null, [66, 55], [66, 62]]]]], [], ["loc", [null, [66, 37], [66, 63]]]], "miles-modal-selected"], [], ["loc", [null, [66, 33], [66, 87]]]]], 0, null, ["loc", [null, [66, 10], [79, 28]]]]],
            locals: ["package"],
            templates: [child0]
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              revision: "Ember@1.13.13",
              loc: {
                source: null,
                start: {
                  line: 90,
                  column: 12
                },
                end: {
                  line: 92,
                  column: 12
                }
              },
              moduleName: "client/templates/online/checkin.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "md-btn", [], ["text", "Confirm", "action", "milesConfirm", "class", "miles-modal-selected miles-button"], ["loc", [null, [91, 14], [91, 103]]]]],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              revision: "Ember@1.13.13",
              loc: {
                source: null,
                start: {
                  line: 92,
                  column: 12
                },
                end: {
                  line: 94,
                  column: 12
                }
              },
              moduleName: "client/templates/online/checkin.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "md-btn", [], ["text", "Confirm", "disabled", "true", "class", "disabled miles-modal-selected miles-button"], ["loc", [null, [93, 14], [93, 106]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            revision: "Ember@1.13.13",
            loc: {
              source: null,
              start: {
                line: 58,
                column: 2
              },
              end: {
                line: 99,
                column: 2
              }
            },
            moduleName: "client/templates/online/checkin.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "modaltopright");
            var el2 = dom.createElement("a");
            var el3 = dom.createElement("i");
            dom.setAttribute(el3, "class", "mdi-navigation-close");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "modal-content miles-modal");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("center");
            var el3 = dom.createElement("h5");
            var el4 = dom.createTextNode("Please identify the correct package");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("center");
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "miles-modal-subheader");
            var el4 = dom.createTextNode("Consignment number: ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "miles-breathing-room");
            var el3 = dom.createTextNode(" ");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "modal-footer");
            dom.setAttribute(el2, "class", "miles-breathing-room");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "row");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "col s5 offset-s1");
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "col s5");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element8 = dom.childAt(fragment, [1, 0]);
            var element9 = dom.childAt(fragment, [3]);
            var element10 = dom.childAt(element9, [7, 1]);
            var morphs = new Array(5);
            morphs[0] = dom.createElementMorph(element8);
            morphs[1] = dom.createMorphAt(dom.childAt(element9, [3, 0]), 1, 1);
            morphs[2] = dom.createMorphAt(dom.childAt(element9, [5]), 2, 2);
            morphs[3] = dom.createMorphAt(dom.childAt(element10, [1]), 1, 1);
            morphs[4] = dom.createMorphAt(dom.childAt(element10, [3]), 1, 1);
            return morphs;
          },
          statements: [["element", "action", ["closeMilesModal"], [], ["loc", [null, [59, 34], [59, 62]]]], ["content", "consignmentNumber", ["loc", [null, [62, 70], [62, 91]]]], ["block", "each", [["get", "milesResults", ["loc", [null, [65, 16], [65, 28]]]]], [], 0, null, ["loc", [null, [65, 8], [80, 17]]]], ["inline", "md-btn", [], ["text", "None of the above", "action", "milesReject", "class", "grey miles-button"], ["loc", [null, [86, 12], [86, 94]]]], ["block", "if", [["get", "milesSelected", ["loc", [null, [90, 18], [90, 31]]]]], [], 1, 2, ["loc", [null, [90, 12], [94, 19]]]]],
          locals: [],
          templates: [child0, child1, child2]
        };
      })();
      return {
        meta: {
          revision: "Ember@1.13.13",
          loc: {
            source: null,
            start: {
              line: 57,
              column: 0
            },
            end: {
              line: 100,
              column: 0
            }
          },
          moduleName: "client/templates/online/checkin.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "md-modal", [], ["close", "closeMilesModal"], 0, null, ["loc", [null, [58, 2], [99, 15]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child3 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              revision: "Ember@1.13.13",
              loc: {
                source: null,
                start: {
                  line: 108,
                  column: 8
                },
                end: {
                  line: 120,
                  column: 8
                }
              },
              moduleName: "client/templates/online/checkin.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "col s6 offset-s3");
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("table");
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("tr");
              var el4 = dom.createTextNode("\n              ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("td");
              dom.setAttribute(el4, "style", "padding: 0 24px 0 0;");
              var el5 = dom.createElement("i");
              dom.setAttribute(el5, "class", "fa-truck green-text");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n              ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("td");
              dom.setAttribute(el4, "style", "padding: 0 5px; width:100%;");
              var el5 = dom.createTextNode("\n                ");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n              ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(element1, [1, 1, 3]), 1, 1);
              morphs[1] = dom.createMorphAt(element1, 3, 3);
              return morphs;
            },
            statements: [["inline", "md-select", [], ["content", ["subexpr", "@mut", [["get", "couriers", ["loc", [null, [114, 36], [114, 44]]]]], [], []], "value", ["subexpr", "@mut", [["get", "courier", ["loc", [null, [114, 51], [114, 58]]]]], [], []], "optionLabelPath", "content.name", "optionValuePath", "content.code", "label", "Parcel booked via", "class", "hide-error"], ["loc", [null, [114, 16], [114, 168]]]], ["inline", "md-input", [], ["value", ["subexpr", "@mut", [["get", "consignmentNumber", ["loc", [null, [118, 27], [118, 44]]]]], [], []], "label", "Consignment/Barcode", "icon", "fa-barcode green-text", "autocomplete", "off", "class", "hide-error"], ["loc", [null, [118, 10], [118, 142]]]]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                revision: "Ember@1.13.13",
                loc: {
                  source: null,
                  start: {
                    line: 125,
                    column: 16
                  },
                  end: {
                    line: 132,
                    column: 16
                  }
                },
                moduleName: "client/templates/online/checkin.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("tr");
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                dom.setAttribute(el2, "style", "padding: 0 5px; width:100%;");
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("tr");
                var el2 = dom.createElement("td");
                dom.setAttribute(el2, "style", "text-align:center;");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
                morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3, 0]), 0, 0);
                return morphs;
              },
              statements: [["inline", "md-input", [], ["value", ["subexpr", "@mut", [["get", "package.consignmentNumber", ["loc", [null, [128, 39], [128, 64]]]]], [], []], "label", ["subexpr", "@mut", [["get", "package.courierName", ["loc", [null, [128, 71], [128, 90]]]]], [], []], "icon", "fa-truck green-text", "autocomplete", "off", "class", "hide-error", "readonly", "true"], ["loc", [null, [128, 22], [128, 173]]]], ["inline", "md-btn", [], ["text", "Confirm", "action", "checkin", "class", "green no-float"], ["loc", [null, [131, 53], [131, 118]]]]],
              locals: ["package"],
              templates: []
            };
          })();
          return {
            meta: {
              revision: "Ember@1.13.13",
              loc: {
                source: null,
                start: {
                  line: 122,
                  column: 8
                },
                end: {
                  line: 135,
                  column: 10
                }
              },
              moduleName: "client/templates/online/checkin.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "col s6 offset-s3");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("table");
              var el3 = dom.createTextNode(" \n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("             \n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
              return morphs;
            },
            statements: [["block", "each", [["get", "consignmentResults", ["loc", [null, [125, 24], [125, 42]]]]], [], 0, null, ["loc", [null, [125, 16], [132, 25]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child2 = (function () {
          var child0 = (function () {
            return {
              meta: {
                revision: "Ember@1.13.13",
                loc: {
                  source: null,
                  start: {
                    line: 138,
                    column: 16
                  },
                  end: {
                    line: 147,
                    column: 16
                  }
                },
                moduleName: "client/templates/online/checkin.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                  ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("tr");
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                dom.setAttribute(el2, "style", "padding: 0 5px; width:100%;");
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                \n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                dom.setAttribute(el2, "style", "padding: 0 5px; width:100%;");
                var el3 = dom.createTextNode("\n                      ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                    ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 1, 1);
                morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 1, 1);
                return morphs;
              },
              statements: [["inline", "md-input", [], ["value", ["subexpr", "@mut", [["get", "package.consignmentNumber", ["loc", [null, [141, 39], [141, 64]]]]], [], []], "label", ["subexpr", "@mut", [["get", "package.courierName", ["loc", [null, [141, 71], [141, 90]]]]], [], []], "icon", "fa-truck green-text", "autocomplete", "off", "class", "hide-error", "readonly", "true"], ["loc", [null, [141, 22], [141, 173]]]], ["inline", "md-btn", [], ["text", "Confirm", "action", "confirmCheckIn", "actionArg", ["subexpr", "@mut", [["get", "package", ["loc", [null, [144, 80], [144, 87]]]]], [], []], "class", "green no-float"], ["loc", [null, [144, 22], [144, 112]]]]],
              locals: ["package"],
              templates: []
            };
          })();
          return {
            meta: {
              revision: "Ember@1.13.13",
              loc: {
                source: null,
                start: {
                  line: 135,
                  column: 10
                },
                end: {
                  line: 150,
                  column: 10
                }
              },
              moduleName: "client/templates/online/checkin.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "col s8 offset-s2");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("table");
              var el3 = dom.createTextNode("           \n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
              return morphs;
            },
            statements: [["block", "each", [["get", "consignmentResults", ["loc", [null, [138, 24], [138, 42]]]]], [], 0, null, ["loc", [null, [138, 16], [147, 25]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child3 = (function () {
          var child0 = (function () {
            return {
              meta: {
                revision: "Ember@1.13.13",
                loc: {
                  source: null,
                  start: {
                    line: 175,
                    column: 6
                  },
                  end: {
                    line: 177,
                    column: 6
                  }
                },
                moduleName: "client/templates/online/checkin.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "md-btn", [], ["text", "Done", "action", "checkin", "class", "green no-float"], ["loc", [null, [176, 8], [176, 70]]]]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                revision: "Ember@1.13.13",
                loc: {
                  source: null,
                  start: {
                    line: 177,
                    column: 6
                  },
                  end: {
                    line: 179,
                    column: 6
                  }
                },
                moduleName: "client/templates/online/checkin.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "md-btn", [], ["text", "Done", "disabled", "true", "class", "disabled green no-float"], ["loc", [null, [178, 8], [178, 78]]]]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              revision: "Ember@1.13.13",
              loc: {
                source: null,
                start: {
                  line: 173,
                  column: 4
                },
                end: {
                  line: 181,
                  column: 4
                }
              },
              moduleName: "client/templates/online/checkin.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "modal-footer center pad");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
              return morphs;
            },
            statements: [["block", "if", [["get", "validCheckin", ["loc", [null, [175, 12], [175, 24]]]]], [], 0, 1, ["loc", [null, [175, 6], [179, 13]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            revision: "Ember@1.13.13",
            loc: {
              source: null,
              start: {
                line: 103,
                column: 2
              },
              end: {
                line: 182,
                column: 2
              }
            },
            moduleName: "client/templates/online/checkin.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "modaltopright");
            var el2 = dom.createElement("a");
            var el3 = dom.createElement("i");
            dom.setAttribute(el3, "class", "mdi-navigation-close");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "modal-content");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("center");
            var el3 = dom.createElement("h5");
            var el4 = dom.createTextNode("Customer Drop Off");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "row no-bottom");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("        \n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1, 0]);
            var element3 = dom.childAt(fragment, [3, 3]);
            var morphs = new Array(4);
            morphs[0] = dom.createElementMorph(element2);
            morphs[1] = dom.createMorphAt(element3, 1, 1);
            morphs[2] = dom.createMorphAt(element3, 3, 3);
            morphs[3] = dom.createMorphAt(fragment, 5, 5, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["element", "action", ["closeCheckinModal"], [], ["loc", [null, [104, 32], [104, 62]]]], ["block", "if", [false], [], 0, null, ["loc", [null, [108, 8], [120, 15]]]], ["block", "if", [["get", "singleConsignmentBool", ["loc", [null, [122, 14], [122, 35]]]]], [], 1, 2, ["loc", [null, [122, 8], [150, 17]]]], ["block", "if", [false], [], 3, null, ["loc", [null, [173, 4], [181, 11]]]]],
          locals: [],
          templates: [child0, child1, child2, child3]
        };
      })();
      return {
        meta: {
          revision: "Ember@1.13.13",
          loc: {
            source: null,
            start: {
              line: 102,
              column: 0
            },
            end: {
              line: 183,
              column: 0
            }
          },
          moduleName: "client/templates/online/checkin.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "md-modal", [], ["close", "closeCheckinModal"], 0, null, ["loc", [null, [103, 2], [182, 15]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child4 = (function () {
      var child0 = (function () {
        return {
          meta: {
            revision: "Ember@1.13.13",
            loc: {
              source: null,
              start: {
                line: 188,
                column: 1
              },
              end: {
                line: 203,
                column: 1
              }
            },
            moduleName: "client/templates/online/checkin.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\t\t");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n\t\t\t");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "modal-header");
            var el3 = dom.createTextNode(" \n\t\t\t\t");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("center");
            var el4 = dom.createElement("h2");
            dom.setAttribute(el4, "class", "red-textt");
            dom.setAttribute(el4, "style", "font-size: 2.5rem; margin-top:2.5rem;");
            var el5 = dom.createElement("b");
            var el6 = dom.createTextNode(" EXPIRED RETURN ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\t\t\t");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n\t\t\t");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "modal-content");
            var el3 = dom.createTextNode("\n\t\t\t\t\n\t\t\t\t");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "row no-bottom");
            var el4 = dom.createElement("center");
            var el5 = dom.createElement("h5");
            dom.setAttribute(el5, "style", "font-size: 1.5rem;");
            var el6 = dom.createTextNode("This parcel is expired ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("font");
            dom.setAttribute(el6, "class", "red-textt");
            var el7 = dom.createElement("b");
            var el8 = dom.createTextNode("and cannot be accepted.");
            dom.appendChild(el7, el8);
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode(" ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("br");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("br");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode(" Please return the parcel to the customer and ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("br");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("ask them to contact the seller.");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\t\t\t");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\t\t\t\n\t\t\t");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "modal-footer remove-scroll center");
            var el3 = dom.createTextNode("\n\t\t\t\t");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "style", "margin-bottom:2.5rem;");
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\t\t\t");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\t\t");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 5, 1]), 1, 1);
            return morphs;
          },
          statements: [["inline", "md-btn", [], ["text", "CONFIRM", "action", "closeDropErrorModal", "class", "blue no-float"], ["loc", [null, [200, 40], [200, 118]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          revision: "Ember@1.13.13",
          loc: {
            source: null,
            start: {
              line: 187,
              column: 0
            },
            end: {
              line: 204,
              column: 0
            }
          },
          moduleName: "client/templates/online/checkin.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "md-modal", [], ["close", "closeCheckinModal"], 0, null, ["loc", [null, [188, 1], [203, 14]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        revision: "Ember@1.13.13",
        loc: {
          source: null,
          start: {
            line: 1,
            column: 0
          },
          end: {
            line: 204,
            column: 7
          }
        },
        moduleName: "client/templates/online/checkin.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "sideForm");
        dom.setAttribute(el1, "class", "col s3 full-height indigo  lighten-4");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col s12");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("form");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "id", "content");
        dom.setAttribute(el1, "class", "col s9 full-height indigo lighten-5");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col s12");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h1");
        dom.setAttribute(el4, "class", "page-heading");
        var el5 = dom.createTextNode("Pre-booked parcels dropped off in store by customers, awaiting courier pickup");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element12 = dom.childAt(fragment, [0, 1, 1, 1]);
        var element13 = dom.childAt(fragment, [2, 1, 1]);
        var morphs = new Array(8);
        morphs[0] = dom.createElementMorph(element12);
        morphs[1] = dom.createMorphAt(element12, 1, 1);
        morphs[2] = dom.createMorphAt(element12, 3, 3);
        morphs[3] = dom.createMorphAt(element13, 3, 3);
        morphs[4] = dom.createMorphAt(element13, 5, 5);
        morphs[5] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        morphs[6] = dom.createMorphAt(fragment, 6, 6, contextualElement);
        morphs[7] = dom.createMorphAt(fragment, 8, 8, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["element", "action", ["checkForConsignment"], ["on", "submit"], ["loc", [null, [4, 12], [4, 56]]]], ["block", "md-card-panel", [], ["class", "transparent-card"], 0, null, ["loc", [null, [5, 8], [7, 26]]]], ["inline", "md-btn-submit", [], ["icon", "mdi-communication-call-made", "iconPosition", "right", "buttonType", "large", "text", "Check In", "class", "right green"], ["loc", [null, [8, 8], [8, 136]]]], ["inline", "pagination-component", [], ["page", ["subexpr", "@mut", [["get", "page", ["loc", [null, [19, 34], [19, 38]]]]], [], []], "totalPages", ["subexpr", "@mut", [["get", "totalPages", ["loc", [null, [19, 50], [19, 60]]]]], [], []], "getPageAction", ["subexpr", "action", ["getPage"], [], ["loc", [null, [19, 75], [19, 93]]]], "class", "finger-pagination"], ["loc", [null, [19, 6], [19, 122]]]], ["block", "md-card-panel", [], ["class", "transparent-card"], 1, null, ["loc", [null, [20, 6], [52, 24]]]], ["block", "if", [["subexpr", "eq", [["get", "showModal", ["loc", [null, [57, 10], [57, 19]]]], "miles"], [], ["loc", [null, [57, 6], [57, 28]]]]], [], 2, null, ["loc", [null, [57, 0], [100, 7]]]], ["block", "if", [["subexpr", "eq", [["get", "showModal", ["loc", [null, [102, 10], [102, 19]]]], "checkin"], [], ["loc", [null, [102, 6], [102, 30]]]]], [], 3, null, ["loc", [null, [102, 0], [183, 7]]]], ["block", "if", [["get", "showDropErrorModal", ["loc", [null, [187, 6], [187, 24]]]]], [], 4, null, ["loc", [null, [187, 0], [204, 7]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  })());
});