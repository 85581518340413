define("client/templates/paperless/checkin", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            revision: "Ember@1.13.13",
            loc: {
              source: null,
              start: {
                line: 9,
                column: 12
              },
              end: {
                line: 12,
                column: 12
              }
            },
            moduleName: "client/templates/paperless/checkin.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment(" md-btn text='Filter Parcels' class='blue' action='showFilter' ");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "md-btn", [], ["text", "Filter By Name", "class", "blue", "action", "showFilter"], ["loc", [null, [10, 14], [10, 79]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          revision: "Ember@1.13.13",
          loc: {
            source: null,
            start: {
              line: 7,
              column: 10
            },
            end: {
              line: 13,
              column: 10
            }
          },
          moduleName: "client/templates/paperless/checkin.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("HD-1342");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "not", [["get", "filterShow", ["loc", [null, [9, 23], [9, 33]]]]], [], ["loc", [null, [9, 18], [9, 34]]]]], [], 0, null, ["loc", [null, [9, 12], [12, 19]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          revision: "Ember@1.13.13",
          loc: {
            source: null,
            start: {
              line: 17,
              column: 6
            },
            end: {
              line: 29,
              column: 6
            }
          },
          moduleName: "client/templates/paperless/checkin.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "checkoutFilter");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment(" Filter parcels by name/consignment/courier \n          <div class=\"cell-item\">Filter by name:</div>");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\t          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "cell-item input-item");
          var el3 = dom.createTextNode("\n             ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n             ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "cell-item");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element11 = dom.childAt(fragment, [1]);
          var element12 = dom.childAt(element11, [5]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(element11, [3]), 1, 1);
          morphs[1] = dom.createMorphAt(element12, 1, 1);
          morphs[2] = dom.createMorphAt(element12, 3, 3);
          return morphs;
        },
        statements: [["inline", "input", [], ["value", ["subexpr", "@mut", [["get", "filterName", ["loc", [null, [22, 27], [22, 37]]]]], [], []], "class", "parcelInStoreSearchInput", "placeholder", "Search : Consignment Number / Customer Name / Return Reference Number", "autocomplete", "off", "enter", "filter"], ["loc", [null, [22, 13], [22, 190]]]], ["inline", "md-btn", [], ["text", "Search", "class", "blue", "action", "filter"], ["loc", [null, [25, 14], [25, 67]]]], ["inline", "md-btn", [], ["text", "Cancel", "class", "red", "action", "showFilter"], ["loc", [null, [26, 14], [26, 70]]]]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            revision: "Ember@1.13.13",
            loc: {
              source: null,
              start: {
                line: 31,
                column: 8
              },
              end: {
                line: 36,
                column: 8
              }
            },
            moduleName: "client/templates/paperless/checkin.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "style", "text-align:center;");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("br");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("Loading...\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["inline", "md-loader", [], ["mode", "circular", "size", "small"], ["loc", [null, [33, 12], [33, 54]]]]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  revision: "Ember@1.13.13",
                  loc: {
                    source: null,
                    start: {
                      line: 57,
                      column: 24
                    },
                    end: {
                      line: 59,
                      column: 24
                    }
                  },
                  moduleName: "client/templates/paperless/checkin.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["content", "-", ["loc", [null, [58, 26], [58, 31]]]]],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              return {
                meta: {
                  revision: "Ember@1.13.13",
                  loc: {
                    source: null,
                    start: {
                      line: 59,
                      column: 24
                    },
                    end: {
                      line: 61,
                      column: 24
                    }
                  },
                  moduleName: "client/templates/paperless/checkin.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "moment-format", [["get", "consignment.checkinTime", ["loc", [null, [60, 42], [60, 65]]]], "DD/MM/YY"], [], ["loc", [null, [60, 26], [60, 79]]]]],
                locals: [],
                templates: []
              };
            })();
            var child2 = (function () {
              return {
                meta: {
                  revision: "Ember@1.13.13",
                  loc: {
                    source: null,
                    start: {
                      line: 64,
                      column: 7
                    },
                    end: {
                      line: 67,
                      column: 7
                    }
                  },
                  moduleName: "client/templates/paperless/checkin.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("\t\t\t\t\t\t\t\t");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1, "class", "in-red-color");
                  var el2 = dom.createTextNode("Expired");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n\t\t\t\t\t\t\t\t\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() {
                  return [];
                },
                statements: [],
                locals: [],
                templates: []
              };
            })();
            var child3 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    revision: "Ember@1.13.13",
                    loc: {
                      source: null,
                      start: {
                        line: 68,
                        column: 8
                      },
                      end: {
                        line: 70,
                        column: 8
                      }
                    },
                    moduleName: "client/templates/paperless/checkin.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("\t\t\t\t\t\t\t\t\t");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1, "class", "in-red-color");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                    return morphs;
                  },
                  statements: [["content", "consignment.status", ["loc", [null, [69, 36], [69, 58]]]]],
                  locals: [],
                  templates: []
                };
              })();
              var child1 = (function () {
                var child0 = (function () {
                  return {
                    meta: {
                      revision: "Ember@1.13.13",
                      loc: {
                        source: null,
                        start: {
                          line: 70,
                          column: 8
                        },
                        end: {
                          line: 72,
                          column: 8
                        }
                      },
                      moduleName: "client/templates/paperless/checkin.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("\t\t\t\t\t\t\t\t\t");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1, "class", "in-orange-color");
                      var el2 = dom.createTextNode("Awaiting Collection");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n\t\t\t\t\t\t\t\t");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() {
                      return [];
                    },
                    statements: [],
                    locals: [],
                    templates: []
                  };
                })();
                return {
                  meta: {
                    revision: "Ember@1.13.13",
                    loc: {
                      source: null,
                      start: {
                        line: 70,
                        column: 8
                      },
                      end: {
                        line: 72,
                        column: 8
                      }
                    },
                    moduleName: "client/templates/paperless/checkin.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["block", "if", [["subexpr", "eq", [["get", "consignment.status", ["loc", [null, [70, 22], [70, 40]]]], "Dropped Off"], [], ["loc", [null, [70, 18], [70, 55]]]]], [], 0, null, ["loc", [null, [70, 8], [72, 8]]]]],
                  locals: [],
                  templates: [child0]
                };
              })();
              return {
                meta: {
                  revision: "Ember@1.13.13",
                  loc: {
                    source: null,
                    start: {
                      line: 67,
                      column: 7
                    },
                    end: {
                      line: 73,
                      column: 8
                    }
                  },
                  moduleName: "client/templates/paperless/checkin.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["subexpr", "eq", [["get", "consignment.status", ["loc", [null, [68, 18], [68, 36]]]], "Not Checked In"], [], ["loc", [null, [68, 14], [68, 54]]]]], [], 0, 1, ["loc", [null, [68, 8], [72, 15]]]]],
                locals: [],
                templates: [child0, child1]
              };
            })();
            var child4 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    revision: "Ember@1.13.13",
                    loc: {
                      source: null,
                      start: {
                        line: 77,
                        column: 7
                      },
                      end: {
                        line: 79,
                        column: 7
                      }
                    },
                    moduleName: "client/templates/paperless/checkin.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("\t\t\t\t\t\t\t\t");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["inline", "md-btn", [], ["text", "CHECK IN", "class", "green", "action", "openDropErrorModal"], ["loc", [null, [78, 8], [78, 77]]]]],
                  locals: [],
                  templates: []
                };
              })();
              var child1 = (function () {
                return {
                  meta: {
                    revision: "Ember@1.13.13",
                    loc: {
                      source: null,
                      start: {
                        line: 79,
                        column: 7
                      },
                      end: {
                        line: 81,
                        column: 7
                      }
                    },
                    moduleName: "client/templates/paperless/checkin.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("\t\t\t\t\t\t\t\t");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["inline", "md-btn", [], ["text", "CHECK IN", "class", "green", "action", "openPrintLabelModal", "actionArg", ["subexpr", "@mut", [["get", "consignment.externalOnlineId", ["loc", [null, [80, 86], [80, 114]]]]], [], []]], ["loc", [null, [80, 8], [80, 116]]]]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  revision: "Ember@1.13.13",
                  loc: {
                    source: null,
                    start: {
                      line: 76,
                      column: 24
                    },
                    end: {
                      line: 82,
                      column: 24
                    }
                  },
                  moduleName: "client/templates/paperless/checkin.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["subexpr", "eq", [["get", "consignment.consignmentDetailsStatus", ["loc", [null, [77, 17], [77, 53]]]], 4], [], ["loc", [null, [77, 13], [77, 56]]]]], [], 0, 1, ["loc", [null, [77, 7], [81, 14]]]]],
                locals: [],
                templates: [child0, child1]
              };
            })();
            var child5 = (function () {
              return {
                meta: {
                  revision: "Ember@1.13.13",
                  loc: {
                    source: null,
                    start: {
                      line: 82,
                      column: 24
                    },
                    end: {
                      line: 84,
                      column: 24
                    }
                  },
                  moduleName: "client/templates/paperless/checkin.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "md-btn", [], ["text", "DOWNLOAD LABEL", "class", "blue", "action", "downloadReprintlabel", "actionArg", ["subexpr", "@mut", [["get", "consignment.externalOnlineId", ["loc", [null, [83, 110], [83, 138]]]]], [], []]], ["loc", [null, [83, 26], [83, 140]]]]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                revision: "Ember@1.13.13",
                loc: {
                  source: null,
                  start: {
                    line: 51,
                    column: 16
                  },
                  end: {
                    line: 87,
                    column: 16
                  }
                },
                moduleName: "client/templates/paperless/checkin.hbs"
              },
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                   ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("tr");
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                dom.setAttribute(el2, "class", "dont-break-out");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                dom.setAttribute(el2, "class", "dont-break-out");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                dom.setAttribute(el2, "class", "dont-break-out");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                       ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                dom.setAttribute(el2, "class", "dont-break-out");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                        ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                var el3 = dom.createTextNode("\t\t\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                         ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                dom.setAttribute(el2, "class", "nowrap");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                        ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element10 = dom.childAt(fragment, [1]);
                var morphs = new Array(6);
                morphs[0] = dom.createMorphAt(dom.childAt(element10, [1]), 0, 0);
                morphs[1] = dom.createMorphAt(dom.childAt(element10, [3]), 0, 0);
                morphs[2] = dom.createMorphAt(dom.childAt(element10, [5]), 0, 0);
                morphs[3] = dom.createMorphAt(dom.childAt(element10, [7]), 1, 1);
                morphs[4] = dom.createMorphAt(dom.childAt(element10, [9]), 1, 1);
                morphs[5] = dom.createMorphAt(dom.childAt(element10, [11]), 1, 1);
                return morphs;
              },
              statements: [["content", "consignment.contactName", ["loc", [null, [53, 47], [53, 74]]]], ["content", "consignment.returnHubbedId", ["loc", [null, [54, 47], [54, 77]]]], ["content", "consignment.consignmentNumber", ["loc", [null, [55, 47], [55, 80]]]], ["block", "if", [["subexpr", "eq", [["get", "consignment.checkinTime", ["loc", [null, [57, 34], [57, 57]]]], null], [], ["loc", [null, [57, 30], [57, 63]]]]], [], 0, 1, ["loc", [null, [57, 24], [61, 31]]]], ["block", "if", [["subexpr", "eq", [["get", "consignment.consignmentDetailsStatus", ["loc", [null, [64, 17], [64, 53]]]], 4], [], ["loc", [null, [64, 13], [64, 56]]]]], [], 2, 3, ["loc", [null, [64, 7], [73, 15]]]], ["block", "if", [["subexpr", "eq", [["get", "consignment.status", ["loc", [null, [76, 34], [76, 52]]]], "Not Checked In"], [], ["loc", [null, [76, 30], [76, 70]]]]], [], 4, 5, ["loc", [null, [76, 24], [84, 31]]]]],
              locals: ["consignment"],
              templates: [child0, child1, child2, child3, child4, child5]
            };
          })();
          return {
            meta: {
              revision: "Ember@1.13.13",
              loc: {
                source: null,
                start: {
                  line: 37,
                  column: 10
                },
                end: {
                  line: 93,
                  column: 11
                }
              },
              moduleName: "client/templates/paperless/checkin.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("table");
              dom.setAttribute(el1, "class", "striped");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("thead");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("tr");
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("th");
              var el5 = dom.createTextNode("Customer Name");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("th");
              var el5 = dom.createTextNode("Return Ref#");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("th");
              var el5 = dom.createTextNode("Consignment");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("th");
              var el5 = dom.createTextNode("Scan in Date ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("th");
              var el5 = dom.createTextNode("Status");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("th");
              var el5 = dom.createTextNode("Action");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                 ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("tbody");
              var el3 = dom.createTextNode("\n\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("thead");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("tr");
              dom.setAttribute(el3, "colspan", "9");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]), 1, 1);
              return morphs;
            },
            statements: [["block", "each", [["get", "consignments", ["loc", [null, [51, 24], [51, 36]]]]], [], 0, null, ["loc", [null, [51, 16], [87, 25]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              revision: "Ember@1.13.13",
              loc: {
                source: null,
                start: {
                  line: 93,
                  column: 11
                },
                end: {
                  line: 95,
                  column: 10
                }
              },
              moduleName: "client/templates/paperless/checkin.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "center");
              var el2 = dom.createTextNode("No data found.");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            revision: "Ember@1.13.13",
            loc: {
              source: null,
              start: {
                line: 36,
                column: 8
              },
              end: {
                line: 96,
                column: 8
              }
            },
            moduleName: "client/templates/paperless/checkin.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "consignments", ["loc", [null, [37, 16], [37, 28]]]]], [], 0, 1, ["loc", [null, [37, 10], [95, 17]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          revision: "Ember@1.13.13",
          loc: {
            source: null,
            start: {
              line: 30,
              column: 7
            },
            end: {
              line: 97,
              column: 8
            }
          },
          moduleName: "client/templates/paperless/checkin.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "consignmentsLoading", ["loc", [null, [31, 14], [31, 33]]]]], [], 0, 1, ["loc", [null, [31, 8], [96, 15]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child3 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              revision: "Ember@1.13.13",
              loc: {
                source: null,
                start: {
                  line: 106,
                  column: 12
                },
                end: {
                  line: 108,
                  column: 12
                }
              },
              moduleName: "client/templates/paperless/checkin.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("h1");
              var el2 = dom.createTextNode("1. Print Label - ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("  | Con# ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element7 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(element7, 1, 1);
              morphs[1] = dom.createMorphAt(element7, 3, 3);
              return morphs;
            },
            statements: [["content", "popvalue.contactName", ["loc", [null, [107, 33], [107, 57]]]], ["content", "popvalue.consignmentNumber", ["loc", [null, [107, 66], [107, 96]]]]],
            locals: ["popvalue"],
            templates: []
          };
        })();
        return {
          meta: {
            revision: "Ember@1.13.13",
            loc: {
              source: null,
              start: {
                line: 101,
                column: 2
              },
              end: {
                line: 127,
                column: 1
              }
            },
            moduleName: "client/templates/paperless/checkin.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("form");
            dom.setAttribute(el1, "class", "remveColor");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "divideintoTwo");
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("a");
            dom.setAttribute(el3, "class", "ck");
            var el4 = dom.createElement("i");
            dom.setAttribute(el4, "class", "mdi-navigation-close");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "modal-header");
            var el4 = dom.createTextNode(" \n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("p");
            var el5 = dom.createTextNode("Download and Print the Toll Label");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "modal-content");
            dom.setAttribute(el3, "style", "padding-bottom: 8px;");
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "center-portion");
            var el5 = dom.createTextNode("\n                  ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("img");
            dom.setAttribute(el5, "src", "images/paperless/label_sample.png");
            dom.setAttribute(el5, "alt", "Label");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n               \n          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "modal-footer center");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "custum-width");
            var el5 = dom.createTextNode(" \n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "style", "display:none;");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("img");
            dom.setAttribute(el4, "src", "images/paperless/checkin.png");
            dom.setAttribute(el4, "alt", "checkin");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element8 = dom.childAt(fragment, [1, 1]);
            var element9 = dom.childAt(element8, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createElementMorph(element9);
            morphs[1] = dom.createMorphAt(dom.childAt(element8, [3]), 1, 1);
            morphs[2] = dom.createMorphAt(dom.childAt(element8, [7, 1]), 1, 1);
            return morphs;
          },
          statements: [["element", "action", ["onClosePrintLabelModal"], [], ["loc", [null, [104, 13], [104, 48]]]], ["block", "each", [["get", "popvalue", ["loc", [null, [106, 20], [106, 28]]]]], [], 0, null, ["loc", [null, [106, 12], [108, 21]]]], ["inline", "md-btn", [], ["text", "DOWNLOAD LABEL", "action", "downloadReprint", "actionArg", ["subexpr", "@mut", [["get", "popvalue.externalOnlineId", ["loc", [null, [119, 79], [119, 104]]]]], [], []], "class", "green no-float btn"], ["loc", [null, [119, 12], [119, 135]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          revision: "Ember@1.13.13",
          loc: {
            source: null,
            start: {
              line: 100,
              column: 0
            },
            end: {
              line: 128,
              column: 1
            }
          },
          moduleName: "client/templates/paperless/checkin.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "md-modal", [], [], 0, null, ["loc", [null, [101, 2], [127, 14]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child4 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              revision: "Ember@1.13.13",
              loc: {
                source: null,
                start: {
                  line: 133,
                  column: 5
                },
                end: {
                  line: 154,
                  column: 4
                }
              },
              moduleName: "client/templates/paperless/checkin.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("     ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("form");
              dom.setAttribute(el1, "class", "remveColor");
              var el2 = dom.createTextNode("\n     ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "divideintoTwo");
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("a");
              dom.setAttribute(el3, "class", "ck");
              var el4 = dom.createElement("i");
              dom.setAttribute(el4, "class", "mdi-navigation-close");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3, "class", "modal-header");
              var el4 = dom.createTextNode(" \n          ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("h1");
              var el5 = dom.createTextNode(" 2. Attach Label - ");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode(" | Con# ");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("  ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n          ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("p");
              var el5 = dom.createTextNode("Stick the Toll Consignment Label onto the parcel");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n        ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3, "class", "modal-content");
              dom.setAttribute(el3, "style", "padding-bottom: 8px;");
              var el4 = dom.createTextNode("\n          ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4, "class", "left-portion");
              var el5 = dom.createTextNode("\n            ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("img");
              dom.setAttribute(el5, "src", "images/paperless/checkin.png");
              dom.setAttribute(el5, "alt", "checkin-1");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n          ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n        ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              dom.setAttribute(el3, "class", "modal-footer center");
              var el4 = dom.createTextNode("\n          ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("div");
              dom.setAttribute(el4, "class", "custum-width");
              var el5 = dom.createTextNode("\n            ");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n            ");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n          ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n        ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n      ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element3 = dom.childAt(fragment, [1, 1]);
              var element4 = dom.childAt(element3, [1]);
              var element5 = dom.childAt(element3, [3, 1]);
              var element6 = dom.childAt(element3, [7, 1]);
              var morphs = new Array(5);
              morphs[0] = dom.createElementMorph(element4);
              morphs[1] = dom.createMorphAt(element5, 1, 1);
              morphs[2] = dom.createMorphAt(element5, 3, 3);
              morphs[3] = dom.createMorphAt(element6, 1, 1);
              morphs[4] = dom.createMorphAt(element6, 3, 3);
              return morphs;
            },
            statements: [["element", "action", ["oncloseAttachLabelModal"], [], ["loc", [null, [136, 11], [136, 47]]]], ["content", "popvalue.contactName", ["loc", [null, [138, 33], [138, 57]]]], ["content", "popvalue.consignmentNumber", ["loc", [null, [138, 65], [138, 95]]]], ["inline", "md-btn-submit", [], ["text", "I HAVE ATTACHED THE LABEL", "action", "openCheckinModal", "class", "green no-float btn"], ["loc", [null, [148, 12], [148, 115]]]], ["inline", "md-btn", [], ["text", "DOWNLOAD LABEL", "action", "downloadReprint", "class", "blue no-float btn"], ["loc", [null, [149, 12], [149, 95]]]]],
            locals: ["popvalue"],
            templates: []
          };
        })();
        return {
          meta: {
            revision: "Ember@1.13.13",
            loc: {
              source: null,
              start: {
                line: 132,
                column: 2
              },
              end: {
                line: 155,
                column: 2
              }
            },
            moduleName: "client/templates/paperless/checkin.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "each", [["get", "popvalue", ["loc", [null, [133, 13], [133, 21]]]]], [], 0, null, ["loc", [null, [133, 5], [154, 13]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          revision: "Ember@1.13.13",
          loc: {
            source: null,
            start: {
              line: 131,
              column: 1
            },
            end: {
              line: 156,
              column: 2
            }
          },
          moduleName: "client/templates/paperless/checkin.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "md-modal", [], [], 0, null, ["loc", [null, [132, 2], [155, 15]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child5 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              revision: "Ember@1.13.13",
              loc: {
                source: null,
                start: {
                  line: 172,
                  column: 10
                },
                end: {
                  line: 186,
                  column: 10
                }
              },
              moduleName: "client/templates/paperless/checkin.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("           ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "rigth-portion");
              var el2 = dom.createTextNode("\n               ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "receiver-name consigmentno");
              var el3 = dom.createTextNode("\n                   ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("label");
              var el4 = dom.createTextNode("Consignment Number");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                   ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                 ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "receiver-name");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "receiver-name refrence");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createTextNode("Ref# ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              \n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 3]), 0, 0);
              morphs[1] = dom.createMorphAt(dom.childAt(element0, [3, 1]), 0, 0);
              morphs[2] = dom.createMorphAt(dom.childAt(element0, [5, 1]), 1, 1);
              return morphs;
            },
            statements: [["content", "popvalue.consignmentNumber", ["loc", [null, [176, 25], [176, 55]]]], ["content", "popvalue.contactName", ["loc", [null, [179, 22], [179, 46]]]], ["content", "popvalue.returnHubbedId", ["loc", [null, [182, 27], [182, 54]]]]],
            locals: ["popvalue"],
            templates: []
          };
        })();
        return {
          meta: {
            revision: "Ember@1.13.13",
            loc: {
              source: null,
              start: {
                line: 160,
                column: 3
              },
              end: {
                line: 193,
                column: 2
              }
            },
            moduleName: "client/templates/paperless/checkin.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("form");
            dom.setAttribute(el1, "class", "remveColor");
            var el2 = dom.createTextNode("\n     ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "divideintoTwo third-popup");
            var el3 = dom.createTextNode("\n         ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("a");
            dom.setAttribute(el3, "class", "ck");
            var el4 = dom.createElement("i");
            dom.setAttribute(el4, "class", "mdi-navigation-close");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "modal-header");
            var el4 = dom.createTextNode(" \n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("h1");
            var el5 = dom.createTextNode(" 3. Confirm the Return details ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("p");
            var el5 = dom.createTextNode("Ensure all the details on the Label are correct - then select 'Check in'");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n      ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "modal-content");
            dom.setAttribute(el3, "style", "padding-bottom: 8px;");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "left-portion");
            var el5 = dom.createTextNode("\n           ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("img");
            dom.setAttribute(el5, "src", "images/paperless/confirm_popup.png");
            dom.setAttribute(el5, "alt", "label_sample");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n         ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "modal-footer center");
            var el4 = dom.createTextNode("\n       ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "custum-width");
            var el5 = dom.createTextNode(" ");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n      ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n    ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n   ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1, 1]);
            var element2 = dom.childAt(element1, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createElementMorph(element2);
            morphs[1] = dom.createMorphAt(dom.childAt(element1, [5]), 3, 3);
            morphs[2] = dom.createMorphAt(dom.childAt(element1, [7, 1]), 1, 1);
            return morphs;
          },
          statements: [["element", "action", ["onCloseCheckinModal"], [], ["loc", [null, [163, 12], [163, 44]]]], ["block", "each", [["get", "popvalue", ["loc", [null, [172, 18], [172, 26]]]]], [], 0, null, ["loc", [null, [172, 10], [186, 19]]]], ["inline", "md-btn", [], ["text", "CHECK IN", "action", "Checkin", "class", "green no-float"], ["loc", [null, [189, 34], [189, 102]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          revision: "Ember@1.13.13",
          loc: {
            source: null,
            start: {
              line: 159,
              column: 0
            },
            end: {
              line: 194,
              column: 0
            }
          },
          moduleName: "client/templates/paperless/checkin.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "md-modal", [], [], 0, null, ["loc", [null, [160, 3], [193, 15]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child6 = (function () {
      var child0 = (function () {
        return {
          meta: {
            revision: "Ember@1.13.13",
            loc: {
              source: null,
              start: {
                line: 198,
                column: 1
              },
              end: {
                line: 213,
                column: 1
              }
            },
            moduleName: "client/templates/paperless/checkin.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\t\t");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            var el2 = dom.createTextNode("\n\t\t\t");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "modal-header");
            var el3 = dom.createTextNode(" \n\t\t\t\t");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("center");
            var el4 = dom.createElement("h2");
            dom.setAttribute(el4, "class", "red-textt");
            dom.setAttribute(el4, "style", "font-size: 2.5rem; margin-top:2.5rem;");
            var el5 = dom.createElement("b");
            var el6 = dom.createTextNode(" EXPIRED RETURN ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\t\t\t");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n\t\t\t");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "modal-content");
            var el3 = dom.createTextNode("\n\t\t\t\t\n\t\t\t\t");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "row no-bottom");
            var el4 = dom.createElement("center");
            var el5 = dom.createElement("h5");
            dom.setAttribute(el5, "style", "font-size: 1.5rem;");
            var el6 = dom.createTextNode("This parcel is expired ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("font");
            dom.setAttribute(el6, "class", "red-textt");
            var el7 = dom.createElement("b");
            var el8 = dom.createTextNode("and cannot be accepted.");
            dom.appendChild(el7, el8);
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode(" ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("br");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("br");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode(" Please return the parcel to the customer and ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("br");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("ask them to contact the seller.");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\t\t\t");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\t\t\t\n\t\t\t");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "modal-footer remove-scroll center");
            var el3 = dom.createTextNode("\n\t\t\t\t");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "style", "margin-bottom:2.5rem;");
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\t\t\t");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\t\t");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 5, 1]), 1, 1);
            return morphs;
          },
          statements: [["inline", "md-btn", [], ["text", "CONFIRM", "action", "closeDropErrorModal", "class", "blue no-float"], ["loc", [null, [210, 40], [210, 118]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          revision: "Ember@1.13.13",
          loc: {
            source: null,
            start: {
              line: 197,
              column: 0
            },
            end: {
              line: 214,
              column: 0
            }
          },
          moduleName: "client/templates/paperless/checkin.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "md-modal", [], ["close", "closeCheckinModal"], 0, null, ["loc", [null, [198, 1], [213, 14]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        revision: "Ember@1.13.13",
        loc: {
          source: null,
          start: {
            line: 1,
            column: 0
          },
          end: {
            line: 220,
            column: 16
          }
        },
        moduleName: "client/templates/paperless/checkin.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "id", "content");
        dom.setAttribute(el1, "class", "col s12 full-height indigo lighten-5");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col s12");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "headerWithFilter");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h1");
        dom.setAttribute(el5, "class", "page-heading");
        var el6 = dom.createTextNode("Paperless  Returns");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "filterBtn");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment(" Manual Override ");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n\n");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n      ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element13 = dom.childAt(fragment, [0, 1, 1]);
        var element14 = dom.childAt(element13, [1]);
        var morphs = new Array(8);
        morphs[0] = dom.createMorphAt(dom.childAt(element14, [3]), 1, 1);
        morphs[1] = dom.createMorphAt(element14, 5, 5);
        morphs[2] = dom.createMorphAt(element13, 3, 3);
        morphs[3] = dom.createMorphAt(element13, 4, 4);
        morphs[4] = dom.createMorphAt(element13, 8, 8);
        morphs[5] = dom.createMorphAt(element13, 10, 10);
        morphs[6] = dom.createMorphAt(element13, 12, 12);
        morphs[7] = dom.createMorphAt(element13, 14, 14);
        return morphs;
      },
      statements: [["block", "if", [false], [], 0, null, ["loc", [null, [7, 10], [13, 17]]]], ["inline", "pagination-component", [], ["page", ["subexpr", "@mut", [["get", "page", ["loc", [null, [15, 36], [15, 40]]]]], [], []], "totalPages", ["subexpr", "@mut", [["get", "totalPages", ["loc", [null, [15, 52], [15, 62]]]]], [], []], "getPageAction", ["subexpr", "action", ["getPage"], [], ["loc", [null, [15, 77], [15, 95]]]], "class", "finger-pagination"], ["loc", [null, [15, 8], [15, 124]]]], ["block", "if", [true], [], 1, null, ["loc", [null, [17, 6], [29, 13]]]], ["block", "md-card-panel", [], ["class", "transparent-card"], 2, null, ["loc", [null, [30, 7], [97, 26]]]], ["block", "if", [["get", "showPrintLabelModal", ["loc", [null, [100, 6], [100, 25]]]]], [], 3, null, ["loc", [null, [100, 0], [128, 8]]]], ["block", "if", [["get", "showAttachLabelModal", ["loc", [null, [131, 7], [131, 27]]]]], [], 4, null, ["loc", [null, [131, 1], [156, 9]]]], ["block", "if", [["get", "showCheckinModal", ["loc", [null, [159, 6], [159, 22]]]]], [], 5, null, ["loc", [null, [159, 0], [194, 7]]]], ["block", "if", [["get", "showDropErrorModal", ["loc", [null, [197, 6], [197, 24]]]]], [], 6, null, ["loc", [null, [197, 0], [214, 7]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6]
    };
  })());
});