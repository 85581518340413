define("client/helpers/object-helper", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.objectHelper = objectHelper;
  function objectHelper(params) {
    var current = params[0];
    for (var i = 1; i < params.length; i++) {
      current = current[params[i]];
    }
    return current;
  }

  exports["default"] = Ember.Helper.helper(objectHelper);
});