define("client/views/default-column-header", ["exports", "ember-cli-materialize/views/default-column-header"], function (exports, _defaultColumnHeader) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function get() {
      return _defaultColumnHeader["default"];
    }
  });
});