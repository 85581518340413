define("client/helpers/environment-flag", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = Ember.Helper.extend({
    config: Ember.inject.service(),
    compute: function compute() {
      switch (this.get("config.environmentFlag")) {
        case "development":
          return "Dev";
        case "staging":
          return "Staging";
        case "demo":
          return "Demo";
      }
      return "";
    }
  });
});