define("client/routes/consignments/handover", ["exports", "ic-ajax", "ember-simple-auth/mixins/authenticated-route-mixin"], function (exports, _icAjax, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = Ember.Route.extend(_authenticatedRouteMixin["default"], {
    config: Ember.inject.service("config"),
    session: Ember.inject.service(),

    setupController: function setupController(controller) {
      var currentDate = new Date();
      //controller.set("handoverModal", true);
      var currentDateUnix = currentDate.getTime();
      controller.set("currentDateUnix", currentDateUnix);
      controller.set("currentStoreDlb", null);
      controller.set("ebayManualCheckinSelection", true);

      (0, _icAjax["default"])({
        url: this.get("config.apiURL") + "dashboard",
        type: "get",
        headers: {
          Authorization: "Bearer " + this.get("session.data").authenticated.jwt
        },
        crossDomain: true
      }).then(function (results) {
        controller.set("currentStoreDlb", results.dropLocation.hubbedLocationData.locationCode);
      })["catch"](function (error) {
        if (error && error.jqXHR && error.jqXHR.status === 401) {
          controller.get("session").invalidate();
        }
      });

      (0, _icAjax["default"])({
        url: this.get("config.apiURL") + "v2/consumer/drops/search/handover?page=" + controller.get("page") + "&perPage=" + controller.get("perPage") + "&limited=true&orderBy=time_waiting&orderDir=DESC",
        type: "get",
        headers: {
          Authorization: "Bearer " + this.get("session.data").authenticated.jwt
        },
        crossDomain: true
      }).then(function (results) {
        controller.set("testlist", null);
        controller.set("consignments", results.data);
        controller.set("consignmentsLoading", false);
        controller.set("page", results.page);
        controller.set("totalPages", results.totalPages);
      })["catch"](function (error) {
        if (error && error.jqXHR && error.jqXHR.status === 401) {
          controller.get("session").invalidate();
        }
      });
    }
  });
});