define("client/helpers/needs-quick-checkin", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.needsQuickCheckin = needsQuickCheckin;
  function needsQuickCheckin(params) {
    var articles = params[0];
    var states = articles.map(function (a) {
      return a.currentState.state;
    });

    // Check whether any article is in the courier check-in state
    return states.indexOf("notYetCheckedIn") > -1;
  }

  exports["default"] = Ember.Helper.helper(needsQuickCheckin);
});