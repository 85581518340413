define("client/helpers/array-first-helper", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.arrayFirstHelper = arrayFirstHelper;
  function arrayFirstHelper(params) {
    return params[0][0];
  }

  exports["default"] = Ember.Helper.helper(arrayFirstHelper);
});