define("client/helpers/courier-helper", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.courierHelper = courierHelper;
  function courierHelper(params) {
    var courier = params[0];
    switch (courier) {
      case "cpl":
        return "CouriersPlease";
      case "dhl":
        return "DHL (Express)";
      case "dsc":
        return "DHL Supply Chain";
      case "rr":
        return "RoadRunners";
      case "toll":
        return "Toll";
      default:
        return courier;
    }
  }

  exports["default"] = Ember.Helper.helper(courierHelper);
});