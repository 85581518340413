define("client/controllers/international/archive", ["exports", "ic-ajax"], function (exports, _icAjax) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = Ember.Controller.extend({
    session: Ember.inject.service(),
    config: Ember.inject.service("config"),
    consignments: Ember.computed.alias("model"),
    printConsignment: null,
    printModal: false,
    queryParams: ["page"],
    page: 1,

    actions: {
      getPage: function getPage(page) {
        var self = this;
        (0, _icAjax["default"])({
          url: self.get("config.apiURL") + "v2/outbound/international/consignments?page=" + page,
          type: "get",
          headers: {
            Authorization: "Bearer " + self.get("session.data").authenticated.jwt
          },
          crossDomain: true
        }).then(function (result) {
          self.set("consignments", result.results);
          self.set("page", result.page);
          self.set("totalPages", result.totalPages);
        })["catch"](function (error) {
          if (error && error.jqXHR && error.jqXHR.status === 401) {
            self.get("session").invalidate();
          }
        });
      },

      "new": function _new() {
        this.transitionToRoute("international.book");
      },

      printModalActivate: function printModalActivate(consignment) {
        this.set("printConsignment", consignment);
        this.set("printModal", true);
      },

      closePrintModal: function closePrintModal() {
        this.set("printConsignment", null);
        this.set("printModal", false);
      }
    }
  });
});