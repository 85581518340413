define("client/mixins/sortable-render", ["exports", "client/mixins/after-render"], function (exports, _afterRender) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = Ember.Mixin.create(_afterRender["default"], {
    afterRenderEvent: function afterRenderEvent() {
      Sortable.init();
    }
  });
});