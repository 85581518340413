define("client/routes/reports/awaiting-pickup", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = Ember.Route.extend(_authenticatedRouteMixin["default"], {
    setupController: function setupController(controller) {
      controller.send("getPage", 1);
    }
  });
});