define("client/initializers/highcharts", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(container) {
    container.optionsForType("highcharts-config", { instantiate: false });
  }

  exports["default"] = {
    name: "highcharts",
    initialize: initialize
  };
});