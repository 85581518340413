define("client/routes/outbound/send", ["exports", "ic-ajax", "ember-simple-auth/mixins/authenticated-route-mixin"], function (exports, _icAjax, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = Ember.Route.extend(_authenticatedRouteMixin["default"], {
    config: Ember.inject.service("config"),
    session: Ember.inject.service(),

    model: function model() {
      var self = this; // Store the route context
      return (0, _icAjax["default"])({
        url: this.get("config.apiURL") + "outbound",
        type: "get",
        headers: {
          Authorization: "Bearer " + this.get("session.data").authenticated.jwt
        },
        crossDomain: true
      })["catch"](function (error) {
        if (error && error.jqXHR && error.jqXHR.status === 401) {
          self.get("session").invalidate();
        }
      });
    },
    setupController: function setupController(controller, model) {
      var self = this;
      controller.set("consignments", model.consignments);
      //SHould remove tmp once backend done
      var tmp = [{ code: "P0", weight: 500, name: "Small Satchel" }];
      controller.set("articleSizes", tmp);
      (0, _icAjax["default"])({
        url: this.get("config.apiURL") + "outbound/packageCodes",
        type: "get",
        headers: {
          Authorization: "Bearer " + this.get("session.data").authenticated.jwt
        },
        crossDomain: true
      }).then(function (results) {
        controller.set("articleSizes", results);
      })["catch"](function (error) {
        if (error && error.jqXHR && error.jqXHR.status === 401) {
          self.get("session").invalidate();
        }
      });
    }
  });
});