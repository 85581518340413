define("client/helpers/state-helper", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.stateHelper = stateHelper;
  function stateHelper(params) {
    var state = params[0];
    switch (state) {
      case "notYetCheckedIn":
        return "Awaiting Pickup";
      case "awaitingCustomerPickup":
        return "Awaiting Pickup";
      case "awaitingCourierPickup":
        // return "In Transit"; // this is changed only for demo purpose
        return "Return Requested";
      case "awaitingCourierPickupOutbound":
        return "Outbound Parcel";
      case "customerHasPickedUp":
        return "Delivered";
      case "courierHasPickedUp":
        return "Returned To Courier";
      default:
        return "Unknown Status";
    }
  }

  exports["default"] = Ember.Helper.helper(stateHelper);
});