define("client/components/md-address-suggest", ["exports", "ember-cli-materialize/components/md-input-field", "ic-ajax"], function (exports, _mdInputField, _icAjax) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = _mdInputField["default"].extend({
    config: Ember.inject.service(),
    session: Ember.inject.service(),

    type: "text",
    miniumCharLength: 5,
    escapedChars: [40, 38, 13],
    throttleTime: 400,
    selectedFromList: false,
    latitude: null,
    longitude: null,
    topBar: null,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this._setupLabel();
    },

    keyUp: function keyUp(event) {
      this._setupLabel();
      var _scope = this;
      if (event.keyCode === 27) {
        _scope.set("suggestions", []);
      } else if (this.escapedChars.indexOf(event.keyCode) === -1) {
        //Check to see if its not arrows or enter
        if (this.get("value").length > this.miniumCharLength) {
          Ember.run.throttle(this, this.searchAddress, this.throttleTime);
        }
      }
    },

    focusIn: function focusIn() {
      if (!this.get("selectedFromList")) {
        Ember.run.throttle(this, this.searchAddress, this.throttleTime);
      }
    },

    focusOut: function focusOut() {
      var _scope = this;
      var func = function func() {
        _scope.set("suggestions", []);
      };
      Ember.run.later(this, func, 400); // set a little delay so give the select a chance to set
    },

    searchAddress: function searchAddress() {
      var _scope = this;
      (0, _icAjax["default"])({
        url: this.get("config.apiURL") + "address/autocomplete?input=" + _scope.get("value") + "&latitude=" + _scope.get("latitude") + "&longitude=" + _scope.get("longitude"),
        type: "GET",
        headers: {
          Authorization: "Bearer " + _scope.get("session.data").authenticated.jwt
        },
        contentType: "application/json",
        crossDomain: true
      }).then(function (data) {
        _scope.set("suggestions", data.predictions);
      });
    },

    actions: {
      selectItem: function selectItem(value) {
        if (this.get("useDescription")) {
          this.set("value", value.description);
        } else {
          this.set("value", value.structured_formatting.main_text);
        }
        this.set("selectedFromList", true);
        this.set("suggestions", []);
      }
    }

  });
});