define("client/helpers/moment-format", ["exports", "ember-moment/helpers/moment-format", "ember-moment/utils/make-bound-helper", "client/config/environment"], function (exports, _momentFormat, _makeBoundHelper, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.computeFn = undefined;
  var computeFn = exports.computeFn = (0, _momentFormat["default"])(Ember.get(_environment["default"], "moment.outputFormat"));

  exports["default"] = (0, _makeBoundHelper["default"])(computeFn);
});