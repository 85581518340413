define("client/components/power-select", ["exports", "ember-power-select/components/power-select"], function (exports, _powerSelect) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function get() {
      return _powerSelect["default"];
    }
  });
});