define("client/components/webcam-snap", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports["default"] = Ember.Component.extend({
		localStream: null,
		base64Data: null,
		captured: false,
		canvasClass: "hide",
		videoClass: "",

		setupFlags: (function () {
			navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;

			var constraints = {
				video: {
					mandatory: {
						minWidth: 1280,
						minHeight: 720
					}
				}
			};

			navigator.getUserMedia(constraints, function (stream) {
				var video = document.querySelector("video");
				video.src = window.URL.createObjectURL(stream);
			}, function () {
				console.log("Falling back to low res");
				navigator.getUserMedia({ video: true }, function (stream) {
					var video = document.querySelector("video");
					video.src = window.URL.createObjectURL(stream);
				}, function (e) {
					console.log(e);
				});
			});
		}).on("init"),

		actions: {
			snap: function snap() {
				var video = document.querySelector("video");
				var previewCanvas = Ember.$("#webcam-preview")[0];
				var ctx = previewCanvas.getContext("2d");

				ctx.drawImage(video, 0, 0, 1280, 720);

				this.set("base64Data", previewCanvas.toDataURL("image/png", 1));
				this.set("captured", true);
				this.set("canvasClass", "");
				this.set("videoClass", "hide");
			},

			reset: function reset() {
				this.set("base64Data", null);
				this.set("captured", false);
				this.set("canvasClass", "hide");
				this.set("videoClass", "");
			}
		}

	});
});