define("client/helpers/not", ["exports", "ember-truth-helpers/helpers/not"], function (exports, _not) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var forExport = null;

  if (Ember.Helper) {
    forExport = Ember.Helper.helper(_not.notHelper);
  } else if (Ember.HTMLBars.makeBoundHelper) {
    forExport = Ember.HTMLBars.makeBoundHelper(_not.notHelper);
  }

  exports["default"] = forExport;
});