define("client/helpers/current-state", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.currentState = currentState;
  function currentState(params) {
    var article = params[0];
    var state = params[1];
    return article.currentState.state === state;
  }

  exports["default"] = Ember.Helper.helper(currentState);
});