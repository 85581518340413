define("client/templates/authenticated", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            revision: "Ember@1.13.13",
            loc: {
              source: null,
              start: {
                line: 16,
                column: 8
              },
              end: {
                line: 21,
                column: 8
              }
            },
            moduleName: "client/templates/authenticated.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "mdi-alert-error blue-text");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1, "class", "text");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
            return morphs;
          },
          statements: [["content", "errorText", ["loc", [null, [20, 24], [20, 37]]]]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              revision: "Ember@1.13.13",
              loc: {
                source: null,
                start: {
                  line: 22,
                  column: 10
                },
                end: {
                  line: 25,
                  column: 10
                }
              },
              moduleName: "client/templates/authenticated.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("i");
              dom.setAttribute(el1, "class", "mdi-action-done blue-text");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              dom.setAttribute(el1, "class", "text");
              var el2 = dom.createTextNode("Success");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                revision: "Ember@1.13.13",
                loc: {
                  source: null,
                  start: {
                    line: 25,
                    column: 10
                  },
                  end: {
                    line: 28,
                    column: 10
                  }
                },
                moduleName: "client/templates/authenticated.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("i");
                dom.setAttribute(el1, "class", "mdi-action-done blue-text");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                dom.setAttribute(el1, "class", "text");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
                return morphs;
              },
              statements: [["content", "infoText", ["loc", [null, [27, 28], [27, 40]]]]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                revision: "Ember@1.13.13",
                loc: {
                  source: null,
                  start: {
                    line: 28,
                    column: 10
                  },
                  end: {
                    line: 31,
                    column: 10
                  }
                },
                moduleName: "client/templates/authenticated.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("p");
                dom.setAttribute(el1, "class", "text");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n          ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
                return morphs;
              },
              statements: [["inline", "md-loader", [], ["mode", "circular"], ["loc", [null, [29, 12], [29, 41]]]], ["content", "loadingText", ["loc", [null, [30, 28], [30, 43]]]]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              revision: "Ember@1.13.13",
              loc: {
                source: null,
                start: {
                  line: 25,
                  column: 10
                },
                end: {
                  line: 31,
                  column: 10
                }
              },
              moduleName: "client/templates/authenticated.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "loadingSuccessInfo", ["loc", [null, [25, 20], [25, 38]]]]], [], 0, 1, ["loc", [null, [25, 10], [31, 10]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            revision: "Ember@1.13.13",
            loc: {
              source: null,
              start: {
                line: 21,
                column: 8
              },
              end: {
                line: 32,
                column: 8
              }
            },
            moduleName: "client/templates/authenticated.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "loadingSuccess", ["loc", [null, [22, 16], [22, 30]]]]], [], 0, 1, ["loc", [null, [22, 10], [31, 17]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          revision: "Ember@1.13.13",
          loc: {
            source: null,
            start: {
              line: 13,
              column: 2
            },
            end: {
              line: 35,
              column: 2
            }
          },
          moduleName: "client/templates/authenticated.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "id", "loading-overlay");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "loading-box");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
          return morphs;
        },
        statements: [["block", "if", [["get", "loadingError", ["loc", [null, [16, 14], [16, 26]]]]], [], 0, 1, ["loc", [null, [16, 8], [32, 15]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            revision: "Ember@1.13.13",
            loc: {
              source: null,
              start: {
                line: 39,
                column: 4
              },
              end: {
                line: 49,
                column: 4
              }
            },
            moduleName: "client/templates/authenticated.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "modaltopright");
            var el2 = dom.createElement("a");
            var el3 = dom.createElement("i");
            dom.setAttribute(el3, "class", "mdi-navigation-close");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "modal-content center");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("h4");
            var el3 = dom.createTextNode("Your Quick Auth Code is:");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", " center");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element13 = dom.childAt(fragment, [1, 0]);
            var element14 = dom.childAt(fragment, [5]);
            var morphs = new Array(4);
            morphs[0] = dom.createElementMorph(element13);
            morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 3, 3);
            morphs[2] = dom.createMorphAt(element14, 1, 1);
            morphs[3] = dom.createMorphAt(element14, 3, 3);
            return morphs;
          },
          statements: [["element", "action", ["closeDailyCode"], [], ["loc", [null, [40, 36], [40, 63]]]], ["inline", "js-barcode", [], ["value", ["subexpr", "@mut", [["get", "dailyCode", ["loc", [null, [43, 27], [43, 36]]]]], [], []], "class", "center"], ["loc", [null, [43, 8], [43, 53]]]], ["inline", "md-btn", [], ["text", "Print", "icon", "mdi-action-print", "buttonType", "large", "iconPosition", "right", "action", "printDailyCode", "class", "blue space white-text center "], ["loc", [null, [46, 6], [46, 156]]]], ["inline", "md-btn", [], ["buttonType", "large", "text", "Done", "action", "closeDailyCode", "class", "green space center  white-text  "], ["loc", [null, [47, 6], [47, 112]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          revision: "Ember@1.13.13",
          loc: {
            source: null,
            start: {
              line: 38,
              column: 2
            },
            end: {
              line: 50,
              column: 2
            }
          },
          moduleName: "client/templates/authenticated.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "md-modal", [], ["class", "auth-modal"], 0, null, ["loc", [null, [39, 4], [49, 17]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            revision: "Ember@1.13.13",
            loc: {
              source: null,
              start: {
                line: 54,
                column: 4
              },
              end: {
                line: 65,
                column: 4
              }
            },
            moduleName: "client/templates/authenticated.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "modal-content center");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("h4");
            var el3 = dom.createTextNode("Are you sure you want to log out?\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("br");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("br");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("br");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("br");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element12 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element12, 3, 3);
            morphs[1] = dom.createMorphAt(element12, 5, 5);
            return morphs;
          },
          statements: [["inline", "md-btn", [], ["text", "Stay Logged In", "action", "closeSignOut", "buttonType", "large", "class", "green white-text  "], ["loc", [null, [59, 8], [60, 77]]]], ["inline", "md-btn", [], ["text", "Log Out", "action", "invalidateSession", "buttonType", "large", "class", "red  white-text  "], ["loc", [null, [61, 8], [62, 81]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          revision: "Ember@1.13.13",
          loc: {
            source: null,
            start: {
              line: 53,
              column: 2
            },
            end: {
              line: 66,
              column: 2
            }
          },
          moduleName: "client/templates/authenticated.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "md-modal", [], ["close", "closeModal", "class", "test-modal"], 0, null, ["loc", [null, [54, 4], [65, 17]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child3 = (function () {
      var child0 = (function () {
        return {
          meta: {
            revision: "Ember@1.13.13",
            loc: {
              source: null,
              start: {
                line: 69,
                column: 4
              },
              end: {
                line: 85,
                column: 4
              }
            },
            moduleName: "client/templates/authenticated.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "auth-check-modal");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "modal-content");
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("h4");
            var el4 = dom.createTextNode("Please enter your password to continue");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("form");
            dom.setAttribute(el3, "autocomplete", "off");
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4, "class", "error");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "center");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element9 = dom.childAt(fragment, [1]);
            var element10 = dom.childAt(element9, [1, 3]);
            var element11 = dom.childAt(element9, [3]);
            var morphs = new Array(5);
            morphs[0] = dom.createElementMorph(element10);
            morphs[1] = dom.createMorphAt(element10, 1, 1);
            morphs[2] = dom.createMorphAt(dom.childAt(element10, [3]), 0, 0);
            morphs[3] = dom.createMorphAt(element11, 1, 1);
            morphs[4] = dom.createMorphAt(element11, 3, 3);
            return morphs;
          },
          statements: [["element", "action", ["checkQuickAuth"], ["on", "submit"], ["loc", [null, [73, 16], [73, 55]]]], ["inline", "md-focusinput", [], ["value", ["subexpr", "@mut", [["get", "quickAuthPasscode", ["loc", [null, [74, 34], [74, 51]]]]], [], []], "label", "Password", "autocomplete", "off", "type", "password", "autofocus", "autofocus"], ["loc", [null, [74, 12], [74, 127]]]], ["content", "quickAuthError", ["loc", [null, [75, 32], [75, 50]]]], ["inline", "md-btn", [], ["text", "Authorize", "action", "checkQuickAuth", "buttonType", "large", "class", "green space white-text  "], ["loc", [null, [79, 8], [80, 85]]]], ["inline", "md-btn", [], ["text", "Logout", "action", "invalidateSession", "buttonType", "large", "class", "blue space white-text  "], ["loc", [null, [81, 8], [82, 87]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          revision: "Ember@1.13.13",
          loc: {
            source: null,
            start: {
              line: 68,
              column: 2
            },
            end: {
              line: 86,
              column: 2
            }
          },
          moduleName: "client/templates/authenticated.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "md-modal", [], ["bodyClass", "auth-check-model"], 0, null, ["loc", [null, [69, 4], [85, 17]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child4 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                var child0 = (function () {
                  return {
                    meta: {
                      revision: "Ember@1.13.13",
                      loc: {
                        source: null,
                        start: {
                          line: 116,
                          column: 26
                        },
                        end: {
                          line: 118,
                          column: 26
                        }
                      },
                      moduleName: "client/templates/authenticated.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                            Parcel checked-in\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() {
                      return [];
                    },
                    statements: [],
                    locals: [],
                    templates: []
                  };
                })();
                var child1 = (function () {
                  return {
                    meta: {
                      revision: "Ember@1.13.13",
                      loc: {
                        source: null,
                        start: {
                          line: 118,
                          column: 26
                        },
                        end: {
                          line: 120,
                          column: 26
                        }
                      },
                      moduleName: "client/templates/authenticated.hbs"
                    },
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                            Parcel marked as not here\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() {
                      return [];
                    },
                    statements: [],
                    locals: [],
                    templates: []
                  };
                })();
                return {
                  meta: {
                    revision: "Ember@1.13.13",
                    loc: {
                      source: null,
                      start: {
                        line: 115,
                        column: 24
                      },
                      end: {
                        line: 121,
                        column: 24
                      }
                    },
                    moduleName: "client/templates/authenticated.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["block", "if", [["get", "article.accept", ["loc", [null, [116, 32], [116, 46]]]]], [], 0, 1, ["loc", [null, [116, 26], [120, 33]]]]],
                  locals: [],
                  templates: [child0, child1]
                };
              })();
              var child1 = (function () {
                return {
                  meta: {
                    revision: "Ember@1.13.13",
                    loc: {
                      source: null,
                      start: {
                        line: 121,
                        column: 24
                      },
                      end: {
                        line: 124,
                        column: 24
                      }
                    },
                    moduleName: "client/templates/authenticated.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                          ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                          ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                    return morphs;
                  },
                  statements: [["inline", "md-btn", [], ["text", "Accept check in", "class", "blue", "action", ["subexpr", "action", ["modalAcceptCheckInBarcodeWise", ["get", "consignment", ["loc", [null, [122, 118], [122, 129]]]], ["get", "article", ["loc", [null, [122, 130], [122, 137]]]]], [], ["loc", [null, [122, 78], [122, 138]]]]], ["loc", [null, [122, 26], [122, 140]]]], ["inline", "md-btn", [], ["text", "Parcel is not here", "class", "red", "action", ["subexpr", "action", ["modalRejectCheckInBarcodeWise", ["get", "consignment", ["loc", [null, [123, 120], [123, 131]]]], ["get", "article", ["loc", [null, [123, 132], [123, 139]]]]], [], ["loc", [null, [123, 80], [123, 140]]]]], ["loc", [null, [123, 26], [123, 142]]]]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  revision: "Ember@1.13.13",
                  loc: {
                    source: null,
                    start: {
                      line: 107,
                      column: 18
                    },
                    end: {
                      line: 127,
                      column: 18
                    }
                  },
                  moduleName: "client/templates/authenticated.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n                    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("tr");
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("td");
                  dom.setAttribute(el2, "class", "tdWrdBreak");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("td");
                  dom.setAttribute(el2, "class", "tdWrdBreak");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("br");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("td");
                  dom.setAttribute(el2, "class", "tdWrdBreak");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("td");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                      ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("td");
                  var el3 = dom.createTextNode("\n");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("                      ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("   \n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element7 = dom.childAt(fragment, [3]);
                  var morphs = new Array(6);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  morphs[1] = dom.createMorphAt(dom.childAt(element7, [1]), 0, 0);
                  morphs[2] = dom.createMorphAt(dom.childAt(element7, [3]), 0, 0);
                  morphs[3] = dom.createMorphAt(dom.childAt(element7, [5]), 0, 0);
                  morphs[4] = dom.createMorphAt(dom.childAt(element7, [7]), 0, 0);
                  morphs[5] = dom.createMorphAt(dom.childAt(element7, [9]), 1, 1);
                  return morphs;
                },
                statements: [["inline", "log", [["get", "article", ["loc", [null, [108, 26], [108, 33]]]]], [], ["loc", [null, [108, 20], [108, 35]]]], ["content", "consignment.consignmentNumber", ["loc", [null, [110, 45], [110, 78]]]], ["content", "article.barcode", ["loc", [null, [111, 45], [111, 64]]]], ["inline", "courier-helper", [["get", "consignment.courierName", ["loc", [null, [112, 62], [112, 85]]]]], [], ["loc", [null, [112, 45], [112, 87]]]], ["content", "consignment.receiver.name", ["loc", [null, [113, 26], [113, 55]]]], ["block", "if", [["get", "article.marked", ["loc", [null, [115, 30], [115, 44]]]]], [], 0, 1, ["loc", [null, [115, 24], [124, 31]]]]],
                locals: ["article"],
                templates: [child0, child1]
              };
            })();
            return {
              meta: {
                revision: "Ember@1.13.13",
                loc: {
                  source: null,
                  start: {
                    line: 106,
                    column: 18
                  },
                  end: {
                    line: 129,
                    column: 18
                  }
                },
                moduleName: "client/templates/authenticated.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [["block", "each", [["get", "consignment.articles", ["loc", [null, [107, 26], [107, 46]]]]], [], 0, null, ["loc", [null, [107, 18], [127, 27]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  revision: "Ember@1.13.13",
                  loc: {
                    source: null,
                    start: {
                      line: 132,
                      column: 45
                    },
                    end: {
                      line: 132,
                      column: 111
                    }
                  },
                  moduleName: "client/templates/authenticated.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("br");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  return morphs;
                },
                statements: [["content", "article.barcode", ["loc", [null, [132, 88], [132, 107]]]]],
                locals: ["article"],
                templates: []
              };
            })();
            var child1 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    revision: "Ember@1.13.13",
                    loc: {
                      source: null,
                      start: {
                        line: 137,
                        column: 26
                      },
                      end: {
                        line: 139,
                        column: 26
                      }
                    },
                    moduleName: "client/templates/authenticated.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                            Parcel checked-in\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() {
                    return [];
                  },
                  statements: [],
                  locals: [],
                  templates: []
                };
              })();
              var child1 = (function () {
                return {
                  meta: {
                    revision: "Ember@1.13.13",
                    loc: {
                      source: null,
                      start: {
                        line: 139,
                        column: 26
                      },
                      end: {
                        line: 141,
                        column: 26
                      }
                    },
                    moduleName: "client/templates/authenticated.hbs"
                  },
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                            Parcel marked as not here\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() {
                    return [];
                  },
                  statements: [],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  revision: "Ember@1.13.13",
                  loc: {
                    source: null,
                    start: {
                      line: 136,
                      column: 24
                    },
                    end: {
                      line: 142,
                      column: 24
                    }
                  },
                  moduleName: "client/templates/authenticated.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["get", "consignment.accept", ["loc", [null, [137, 32], [137, 50]]]]], [], 0, 1, ["loc", [null, [137, 26], [141, 33]]]]],
                locals: [],
                templates: [child0, child1]
              };
            })();
            var child2 = (function () {
              return {
                meta: {
                  revision: "Ember@1.13.13",
                  loc: {
                    source: null,
                    start: {
                      line: 142,
                      column: 24
                    },
                    end: {
                      line: 145,
                      column: 24
                    }
                  },
                  moduleName: "client/templates/authenticated.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n                          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                  return morphs;
                },
                statements: [["inline", "md-btn", [], ["text", "Accept check in", "class", "blue", "action", "modalAcceptCheckIn", "actionArg", ["subexpr", "@mut", [["get", "consignment", ["loc", [null, [143, 109], [143, 120]]]]], [], []]], ["loc", [null, [143, 26], [143, 122]]]], ["inline", "md-btn", [], ["text", "Parcel is not here", "class", "red", "action", "modalRejectCheckIn", "actionArg", ["subexpr", "@mut", [["get", "consignment", ["loc", [null, [144, 111], [144, 122]]]]], [], []]], ["loc", [null, [144, 26], [144, 124]]]]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                revision: "Ember@1.13.13",
                loc: {
                  source: null,
                  start: {
                    line: 129,
                    column: 18
                  },
                  end: {
                    line: 148,
                    column: 18
                  }
                },
                moduleName: "client/templates/authenticated.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("tr");
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                dom.setAttribute(el2, "class", "tdWrdBreak");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                dom.setAttribute(el2, "class", "tdWrdBreak");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                dom.setAttribute(el2, "class", "tdWrdBreak");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                      ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("td");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("                      ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("                  \n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element6 = dom.childAt(fragment, [1]);
                var morphs = new Array(5);
                morphs[0] = dom.createMorphAt(dom.childAt(element6, [1]), 0, 0);
                morphs[1] = dom.createMorphAt(dom.childAt(element6, [3]), 0, 0);
                morphs[2] = dom.createMorphAt(dom.childAt(element6, [5]), 0, 0);
                morphs[3] = dom.createMorphAt(dom.childAt(element6, [7]), 0, 0);
                morphs[4] = dom.createMorphAt(dom.childAt(element6, [9]), 1, 1);
                return morphs;
              },
              statements: [["content", "consignment.consignmentNumber", ["loc", [null, [131, 45], [131, 78]]]], ["block", "each", [["get", "consignment.articles", ["loc", [null, [132, 53], [132, 73]]]]], [], 0, null, ["loc", [null, [132, 45], [132, 120]]]], ["inline", "courier-helper", [["get", "consignment.courierName", ["loc", [null, [133, 62], [133, 85]]]]], [], ["loc", [null, [133, 45], [133, 87]]]], ["content", "consignment.receiver.name", ["loc", [null, [134, 26], [134, 55]]]], ["block", "if", [["get", "consignment.marked", ["loc", [null, [136, 30], [136, 48]]]]], [], 1, 2, ["loc", [null, [136, 24], [145, 31]]]]],
              locals: [],
              templates: [child0, child1, child2]
            };
          })();
          return {
            meta: {
              revision: "Ember@1.13.13",
              loc: {
                source: null,
                start: {
                  line: 105,
                  column: 16
                },
                end: {
                  line: 149,
                  column: 16
                }
              },
              moduleName: "client/templates/authenticated.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["subexpr", "or", [["subexpr", "eq", [["get", "consignment.courier", ["loc", [null, [106, 32], [106, 51]]]], "TOLL_AU_1591745841"], [], ["loc", [null, [106, 28], [106, 73]]]], ["subexpr", "eq", [["get", "consignment.courier", ["loc", [null, [106, 78], [106, 97]]]], "TOLL_AU_1562909600"], [], ["loc", [null, [106, 74], [106, 119]]]]], [], ["loc", [null, [106, 24], [106, 120]]]]], [], 0, 1, ["loc", [null, [106, 18], [148, 25]]]]],
            locals: ["consignment"],
            templates: [child0, child1]
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              revision: "Ember@1.13.13",
              loc: {
                source: null,
                start: {
                  line: 153,
                  column: 14
                },
                end: {
                  line: 155,
                  column: 14
                }
              },
              moduleName: "client/templates/authenticated.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "md-btn", [], ["text", "Done", "class", "green", "action", "modalDoneCheckIns"], ["loc", [null, [154, 16], [154, 79]]]]],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              revision: "Ember@1.13.13",
              loc: {
                source: null,
                start: {
                  line: 155,
                  column: 14
                },
                end: {
                  line: 157,
                  column: 14
                }
              },
              moduleName: "client/templates/authenticated.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "md-btn", [], ["text", "Done", "class", "disabled"], ["loc", [null, [156, 16], [156, 55]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            revision: "Ember@1.13.13",
            loc: {
              source: null,
              start: {
                line: 89,
                column: 4
              },
              end: {
                line: 162,
                column: 4
              }
            },
            moduleName: "client/templates/authenticated.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "check-in-modal");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "modal-content");
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("h4");
            var el4 = dom.createTextNode("Please confirm that the below parcels are in your store");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("form");
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("table");
            dom.setAttribute(el4, "class", "ui table");
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("thead");
            dom.setAttribute(el5, "class", "full-width");
            var el6 = dom.createTextNode("\n                ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("tr");
            var el7 = dom.createTextNode("\n                  ");
            dom.appendChild(el6, el7);
            var el7 = dom.createElement("th");
            var el8 = dom.createTextNode("Consignment No#");
            dom.appendChild(el7, el8);
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n                  ");
            dom.appendChild(el6, el7);
            var el7 = dom.createElement("th");
            var el8 = dom.createTextNode("Article Barcodes");
            dom.appendChild(el7, el8);
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n                  ");
            dom.appendChild(el6, el7);
            var el7 = dom.createElement("th");
            var el8 = dom.createTextNode("Courier");
            dom.appendChild(el7, el8);
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n                  ");
            dom.appendChild(el6, el7);
            var el7 = dom.createElement("th");
            var el8 = dom.createTextNode("Customer");
            dom.appendChild(el7, el8);
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n                  ");
            dom.appendChild(el6, el7);
            var el7 = dom.createElement("th");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n                ");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n              ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("tbody");
            var el6 = dom.createTextNode("\n");
            dom.appendChild(el5, el6);
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("              ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "center");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("            ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element8 = dom.childAt(fragment, [1, 1, 3]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(element8, [1, 3]), 1, 1);
            morphs[1] = dom.createMorphAt(dom.childAt(element8, [3]), 1, 1);
            return morphs;
          },
          statements: [["block", "each", [["get", "checkInConsignments", ["loc", [null, [105, 24], [105, 43]]]]], [], 0, null, ["loc", [null, [105, 16], [149, 25]]]], ["block", "if", [["get", "checkInModalButtonEnabled", ["loc", [null, [153, 20], [153, 45]]]]], [], 1, 2, ["loc", [null, [153, 14], [157, 21]]]]],
          locals: [],
          templates: [child0, child1, child2]
        };
      })();
      return {
        meta: {
          revision: "Ember@1.13.13",
          loc: {
            source: null,
            start: {
              line: 88,
              column: 2
            },
            end: {
              line: 163,
              column: 2
            }
          },
          moduleName: "client/templates/authenticated.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "md-modal", [], ["class", "check-in-modal"], 0, null, ["loc", [null, [89, 4], [162, 17]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child5 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  revision: "Ember@1.13.13",
                  loc: {
                    source: null,
                    start: {
                      line: 194,
                      column: 24
                    },
                    end: {
                      line: 196,
                      column: 24
                    }
                  },
                  moduleName: "client/templates/authenticated.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                          Parcel marked as discarded\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() {
                  return [];
                },
                statements: [],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              return {
                meta: {
                  revision: "Ember@1.13.13",
                  loc: {
                    source: null,
                    start: {
                      line: 196,
                      column: 24
                    },
                    end: {
                      line: 198,
                      column: 24
                    }
                  },
                  moduleName: "client/templates/authenticated.hbs"
                },
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                          Parcel marked as not discarded\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() {
                  return [];
                },
                statements: [],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                revision: "Ember@1.13.13",
                loc: {
                  source: null,
                  start: {
                    line: 193,
                    column: 21
                  },
                  end: {
                    line: 199,
                    column: 22
                  }
                },
                moduleName: "client/templates/authenticated.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["get", "consignment.accept", ["loc", [null, [194, 30], [194, 48]]]]], [], 0, 1, ["loc", [null, [194, 24], [198, 31]]]]],
              locals: [],
              templates: [child0, child1]
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                revision: "Ember@1.13.13",
                loc: {
                  source: null,
                  start: {
                    line: 199,
                    column: 22
                  },
                  end: {
                    line: 202,
                    column: 22
                  }
                },
                moduleName: "client/templates/authenticated.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                return morphs;
              },
              statements: [["inline", "md-btn", [], ["text", "Confirm", "class", "blue no-float", "action", "onDiscardWishbutton", "actionArg", ["subexpr", "@mut", [["get", "consignment", ["loc", [null, [200, 109], [200, 120]]]]], [], []]], ["loc", [null, [200, 24], [200, 122]]]], ["inline", "md-btn", [], ["text", "Cancel", "class", "red no-float", "action", "onCancelWish", "actionArg", ["subexpr", "@mut", [["get", "consignment", ["loc", [null, [201, 100], [201, 111]]]]], [], []]], ["loc", [null, [201, 24], [201, 113]]]]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              revision: "Ember@1.13.13",
              loc: {
                source: null,
                start: {
                  line: 188,
                  column: 15
                },
                end: {
                  line: 205,
                  column: 16
                }
              },
              moduleName: "client/templates/authenticated.hbs"
            },
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                 ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("tr");
              dom.setAttribute(el1, "class", "wish-popup");
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "class", "tdWrdBreak");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "class", "tdWrdBreak");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                    ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                    ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" \n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(dom.childAt(element2, [1]), 0, 0);
              morphs[1] = dom.createMorphAt(dom.childAt(element2, [3]), 0, 0);
              morphs[2] = dom.createMorphAt(dom.childAt(element2, [5]), 1, 1);
              return morphs;
            },
            statements: [["content", "consignment.consignmentNumber", ["loc", [null, [190, 43], [190, 76]]]], ["content", "consignment.cncData.hubbedId", ["loc", [null, [191, 43], [191, 75]]]], ["block", "if", [["get", "consignment.marked", ["loc", [null, [193, 27], [193, 45]]]]], [], 0, 1, ["loc", [null, [193, 21], [202, 29]]]]],
            locals: ["consignment"],
            templates: [child0, child1]
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              revision: "Ember@1.13.13",
              loc: {
                source: null,
                start: {
                  line: 210,
                  column: 14
                },
                end: {
                  line: 212,
                  column: 14
                }
              },
              moduleName: "client/templates/authenticated.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "md-btn", [], ["text", "Done", "class", "green no-float", "action", "modalDoneCheckInsWish"], ["loc", [null, [211, 16], [211, 92]]]]],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              revision: "Ember@1.13.13",
              loc: {
                source: null,
                start: {
                  line: 212,
                  column: 14
                },
                end: {
                  line: 214,
                  column: 14
                }
              },
              moduleName: "client/templates/authenticated.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "md-btn", [], ["text", "Done", "class", "disabled no-float"], ["loc", [null, [213, 16], [213, 64]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            revision: "Ember@1.13.13",
            loc: {
              source: null,
              start: {
                line: 168,
                column: 4
              },
              end: {
                line: 220,
                column: 4
              }
            },
            moduleName: "client/templates/authenticated.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "modaltopright");
            var el2 = dom.createElement("a");
            var el3 = dom.createElement("i");
            dom.setAttribute(el3, "class", "mdi-navigation-close");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "check-in-modal wish-modal");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "modal-header");
            dom.setAttribute(el2, "style", "margin-bottom: 0px;");
            var el3 = dom.createTextNode(" \n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("center");
            var el4 = dom.createElement("h2");
            dom.setAttribute(el4, "class", "red-textt");
            dom.setAttribute(el4, "style", "font-size:2.5em; margin:0px;");
            var el5 = dom.createElement("b");
            var el6 = dom.createTextNode("Daily Aged Parcel to be Discarded");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "modal-content-new");
            dom.setAttribute(el2, "style", "padding:0px 0px 12px 24px");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("form");
            dom.setAttribute(el3, "style", "padding-top:0;");
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "abcd");
            dom.setAttribute(el4, "style", "overflow-y: auto;height:278px;");
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("table");
            dom.setAttribute(el5, "class", "ui table");
            var el6 = dom.createTextNode("\n              ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("thead");
            dom.setAttribute(el6, "class", "full-width");
            var el7 = dom.createTextNode("\n                ");
            dom.appendChild(el6, el7);
            var el7 = dom.createElement("tr");
            var el8 = dom.createTextNode("\n                  ");
            dom.appendChild(el7, el8);
            var el8 = dom.createElement("th");
            var el9 = dom.createTextNode("Consignment");
            dom.appendChild(el8, el9);
            dom.appendChild(el7, el8);
            var el8 = dom.createTextNode("\n                   ");
            dom.appendChild(el7, el8);
            var el8 = dom.createElement("th");
            var el9 = dom.createTextNode("Hubbed ID");
            dom.appendChild(el8, el9);
            dom.appendChild(el7, el8);
            var el8 = dom.createTextNode("\n                  ");
            dom.appendChild(el7, el8);
            var el8 = dom.createElement("th");
            var el9 = dom.createTextNode("Discard");
            dom.appendChild(el8, el9);
            dom.appendChild(el7, el8);
            var el8 = dom.createTextNode("\n                  ");
            dom.appendChild(el7, el8);
            var el8 = dom.createElement("th");
            dom.appendChild(el7, el8);
            var el8 = dom.createTextNode("\n                ");
            dom.appendChild(el7, el8);
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("\n              ");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n              ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("tbody");
            dom.setAttribute(el6, "style", "overflow: auto;");
            var el7 = dom.createTextNode("\n");
            dom.appendChild(el6, el7);
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("              ");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n            ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "center");
            dom.setAttribute(el4, "style", "margin-top:10px;");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("              ");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [1, 0]);
            var element4 = dom.childAt(fragment, [3, 3, 2]);
            var element5 = dom.childAt(element4, [3]);
            var morphs = new Array(4);
            morphs[0] = dom.createElementMorph(element3);
            morphs[1] = dom.createMorphAt(dom.childAt(element4, [1, 1, 3]), 1, 1);
            morphs[2] = dom.createMorphAt(element5, 1, 1);
            morphs[3] = dom.createMorphAt(element5, 3, 3);
            return morphs;
          },
          statements: [["element", "action", ["onCloseWishPopUp"], [], ["loc", [null, [169, 34], [169, 63]]]], ["block", "each", [["get", "checkInConsignmentsWish", ["loc", [null, [188, 23], [188, 46]]]]], [], 0, null, ["loc", [null, [188, 15], [205, 25]]]], ["block", "if", [["get", "checkInModalButtonEnabledWish", ["loc", [null, [210, 20], [210, 49]]]]], [], 1, 2, ["loc", [null, [210, 14], [214, 21]]]], ["inline", "md-btn", [], ["text", "Close", "class", "red no-float", "action", "onCloseWishPopUp"], ["loc", [null, [215, 14], [215, 84]]]]],
          locals: [],
          templates: [child0, child1, child2]
        };
      })();
      return {
        meta: {
          revision: "Ember@1.13.13",
          loc: {
            source: null,
            start: {
              line: 167,
              column: 2
            },
            end: {
              line: 221,
              column: 2
            }
          },
          moduleName: "client/templates/authenticated.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "md-modal", [], ["class", "check-in-modal", "style", "height:100%;"], 0, null, ["loc", [null, [168, 4], [220, 17]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child6 = (function () {
      var child0 = (function () {
        return {
          meta: {
            revision: "Ember@1.13.13",
            loc: {
              source: null,
              start: {
                line: 226,
                column: 2
              },
              end: {
                line: 235,
                column: 2
              }
            },
            moduleName: "client/templates/authenticated.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "check-in-modal");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "modal-content");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("img");
            dom.setAttribute(el3, "alt", "Loading...");
            dom.setAttribute(el3, "class", "imageSet");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "wrapperImagebtn");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var element1 = dom.childAt(element0, [1, 1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element1, "src");
            morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 1, 1);
            return morphs;
          },
          statements: [["attribute", "src", ["get", "checkInImage", ["loc", [null, [229, 19], [229, 31]]]]], ["inline", "md-btn", [], ["text", "Acknowledge", "class", "green", "action", "SaveAcknowledgement"], ["loc", [null, [232, 8], [232, 80]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          revision: "Ember@1.13.13",
          loc: {
            source: null,
            start: {
              line: 225,
              column: 0
            },
            end: {
              line: 236,
              column: 0
            }
          },
          moduleName: "client/templates/authenticated.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "md-modal", [], ["class", "check-in-modal"], 0, null, ["loc", [null, [226, 2], [235, 15]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        revision: "Ember@1.13.13",
        loc: {
          source: null,
          start: {
            line: 1,
            column: 0
          },
          end: {
            line: 240,
            column: 0
          }
        },
        moduleName: "client/templates/authenticated.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "main-page row ");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "id", "sidebar");
        dom.setAttribute(el2, "class", "col s2 no-padding");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "id", "current-view");
        dom.setAttribute(el2, "class", "col s10 no-padding");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element15 = dom.childAt(fragment, [0]);
        var morphs = new Array(13);
        morphs[0] = dom.createMorphAt(dom.childAt(element15, [1]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element15, [3]), 1, 1);
        morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[3] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        morphs[4] = dom.createMorphAt(fragment, 6, 6, contextualElement);
        morphs[5] = dom.createMorphAt(fragment, 8, 8, contextualElement);
        morphs[6] = dom.createMorphAt(fragment, 10, 10, contextualElement);
        morphs[7] = dom.createMorphAt(fragment, 12, 12, contextualElement);
        morphs[8] = dom.createMorphAt(fragment, 14, 14, contextualElement);
        morphs[9] = dom.createMorphAt(fragment, 16, 16, contextualElement);
        morphs[10] = dom.createMorphAt(fragment, 18, 18, contextualElement);
        morphs[11] = dom.createMorphAt(fragment, 20, 20, contextualElement);
        morphs[12] = dom.createMorphAt(fragment, 22, 22, contextualElement);
        return morphs;
      },
      statements: [["inline", "partial", ["partials/nav"], [], ["loc", [null, [3, 4], [3, 30]]]], ["content", "outlet", ["loc", [null, [6, 4], [6, 14]]]], ["content", "md-modal-container", ["loc", [null, [10, 0], [10, 22]]]], ["content", "initial-load-remover", ["loc", [null, [11, 0], [11, 24]]]], ["block", "if", [["get", "loading", ["loc", [null, [13, 8], [13, 15]]]]], [], 0, null, ["loc", [null, [13, 2], [35, 9]]]], ["block", "if", [["get", "dailyCodeModal", ["loc", [null, [38, 8], [38, 22]]]]], [], 1, null, ["loc", [null, [38, 2], [50, 9]]]], ["block", "if", [["get", "signOutModal", ["loc", [null, [53, 8], [53, 20]]]]], [], 2, null, ["loc", [null, [53, 2], [66, 9]]]], ["block", "if", [["get", "quickAuth", ["loc", [null, [68, 8], [68, 17]]]]], [], 3, null, ["loc", [null, [68, 2], [86, 9]]]], ["block", "if", [["get", "checkInModal", ["loc", [null, [88, 8], [88, 20]]]]], [], 4, null, ["loc", [null, [88, 2], [163, 9]]]], ["block", "if", [["get", "showHourlyHandoverPopup", ["loc", [null, [167, 8], [167, 31]]]]], [], 5, null, ["loc", [null, [167, 2], [221, 9]]]], ["block", "if", [["get", "imageUpload", ["loc", [null, [225, 6], [225, 17]]]]], [], 6, null, ["loc", [null, [225, 0], [236, 7]]]], ["inline", "ads-trigger", [], ["currentPath", ["subexpr", "@mut", [["get", "currentPath", ["loc", [null, [238, 26], [238, 37]]]]], [], []], "stopAdsAction", ["subexpr", "action", ["stopAds"], [], ["loc", [null, [238, 52], [238, 70]]]], "startAdsAction", ["subexpr", "action", ["startAds"], [], ["loc", [null, [238, 86], [238, 105]]]]], ["loc", [null, [238, 0], [238, 107]]]], ["content", "version-checker", ["loc", [null, [239, 0], [239, 19]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6]
    };
  })());
});