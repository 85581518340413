define("client/helpers/color-helper", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.colorHelper = colorHelper;
	function colorHelper(params) {
		//	console.log(params);
		var currentState = params[0];
		var isExpired = params[1];
		switch (currentState) {
			case "notYetCheckedIn":
				return "none";
			case "awaitingCustomerPickup":
				return "row-alert";
			case "awaitingCourierPickup":
				return "row-warning";
			default:
				return "none";
		}
	}

	exports["default"] = Ember.Helper.helper(colorHelper);
});