define("client/controllers/consignments/handover", ["exports", "ic-ajax", "client/helpers/product-helper"], function (exports, _icAjax, _productHelper) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports["default"] = Ember.Controller.extend({
    config: Ember.inject.service(),
    session: Ember.inject.service(),

    consignments: [],
    consignmentHandover: [],
    handoverContetn: {},
    indexValue: "",
    consignmentsLoading: true,
    duplicateEbayParcel: false,
    queryParams: ["page", "perPage"],
    page: 1,
    perPage: 6,
    totalPages: 1,
    custumflag: false,
    checkoutButton: false,
    cardNumber: "",
    logErrorConsignmentDetails: null,
    showErrorForInfo: false,
    consignmentNo: "",
    SucessconsignmentNo: false,
    filterName: "",
    filterShow: false,
    handoverModal: true,
    ConfirmationModal: false,
    MultiPieceModal: false,
    MultiPieceConsignment: [],
    deletedConsignment: "",

    /* for delay seaching while typing */
    events: Ember.A(),

    inputTextObservor: (function () {
      Ember.run.debounce(this, this.searchText, 1000);
    }).observes("filterName"),

    searchText: function searchText() {
      //console.info(this.get('filterName')); 
      this.send("getPage", 1);
    },
    /* for delay seaching while typing ENDS */

    autoReload: (function () {
      var self = this;
      Ember.run.later(self, function () {
        var url = self.get("config.apiURL") + "v2/consumer/drops/search/handover?page=" + this.get("page") + "&perPage=" + this.get("perPage") + "&limited=true&orderBy=time_waiting&orderDir=DESC";
        if (this.get("filterName")) {
          url += "&filter=" + encodeURIComponent(this.get("filterName"));
          //url += "&filter=" + this.get('filterName');
        }
        //url+="&orderBy=created&orderDir=DESC";
        (0, _icAjax["default"])({
          url: url,
          type: "GET",
          headers: {
            Authorization: "Bearer " + self.get("session.data").authenticated.jwt
          },
          crossDomain: true
        }).then(function (results) {
          self.set("consignments", results.data);
          self.set("consignmentsLoading", false);
          self.set("page", results.page);
          self.set("totalPages", results.totalPages);
          self.autoReload();
        })["catch"](function (error) {

          if (error && error.jqXHR && error.jqXHR.status === 401) {
            self.get("session").invalidate();
          }

          self.set("consignments", []);
          self.set("consignmentsLoading", false);
        });
      }, self.get("config.autoRefreshSpeed"));
    }).on("init"),

    couriers: [],

    checkoutWorkflow: null,
    checkoutCommon: {
      receiver: "",
      idType: "",
      idLast4: "",
      signature: "",
      pin: ""
    },
    manualCheckout: {
      reason: "",
      consignment: null
    },

    actions: {
      refreshAuthToken: function refreshAuthToken(methodToCall) {
        var self = this;
        (0, _icAjax["default"])({
          url: self.get("config.apiURL") + "v2/admin/auth/validateToken",
          type: "GET",
          headers: { Authorization: "Bearer " + self.get("session.data").authenticated.jwt },
          contentType: "application/json",
          crossDomain: true
        }).then(function (response) {
          var responseData = JSON.parse(response);
          self.get("session").set("data.authenticated.dotnet_auth", responseData.responseMessage);
          self.send(methodToCall);
        }, function (error) {
          self.controllerFor("application").send("showError", "Error while submitting your request");
          self.controllerFor("application").send("hideSpinner", 850);
          self.set("logErrorConsignmentDetails", null);
          self.set("showErrorForInfo", false);
          self.set("showModal", null);
        });
      },

      showFilter: function showFilter() {
        this.set("filterShow", !this.get("filterShow"));

        if (this.get("filterName")) {
          this.set("filterName", "");
          this.send("getPage", 1);
        } else {
          this.set("searchedEcpCodeArr", []);
          this.send("getPage", 1);
        }
      },

      filter: function filter() {
        this.send("getPage", 1);
      },

      HandoverToCourier: function HandoverToCourier() {
        var self = this;
        var dataa = self.get("consignmentHandover");
        var publicArtId = [];
        var externalArtId = [];
        var numberOfDataCount = dataa.length;
        if (numberOfDataCount > 0) {
          dataa.forEach(function (element) {
            if (element.publicArticleId != null) {
              publicArtId.push(element.publicArticleId);
            }
            if (element.externalArticleId != null) {
              externalArtId.push(element.externalArticleId);
            }
          });
          var consignmentHandoverData = {
            externalArticleIds: externalArtId,
            publicArticleIds: publicArtId
          };
          self.controllerFor("application").send("showSpinner");
          (0, _icAjax["default"])({
            url: self.get("config.apiURL") + "v2/consumer/drops/handovers",
            type: "Post",
            headers: {
              Authorization: "Bearer " + self.get("session.data").authenticated.jwt
            },
            data: JSON.stringify(consignmentHandoverData),
            contentType: "application/json",
            crossDomain: true
          }).then(function (result) {
            self.controllerFor("application").send("showSuccessInfo", +numberOfDataCount + " Handover Successfully!");
            self.set("handoverModal", false);
            self.set("handoverbarcodesearch", "");
            self.set("handoversearch", "");
            self.set("consignmentHandover", "");
            self.controllerFor("application").send("hideSpinner", 3000);
            self.send("getPage", 1);
          }, function () /*error*/{
            self.controllerFor("application").send("showError");
            self.controllerFor("application").send("hideSpinn;er", 3000);
          });
        } else {
          self.controllerFor("application").send("showError");
        }
      },

      filterHandover: function filterHandover() {
        this.send("getPageHandover", 1);
      },

      filterHandoverOnData: function filterHandoverOnData() {
        this.send("getPageHandoveronData", 1);
        // To Be Work Here  getPageHandoveronData
      },

      closeHandoverModal: function closeHandoverModal() {
        this.set("handoverModal", false);
        this.set("handoverbarcodesearch", "");
        this.set("handoversearch", "");
        this.set("consignmentHandover", "");
      },

      closeConsgnPopup: function closeConsgnPopup() {
        this.set("MultiPieceModal", false);
      },

      OnSelectOfMultipieceCons: function OnSelectOfMultipieceCons(multipiece, indexValue) {
        var self = this;
        var toCheckExists = [].concat(_toConsumableArray(self.get("consignmentHandover"))).filter(function (item) {
          return item.barcode === multipiece.barcode;
        });
        if (toCheckExists.length == 0) {
          var toCheck = [].concat(_toConsumableArray(self.get("consignmentHandover"))).every(function (item) {
            return item.courier === multipiece.courier;
          });
          if (toCheck == true) {
            // var toChecktst = [...self.get('MultiPieceConsignment')].find(item => item.barcode === multipiece.barcode);
            self.set("consignmentHandover", [].concat(_toConsumableArray(self.get("consignmentHandover")), [multipiece]));
            self.set("handoverbarcodesearch", "");
            self.set("handoversearch", "");
            self.set("MultiPieceModal", false);
            // self.controllerFor('application').send('hideSpinner');
          }
        } else {
          self.controllerFor("application").send("showSpinner");
          self.controllerFor("application").send("showError", "Scanned item already in a list.");
          self.set("handoverbarcodesearch", "");
          self.set("handoversearch", "");
          this.set("MultiPieceModal", false);
          self.controllerFor("application").send("hideSpinner", 1000);
        }
      },

      OpenPopup: function OpenPopup() {
        this.set("handoverModal", true);
      },

      getPageHandover: function getPageHandover(page) {
        var _this = this;

        var self = this;
        //var toCheckExists = [...self.get('consignmentHandover')].filter(item => item.consignmentNumber === this.get('handoverbarcodesearch')  || item.barcode === this.get('handoverbarcodesearch'));
        var toCheckExists = [].concat(_toConsumableArray(self.get("consignmentHandover"))).filter(function (item) {
          return item.barcode === _this.get("handoverbarcodesearch") || item.consignmentNumber === _this.get("handoverbarcodesearch");
        });
        if (toCheckExists.length == 0) {
          var consignmentData = {
            number: this.get("handoverbarcodesearch")
          };
          self.controllerFor("application").send("showSpinner");
          (0, _icAjax["default"])({
            url: self.get("config.apiURL") + "v2/consumer/drops/handover/options",
            type: "Post",
            headers: {
              Authorization: "Bearer " + self.get("session.data").authenticated.jwt
            },
            data: JSON.stringify(consignmentData),
            contentType: "application/json",
            crossDomain: true
          }).then(function (result) {
            if (result.handOverOptions.length == 0 && result.success == false) {
              // self.controllerFor('application').send('showError', 'Consignment already scanned / not found.');
              self.controllerFor("application").send("showError", result.status);
              self.set("handoverbarcodesearch", "");
              self.set("handoversearch", "");
              self.controllerFor("application").send("hideSpinner", 3000);
            } else if (result.handOverOptions.length > 1) {
              self.controllerFor("application").send("hideSpinner", 500);
              self.set("MultiPieceModal", true);
              //self.set('MultiPieceConsignment', [...self.get('consignmentHandover'), ...result.handOverOptions]);  //SENDLE_1572398415
              self.set("MultiPieceConsignment", result.handOverOptions);
            } else {
              var testchecek = result.handOverOptions;
              var newtesr = testchecek[0].consignmentNumber.substring(0, 2);
              //if(newtesr.toLowerCase() == "cp" && (result.handOverOptions[0].courierCode.toLowerCase() == "sendle_1589274351" || result.handOverOptions[0].courierCode.toLowerCase() == "cpl")){       //for Dev
              if (newtesr.toLowerCase() == "cp" && (result.handOverOptions[0].courierCode.toLowerCase() == "sendle_1572398415" || result.handOverOptions[0].courierCode.toLowerCase() == "cpl")) {
                var ab = [].concat(_toConsumableArray(self.get("consignmentHandover")));
                if (ab.length > 0) {
                  // if(ab[0].courierCode.toLowerCase() == "sendle_1589274351" || ab[0].courierCode.toLowerCase() == "cpl"){  // for dev
                  if (ab[0].courierCode.toLowerCase() == "sendle_1572398415" || ab[0].courierCode.toLowerCase() == "cpl") {
                    // for dev
                    var toCheck = [].concat(_toConsumableArray(self.get("consignmentHandover"))).every(function (item) {
                      return item.consignmentNumber.toLowerCase().substring(0, 2) === result.handOverOptions[0].consignmentNumber.toLowerCase().substring(0, 2);
                    });
                  } else {
                    var toCheck = false;
                  }
                } else {
                  var toCheck = [].concat(_toConsumableArray(self.get("consignmentHandover"))).every(function (item) {
                    return item.courier === result.handOverOptions[0].courier;
                  });
                }
              } else {
                var toCheck = [].concat(_toConsumableArray(self.get("consignmentHandover"))).every(function (item) {
                  return item.courier === result.handOverOptions[0].courier;
                });
              }

              if (toCheck == true) {
                self.set("consignmentHandover", [].concat(_toConsumableArray(self.get("consignmentHandover")), _toConsumableArray(result.handOverOptions)));
                //self.set('consignmentHandover', result.handOverOptions);
                // console.log('From Getting', self.get('consignmentHandover'));
                self.set("handoverbarcodesearch", "");
                self.set("handoversearch", "");
                self.controllerFor("application").send("hideSpinner");
              } else {
                self.controllerFor("application").send("showError", "Scanned item belongs to a different courier.");
                self.set("handoverbarcodesearch", "");
                self.set("handoversearch", "");
                self.controllerFor("application").send("hideSpinner", 3000);
              }
            }
            //self.set('consignmentHandover', [...self.get('consignmentHandover'), ...result.handOverOptions]);
            // self.controllerFor('application').send('hideSpinner');
          }, function (error) {

            if (error && error.jqXHR && error.jqXHR.status === 401) {
              self.get("session").invalidate();
            }

            self.controllerFor("application").send("showError");
            self.set("handoverbarcodesearch", "");
            self.set("handoversearch", "");
            self.controllerFor("application").send("hideSpinner", 3000);
          });
        } else {
          self.controllerFor("application").send("showSpinner");
          self.controllerFor("application").send("showError", "Scanned item already in a list.");
          self.set("handoverbarcodesearch", "");
          self.set("handoversearch", "");
          self.controllerFor("application").send("hideSpinner", 2000);
        }
      },

      getPageHandoveronData: function getPageHandoveronData(page) {
        var _this2 = this;

        var self = this;
        // var toCheckExists = [...self.get('consignmentHandover')].filter(item => item.consignmentNumber === this.get('handoversearch')  || item.barcode === this.get('handoversearch'));
        // var toCheckExists = [...self.get('consignmentHandover')].filter(item => item.barcode === this.get('handoversearch')); // Runing Code
        var toCheckExists = [].concat(_toConsumableArray(self.get("consignmentHandover"))).filter(function (item) {
          return item.barcode === _this2.get("handoversearch") || item.consignmentNumber === _this2.get("handoversearch");
        });
        if (toCheckExists.length == 0) {
          var consignmentData = {
            number: this.get("handoversearch")
          };
          self.controllerFor("application").send("showSpinner");
          (0, _icAjax["default"])({
            url: self.get("config.apiURL") + "v2/consumer/drops/handover/options",
            type: "Post",
            headers: {
              Authorization: "Bearer " + self.get("session.data").authenticated.jwt
            },
            data: JSON.stringify(consignmentData),
            contentType: "application/json",
            crossDomain: true
          }).then(function (result) {
            if (result.handOverOptions.length == 0 && result.success == false) {
              // self.controllerFor('application').send('showError', 'Consignment already scanned / not found.');
              self.controllerFor("application").send("showError", result.status);
              self.set("handoverbarcodesearch", "");
              self.set("handoversearch", "");
              self.controllerFor("application").send("hideSpinner", 3000);
            } else if (result.handOverOptions.length > 1) {
              self.controllerFor("application").send("hideSpinner", 500);
              self.set("MultiPieceModal", true);
              //self.set('MultiPieceConsignment', [...self.get('consignmentHandover'), ...result.handOverOptions]);
              self.set("MultiPieceConsignment", result.handOverOptions);
            } else {
              //This Check Is For Other Couriers Not be Present   
              var testchecek = result.handOverOptions;
              var newtesr = testchecek[0].consignmentNumber.substring(0, 2);
              // if(newtesr.toLowerCase() == "cp" && (result.handOverOptions[0].courierCode.toLowerCase() == "sendle_1589274351" || result.handOverOptions[0].courierCode.toLowerCase() == "cpl")){  // for dev
              if (newtesr.toLowerCase() == "cp" && (result.handOverOptions[0].courierCode.toLowerCase() == "sendle_1572398415" || result.handOverOptions[0].courierCode.toLowerCase() == "cpl")) {
                var ab = [].concat(_toConsumableArray(self.get("consignmentHandover")));
                if (ab.length > 0) {
                  // if(ab[0].courierCode.toLowerCase() == "sendle_1589274351" || ab[0].courierCode.toLowerCase() == "cpl"){ // for Dev
                  if (ab[0].courierCode.toLowerCase() == "sendle_1572398415" || ab[0].courierCode.toLowerCase() == "cpl") {
                    var toCheck = [].concat(_toConsumableArray(self.get("consignmentHandover"))).every(function (item) {
                      return item.consignmentNumber.toLowerCase().substring(0, 2) === result.handOverOptions[0].consignmentNumber.toLowerCase().substring(0, 2);
                    });
                  } else {
                    var toCheck = false;
                  }
                } else {
                  var toCheck = [].concat(_toConsumableArray(self.get("consignmentHandover"))).every(function (item) {
                    return item.courier === result.handOverOptions[0].courier;
                  });
                }
              } else {
                var toCheck = [].concat(_toConsumableArray(self.get("consignmentHandover"))).every(function (item) {
                  return item.courier === result.handOverOptions[0].courier;
                });
              }

              //  var toCheck = [...self.get('consignmentHandover')].every(item => item.courier === result.handOverOptions[0].courier);
              if (toCheck == true) {
                self.set("consignmentHandover", [].concat(_toConsumableArray(self.get("consignmentHandover")), _toConsumableArray(result.handOverOptions)));
                //self.set('consignmentHandover', result.handOverOptions);
                // console.log('From Getting', self.get('consignmentHandover')); 
                self.set("handoverbarcodesearch", "");
                self.set("handoversearch", "");
                self.controllerFor("application").send("hideSpinner");
              } else {
                self.controllerFor("application").send("showError", "Scanned item belongs to a different courier.");
                self.set("handoverbarcodesearch", "");
                self.set("handoversearch", "");
                self.controllerFor("application").send("hideSpinner", 3000);
              }
            }
            // self.set('consignmentHandover', [...self.get('consignmentHandover'), ...result.handOverOptions]);
            // self.controllerFor('application').send('hideSpinner');
          }, function () /*error*/{
            self.controllerFor("application").send("showError");
            self.set("handoverbarcodesearch", "");
            self.set("handoversearch", "");
            self.controllerFor("application").send("hideSpinner", 3000);
          })["catch"](function (error) {
            if (error && error.jqXHR && error.jqXHR.status === 401) {
              self.get("session").invalidate();
            }
          });
        } else {
          self.controllerFor("application").send("showSpinner");
          self.controllerFor("application").send("showError", "Scanned item already in a list.");
          self.set("handoverbarcodesearch", "");
          self.set("handoversearch", "");
          self.controllerFor("application").send("hideSpinner", 2000);
        }
      },

      getPage: function getPage(page) {
        var self = this;
        self.set("consignmentsLoading", true);
        var url = self.get("config.apiURL") + "v2/consumer/drops/search/handover?page=" + page + "&perPage=" + this.get("perPage") + "&limited=true&orderBy=time_waiting&orderDir=DESC";
        if (this.get("filterName")) {
          url += "&filter=" + encodeURIComponent(this.get("filterName"));
          //url += "&filter=" + this.get('filterName');
        } else if (self.get("searchedEcpCodeArr") && self.get("searchedEcpCodeArr").length > 0) {
          url += "&ecpCodes=" + encodeURIComponent(self.get("searchedEcpCodeArr").toString());
        }
        (0, _icAjax["default"])({
          url: url,
          type: "get",
          headers: {
            Authorization: "Bearer " + self.get("session.data").authenticated.jwt
          },
          crossDomain: true
        }).then(function (result) {
          if (result && result.data) {
            for (var i = 0; i < result.data.length; i++) {
              if (self.get("filterName") && result.data[i].ebayData && result.data[i].ebayData.ecpNumber) {
                self.set("duplicateEbayParcel", true);
                self.set("duplicateEbayParcelDetails", { firstname: result.data[i].ebayData.buyerFirstName, lastname: result.data[i].ebayData.buyerLastName, ecpCode: result.data[i].ebayData.ecpNumber });
                var articles = result.data[i].articles;
                var states = articles.map(function (a) {
                  return a.currentState.state;
                });
                if (states.indexOf("notYetCheckedIn") > -1) {
                  self.set("duplicateEbayParcel", false);
                  break;
                }
              }
            }
          }
          self.set("consignmentsLoading", false);
          self.set("consignments", result.data);
          self.set("page", result.page);
          self.set("totalPages", result.totalPages);
        })["catch"](function (error) {

          if (error && error.jqXHR && error.jqXHR.status === 401) {
            self.get("session").invalidate();
          }

          self.set("consignments", []);
          self.set("consignmentsLoading", false);
        });
      },

      //Manual override
      onManualCheckout: function onManualCheckout(consignment, indexValue) {
        var self = this;
        var consignmentData = {
          number: consignment.consignmentNumber,
          externalArticleId: null,
          publicArticleId: null
        };
        self.controllerFor("application").send("showSpinner");
        (0, _icAjax["default"])({
          url: self.get("config.apiURL") + "v2/consumer/drops/handover",
          type: "Post",
          headers: {
            Authorization: "Bearer " + self.get("session.data").authenticated.jwt
          },
          data: JSON.stringify(consignmentData),
          contentType: "application/json",
          crossDomain: true
        }).then(function (result) {
          // console.log('consignment in the controller checkout page', result)
          self.set("handoverContetn", result);
          self.set("harndoverContetn", result.number);
          self.set("SucessconsignmentNo", true);
          self.send("reload");
          //  self.set('checkoutButton', true);
          self.send("getPage", 1);
          self.set("manualCheckout", {
            consignment: consignment
          });
          self.controllerFor("application").send("hideSpinner");
        }, function () /*error*/{
          self.controllerFor("application").send("showError");
          self.controllerFor("application").send("hideSpinner", 3000);
        });
      },

      OnRemoveHandover: function OnRemoveHandover(consignment, indexValue) {
        var self = this;
        self.set("ConfirmationModal", true);
        // self.set("deletedConsignment",consignment.consignmentNumber);
        self.set("deletedConsignment", consignment.barcode);
      },

      OnConfirmRemoveHandover: function OnConfirmRemoveHandover(consignment, indexValue) {
        var self = this;
        var consignmentnumber = self.get("deletedConsignment");
        var dataa = self.get("consignmentHandover");
        // var newdata = dataa.filter(a=>a.consignmentNumber != consignmentnumber);
        var newdata = dataa.filter(function (a) {
          return a.barcode != consignmentnumber;
        });
        self.set("consignmentHandover", newdata);
        //  console.log("To Be Show On UI ",self.get('consignmentHandover')); 
        this.set("ConfirmationModal", false);
      },

      OnNoConfirmRemoveHandover: function OnNoConfirmRemoveHandover() {
        this.set("ConfirmationModal", false);
      },

      reload: function reload() {
        var self = this;
        this.set("cardNumber", "");
        var urlStr = self.get("config.apiURL") + "v2/consumer/drops/search/handover?page=" + this.get("perPage") + "&limited=true&orderBy=time_waiting&orderDir=DESC";
        if (this.get("filterName")) {
          urlStr += "&filter=" + encodeURIComponent(this.get("filterName"));
        }
        (0, _icAjax["default"])({
          url: urlStr,
          type: "get",
          headers: {
            Authorization: "Bearer " + self.get("session.data").authenticated.jwt
          },
          crossDomain: true
        }).then(function (consignments) {
          self.set("consignments", consignments.data);
          self.set("consignmentsLoading", false);
          self.set("page", consignments.page);
          self.set("totalPages", consignments.totalPages);
          self.set("consignmentNumber", "");
        })["catch"](function (error) {

          if (error && error.jqXHR && error.jqXHR.status === 401) {
            self.get("session").invalidate();
          }

          self.set("consignments", []);
          self.set("consignmentsLoading", false);
          self.set("page", 1); // Reset page number to 1
          self.set("totalPages", 0); // Reset total pages to 0
          self.set("consignmentNumber", "");
        });
      }
    }
  });
});