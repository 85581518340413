define("client/components/md-radios-radio", ["exports", "ember-cli-materialize/components/md-radios-radio"], function (exports, _mdRadiosRadio) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function get() {
      return _mdRadiosRadio["default"];
    }
  });
});