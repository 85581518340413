define("client/helpers/ember-power-select-build-selection", ["exports", "ember-power-select/helpers/ember-power-select-build-selection"], function (exports, _emberPowerSelectBuildSelection) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function get() {
      return _emberPowerSelectBuildSelection["default"];
    }
  });
  Object.defineProperty(exports, "emberPowerSelectBuildSelection", {
    enumerable: true,
    get: function get() {
      return _emberPowerSelectBuildSelection.emberPowerSelectBuildSelection;
    }
  });
});