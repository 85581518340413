define("client/initializers/ember-simple-auth", ["exports", "client/config/environment", "ember-simple-auth/configuration", "ember-simple-auth/initializers/setup-session", "ember-simple-auth/initializers/setup-session-service"], function (exports, _environment, _configuration, _setupSession, _setupSessionService) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = {
    name: "ember-simple-auth",

    initialize: function initialize(registry) {
      var config = _environment["default"]["ember-simple-auth"] || {};
      config.baseURL = _environment["default"].rootURL || _environment["default"].baseURL;
      _configuration["default"].load(config);

      (0, _setupSession["default"])(registry);
      (0, _setupSessionService["default"])(registry);
    }
  };
});