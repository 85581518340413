define("client/helpers/is-equal", ["exports", "ember-truth-helpers/helpers/is-equal"], function (exports, _isEqual) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function get() {
      return _isEqual["default"];
    }
  });
  Object.defineProperty(exports, "isEqual", {
    enumerable: true,
    get: function get() {
      return _isEqual.isEqual;
    }
  });
});