define("client/templates/components/webcam-snap", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          revision: "Ember@1.13.13",
          loc: {
            source: null,
            start: {
              line: 13,
              column: 4
            },
            end: {
              line: 15,
              column: 4
            }
          },
          moduleName: "client/templates/components/webcam-snap.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "md-btn", [], ["buttonType", "large", "icon", "mdi-image-photo-camera", "text", "Capture", "class", "green", "action", "snap"], ["loc", [null, [14, 6], [14, 108]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          revision: "Ember@1.13.13",
          loc: {
            source: null,
            start: {
              line: 15,
              column: 4
            },
            end: {
              line: 17,
              column: 4
            }
          },
          moduleName: "client/templates/components/webcam-snap.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "md-btn", [], ["buttonType", "large", "icon", "mdi-maps-local-printshop", "text", "Try Again", "class", "blue", "action", "reset"], ["loc", [null, [16, 6], [16, 113]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        revision: "Ember@1.13.13",
        loc: {
          source: null,
          start: {
            line: 1,
            column: 0
          },
          end: {
            line: 20,
            column: 0
          }
        },
        moduleName: "client/templates/components/webcam-snap.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col s12 center");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("video");
        dom.setAttribute(el3, "id", "webcam-video");
        dom.setAttribute(el3, "autoplay", "");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col s12 center");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("canvas");
        dom.setAttribute(el3, "id", "webcam-preview");
        dom.setAttribute(el3, "width", "1280");
        dom.setAttribute(el3, "height", "720");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row no-bottom");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col s12 center");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(fragment, [2]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element0, "class");
        morphs[1] = dom.createAttrMorph(element1, "class");
        morphs[2] = dom.createMorphAt(dom.childAt(fragment, [4, 1]), 1, 1);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["row ", ["get", "videoClass", ["loc", [null, [1, 18], [1, 28]]]]]]], ["attribute", "class", ["concat", ["row ", ["get", "canvasClass", ["loc", [null, [6, 18], [6, 29]]]]]]], ["block", "if", [["subexpr", "not", [["get", "captured", ["loc", [null, [13, 15], [13, 23]]]]], [], ["loc", [null, [13, 10], [13, 24]]]]], [], 0, 1, ["loc", [null, [13, 4], [17, 11]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});