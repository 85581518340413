define("client/controllers/diagnostics", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = Ember.Controller.extend({
    config: Ember.inject.service(),
    session: Ember.inject.service(),

    hubbedVersion: Ember.computed.alias("config.version"),
    environment: Ember.computed.alias("config.environmentFlag"),

    isDevLikeBuild: Ember.computed("config.environmentFlag", function () {
      return ["development", "staging", "test"].indexOf(this.get("config.environmentFlag")) > -1;
    }),

    browser: null,
    diagnostics: null,
    barcode: "",
    preview: null,

    browserInfo: function browserInfo() {
      var data = {};
      var ua = data.uaString = navigator.userAgent;
      var browserMatch = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*([\d\.]+)/i) || [];
      if (browserMatch[1]) {
        browserMatch[1] = browserMatch[1].toLowerCase();
      }
      var operaMatch = browserMatch[1] === "chrome";
      if (operaMatch) {
        operaMatch = ua.match(/\bOPR\/([\d\.]+)/);
      }

      if (/trident/i.test(browserMatch[1])) {
        var msieMatch = /\brv[ :]+([\d\.]+)/g.exec(ua) || [];
        data.name = "MSIE";
        data.version = msieMatch[1];
      } else if (operaMatch) {
        data.name = "Opera";
        data.version = operaMatch[1];
      } else if (browserMatch[1] === "safari") {
        var safariVersionMatch = ua.match(/version\/([\d\.]+)/i);
        data.name = "Safari";
        data.version = safariVersionMatch[1];
      } else {
        data.name = browserMatch[1];
        data.version = browserMatch[2];
      }

      var versionParts = [];
      if (data.version) {
        var versionPartsMatch = data.version.match(/(\d+)/g) || [];
        for (var i = 0; i < versionPartsMatch.length; i++) {
          versionParts.push(versionPartsMatch[i]);
        }
        if (versionParts.length > 0) {
          data.majorVersion = versionParts[0];
        }
      }
      data.name = data.name || "(unknown browser name)";
      data.version = {
        full: data.version || "(unknown full browser version)",
        parts: versionParts,
        major: versionParts.length > 0 ? versionParts[0] : "(unknown major browser version)"
      };

      return data;
    },

    chr: function chr(i) {
      return String.fromCharCode(i);
    },

    actions: {
      clearBarcode: function clearBarcode() {
        this.set("barcode", "");
      }
    }

  });
});