define("client/components/power-select/options", ["exports", "ember-power-select/components/power-select/options"], function (exports, _options) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function get() {
      return _options["default"];
    }
  });
});