define("client/components/md-fixed-btns", ["exports", "ember-cli-materialize/components/md-fixed-btns"], function (exports, _mdFixedBtns) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function get() {
      return _mdFixedBtns["default"];
    }
  });
});