define("client/helpers/hash", ["exports", "ember-hash-helper-polyfill/helpers/hash"], function (exports, _hash) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function get() {
      return _hash["default"];
    }
  });
  Object.defineProperty(exports, "hash", {
    enumerable: true,
    get: function get() {
      return _hash.hash;
    }
  });
});