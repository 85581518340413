define("client/components/ads-trigger", ["exports", "client/helpers/user-ability-helper"], function (exports, _userAbilityHelper) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = Ember.Component.extend({
    session: Ember.inject.service(),

    //ADs Screensaver
    adStartDelay: 300000, //5 minutes

    startAdsTrigger: function startAdsTrigger() {
      // console.log("[Component] startAdsTrigger");
      if (this.get("session.isAuthenticated") && !(0, _userAbilityHelper.userAbilityHelper)([this.get("session.data").authenticated.abilities, "ads"])) {} else {
        this.get("startAdsAction")();
      }
    },

    startAdsDelayedTriggerDebounce: function startAdsDelayedTriggerDebounce() {
      // console.log("[Component] startAdsDelayedTriggerDebounce");
      if (this.get("session.isAuthenticated") && !(0, _userAbilityHelper.userAbilityHelper)([this.get("session.data").authenticated.abilities, "ads"])) {} else {
        Ember.run.debounce(this, this.startAdsTrigger, this.adStartDelay);
      }
    },

    debounceAdStart: function debounceAdStart() {
      // console.log("[Component] debounceAdStart");
      if (this.get("session.isAuthenticated") && !(0, _userAbilityHelper.userAbilityHelper)([this.get("session.data").authenticated.abilities, "ads"])) {} else {
        if (this.get("currentPath") !== "ads") {
          Ember.run.debounce(this, this.startAdsTrigger, this.adStartDelay);
        } else {
          this.get("stopAdsAction")();
          Ember.run.later(this, this.startAdsDelayedTriggerDebounce, 10000);
        }
      }
    },

    click: function click(e) {
      // console.log("[Component] click");
      if (e.data._self.get("session.authenticated") && !(0, _userAbilityHelper.userAbilityHelper)([e.data._self.get("session.data").authenticated.abilities, "ads"])) {} else {
        if (e.target.id !== "manualAdStart") {
          e.data._self.debounceAdStart();
        }
      }
    },

    touchStart: function touchStart(e) {
      // console.log("[Component] touchStart");
      if (e.data._self.get("session.authenticated") && !(0, _userAbilityHelper.userAbilityHelper)([e.data._self.get("session.data").authenticated.abilities, "ads"])) {} else {
        if (e.target.id !== "manualAdStart") {
          e.data._self.debounceAdStart();
        }
      }
    },

    keyDown: function keyDown(e) {
      // console.log("[Component] keyDown");
      e.data._self.debounceAdStart();
    },

    didInsertElement: function didInsertElement() {
      // console.log("[Component] didInsertElement");
      this._super.apply(this, arguments);
      this.debounceAdStart();
      Ember.$(document).on("keydown", { _self: this }, this.keyDown);
      Ember.$(document).on("click", { _self: this }, this.click);
      Ember.$(document).on("touchstart", { _self: this }, this.touchStart);
    },

    willDestroyElement: function willDestroyElement() {
      Ember.$(document).off("keydown", this.keyDown);
      Ember.$(document).off("click", this.click);
      Ember.$(document).off("touchstart", this.touchStart);
    }

  });
});