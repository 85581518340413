define("client/helpers/date-now", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.dateNow = dateNow;
  function dateNow() {
    return Date.now();
  }

  exports["default"] = Ember.Helper.helper(dateNow);
});