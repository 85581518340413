define("client/helpers/color-helper-new", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.colorHelperNew = colorHelperNew;
	function colorHelperNew(params) {
		var currentState = params[0];

		switch (currentState) {
			case "notYetCheckedIn":
				return "none";
			case "awaitingCustomerPickup":
				return "none";
			case "awaitingCourierPickup":
				return "row-warning";
			default:
				return "none";
		}
	}

	exports["default"] = Ember.Helper.helper(colorHelperNew);
});