define("client/components/md-switches-switch", ["exports", "ember-cli-materialize/components/md-switches-switch"], function (exports, _mdSwitchesSwitch) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function get() {
      return _mdSwitchesSwitch["default"];
    }
  });
});