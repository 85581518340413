define("client/helpers/format-country", ["exports", "client/utils/countries-list"], function (exports, _countriesList) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatCountry = formatCountry;
  function formatCountry(params) {
    var isoCode = params[0];
    console.log(_countriesList.countries.find(function (c) {
      return c.iso === isoCode;
    }));
    if (_countriesList.countries.find(function (c) {
      return c.iso === isoCode;
    })) {
      return _countriesList.countries.find(function (c) {
        return c.iso === isoCode;
      }).country;
    } else {
      return isoCode;
    }
  }

  exports["default"] = Ember.Helper.helper(formatCountry);
});