define("client/routes/index", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = Ember.Route.extend(_unauthenticatedRouteMixin["default"], {
    session: Ember.inject.service(),

    redirect: function redirect() {
      if (this.get("session.isAuthenticated")) {
        this.transitionTo("dashboard");
      } else {
        this.transitionTo("sign_in");
      }
    }
  });
});