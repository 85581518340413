define("client/helpers/needs-quick-checkin-barcode", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.needsQuickCheckinBarcode = needsQuickCheckinBarcode;
  function needsQuickCheckinBarcode(articles) {
    // Check if articles is an array and it has at least one element
    if (Array.isArray(articles) && articles.length > 0) {
      var firstArticle = articles[0];
      if (firstArticle && firstArticle.currentState && firstArticle.currentState.state) {
        return firstArticle.currentState.state === "notYetCheckedIn";
      }
    }
    return false; // Default to false if the array or its first element doesn't meet the criteria.
  }

  exports["default"] = Ember.Helper.helper(needsQuickCheckinBarcode);
});