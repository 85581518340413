define("client/helpers/lt", ["exports", "ember-truth-helpers/helpers/lt"], function (exports, _lt) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var forExport = null;

  if (Ember.Helper) {
    forExport = Ember.Helper.helper(_lt.ltHelper);
  } else if (Ember.HTMLBars.makeBoundHelper) {
    forExport = Ember.HTMLBars.makeBoundHelper(_lt.ltHelper);
  }

  exports["default"] = forExport;
});