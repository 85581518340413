define("client/mixins/adapter-fetch", ["exports", "ember-fetch/mixins/adapter-fetch"], function (exports, _adapterFetch) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function get() {
      return _adapterFetch["default"];
    }
  });
});