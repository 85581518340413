define("client/initializers/app-version", ["exports", "ember-cli-app-version/initializer-factory", "client/config/environment"], function (exports, _initializerFactory, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = {
    name: "App Version",
    initialize: (0, _initializerFactory["default"])(_environment["default"].APP.name, _environment["default"].APP.version)
  };
});