define("client/components/md-fixed-btn", ["exports", "ember-cli-materialize/components/md-fixed-btn"], function (exports, _mdFixedBtn) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function get() {
      return _mdFixedBtn["default"];
    }
  });
});