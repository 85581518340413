define("client/initializers/add-modals-container", ["exports", "ember-modal-dialog/initializers/add-modals-container"], function (exports, _addModalsContainer) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = {
    name: "add-modals-container",
    initialize: _addModalsContainer["default"]
  };
});