define("client/helpers/increment-count", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.incrementCount = incrementCount;
  function incrementCount(params) {
    var value = params[0];
    return parseInt(value) + 1;
  }

  exports["default"] = Ember.Helper.helper(incrementCount);
});