define("client/helpers/user-ability-helper", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.userAbilityHelper = userAbilityHelper;
  function userAbilityHelper(params) {
    var abilities = params[0];
    var requested_ability = params[1];
    return Ember.$.inArray(requested_ability, abilities) >= 0;
  }

  exports["default"] = Ember.Helper.helper(userAbilityHelper);
});