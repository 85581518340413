define("client/controllers/settings", ["exports", "ic-ajax"], function (exports, _icAjax) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = Ember.Controller.extend({
    config: Ember.inject.service("config"),
    session: Ember.inject.service(),

    actions: {
      regenerateDailyCode: function regenerateDailyCode() {
        var self = this;
        self.controllerFor("application").send("showSpinner");
        (0, _icAjax["default"])({
          url: self.get("config.apiURL") + "dailyCode/generate",
          type: "POST",
          headers: {
            Authorization: "Bearer " + self.get("session.data").authenticated.jwt
          },
          crossDomain: true
        }).then(function () {
          self.controllerFor("application").send("hideSpinner");
          self.controllerFor("application").send("showDailyCode");
        }, function () /*error*/{
          self.controllerFor("application").send("showError");
          self.controllerFor("application").send("hideSpinner", 850);
        });
      },
      showCode: function showCode() {
        this.controllerFor("application").send("showDailyCode");
      }
    }

  });
});