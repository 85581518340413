define("client/helpers/new-date-diff", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.datediffdouble = datediffdouble;
	function datediffdouble(params) {
		var articles = params[2];
		var article = articles[0];

		var is_accept_checkin_required = params[3];
		var diffDays = 0;

		// do not show as expired if parcel is not accepted
		if (is_accept_checkin_required) {
			return diffDays;
		} else {
			if (params[4] == 2) {
				// for ebay cnc only
				var states = params[1].split(",");
				var search = article.states.filter(function (obj) {
					return states.indexOf(obj.state) > -1;
				});
				var articleDate = 0;
				if (search.length > 0) {
					articleDate = search[0].time;
				}
				var currentDate = params[0];
				var timeDiff = Math.abs(currentDate - articleDate);
				var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

				if (diffDays < 15) {
					// for ebay, expiry starts from 15th day
					return 0;
				} else {
					return diffDays;
				}
			} else {
				var states = params[1].split(",");
				var search = article.states.filter(function (obj) {
					return states.indexOf(obj.state) > -1;
				});
				var articleDate = 0;
				if (search.length > 0) {
					articleDate = search[0].time;
				}
				var currentDate = params[0];
				var timeDiff = Math.abs(currentDate - articleDate);
				var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
				var diffDaysR = Math.round(timeDiff / (1000 * 3600 * 24)); // 16.1 days ==> 16; 16.5 days ===> 17

				// for Parcel_Freight_Logistics_Pty_Ltd courier. Expiry has to be on 15th day
				if (params[5] == "Parcel_Freight_Logistics_Pty_Ltd") {
					if (diffDays < 15) {
						// expiry starts from 15th day
						return 0;
					} else {
						return diffDays;
					}
				} else if (params[5] == "Wish") {
					if (diffDaysR < 16) {
						// expiry starts from 16th day
						return 0;
					} else {
						return diffDaysR;
					}
				}

				// for rest of the couriers
				return diffDays;
			}
		}
	}

	exports["default"] = Ember.Helper.helper(datediffdouble);
});