define("client/controllers/support", ["exports", "ic-ajax"], function (exports, _icAjax) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = Ember.Controller.extend({
    config: Ember.inject.service(),
    session: Ember.inject.service(),

    partnersLogoList: []
  });
});