define("client/helpers/loud-recent", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.loudRecent = loudRecent;
  function loudRecent(params) {
    var value = params[0] / 1000 / 60 / 60;
    var t = new Date();
    var today = t.getTime() / 1000 / 60 / 60;

    if (today - value <= 1) {
      return true;
    } else {
      return false;
    }
  }

  exports["default"] = Ember.Helper.helper(loudRecent);
});