define("client/routes/international/archive", ["exports", "ic-ajax", "ember-simple-auth/mixins/authenticated-route-mixin"], function (exports, _icAjax, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = Ember.Route.extend(_authenticatedRouteMixin["default"], {
    config: Ember.inject.service("config"),
    session: Ember.inject.service(),

    setupController: function setupController(controller) {
      (0, _icAjax["default"])({
        url: this.get("config.apiURL") + "v2/outbound/international/consignments?page=" + controller.get("page"),
        type: "get",
        headers: {
          Authorization: "Bearer " + this.get("session.data").authenticated.jwt
        },
        crossDomain: true
      }).then(function (results) {
        controller.set("consignments", results.results);
        controller.set("page", results.page);
        controller.set("totalPages", results.totalPages);
      })["catch"](function (error) {
        if (error && error.jqXHR && error.jqXHR.status === 401) {
          controller.get("session").invalidate();
        }
      });
    }
  });
});