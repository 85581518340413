define("client/helpers/product-helper", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.productHelper = productHelper;
  function productHelper(params) {
    var productId = params[0];
    switch (productId) {
      case 0:
        return "Failed Delivery";
      case 1:
        return "HUBBED C&C";
      case 2:
        return "eBay C&C";
      default:
        return productId;
    }
  }

  exports["default"] = Ember.Helper.helper(productHelper);
});