define("client/helpers/article-state", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.articleStateDate = articleStateDate;
  function articleStateDate(params) {
    var article = params[0];
    var states = params[1].split(",");

    var search = article.states.filter(function (obj) {
      return states.indexOf(obj.state) > -1;
    });

    if (search.length > 0) {
      if (article.currentState.time === search[0].time) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
    //sort in ascending order by time
    /**
    var copyArray = Object.assign([], search).sort(function(a,b) {
        return a.time-b.time
    });
      if(copyArray.length > 0 ){
      return  copyArray[copyArray.length-1].time;
    } else {
      return 0;
    }**/
  }

  exports["default"] = Ember.Helper.helper(articleStateDate);
});