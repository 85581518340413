define("client/controllers/outbound/send", ["exports", "ic-ajax"], function (exports, _icAjax) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = Ember.Controller.extend({
    config: Ember.inject.service("config"),
    session: Ember.inject.service(),

    packagingPrices: [],
    consignments: [],
    cartShow: true,
    senderDetailsForm: true,
    senderReceiverDetails: {
      recipientName: "",
      recipientPostcode: "",
      recipientSuburbId: -1,
      recipientSuburb: "",
      recipientState: "",
      recipientLat: null,
      recipientLong: null,
      recipientAddress: "",
      senderName: ""
    },
    senderReceiverDetailsError: {
      recipientName: null,
      recipientAddress: null,
      senderName: null
    },
    postcodeResults: [],
    articleSizes: [],
    articlesForm: false,
    articles: [{
      code: "P0"
    }],

    summary: null,

    quoteRequest: null,
    selectedOfferId: null,
    selectedOfferCost: 0,

    finalConsignments: null,
    labelPrintDialog: false,

    addressOnParcel: false,
    recipientOnParcel: true,
    senderOnParcel: true,

    cart: [],

    currentFinalConsignment: (function () {
      return this.get("finalConsignments").objectAt(this.get("selectedFinalConsignmentId"));
    }).property("selectedFinalConsignmentId", "finalConsignments"),

    cartTotal: (function () {
      var price = parseFloat(this.get("currentConsignmentTotal"));
      this.get("cart").forEach(function (m) {
        price += parseFloat(m.shipping.price);
        price += parseFloat(m.packaging.price);
      });
      return price;
    }).property("currentConsignmentTotal", "cart"),

    subTotals: (function () {
      this.get("packagingPrices").forEach(function (m) {
        if (parseFloat(m.get("quantity")) < 0) {
          m.set("quantity", 0);
        }
        m.set("subTotal", Math.round(parseFloat(m.get("quantity")) * parseFloat(m.get("dropLocationPrice"))));
      });
    }).observes("packagingPrices.@each.quantity"),

    currentConsignmentTotal: (function () {
      var price = parseFloat(this.get("selectedOfferCost"));
      this.get("packagingPrices").forEach(function (m) {
        price += parseFloat(m.get("subTotal"));
      });
      return price;
    }).property("selectedOfferCost", "packagingPrices.@each.subTotal"),

    pendingCartItem: (function () {
      //Adds the current details to cart including shipping,
      var self = this;
      if (this.get("quoteRequest") === null || this.get("quoteRequest.quote.offers").length <= 0) {
        return null;
      }
      var selected = Ember.$.grep(this.get("quoteRequest.quote.offers"), function (e) {
        return e.id === self.get("selectedOfferId");
      });

      var shippingItem = {
        price: parseFloat(this.get("selectedOfferCost")),
        courier: selected[0].courier.name,
        location: this.get("senderReceiverDetails.recipientSuburb") + " " + this.get("senderReceiverDetails.recipientPostcode"),
        transaction: this.get("quoteRequest.booking.id"),
        offer: this.get("selectedOfferId")
      };

      var packaging = {
        price: 0,
        items: []
      };

      this.get("packagingPrices").forEach(function (m) {
        if (parseFloat(m.get("quantity")) > 0) {
          packaging.items.pushObject({ id: m.get("id"), quantity: parseInt(m.get("quantity")), price: parseFloat(m.get("dropLocationPrice")) });
        }
        packaging.price += Math.round(parseFloat(m.get("quantity")) * parseFloat(m.get("dropLocationPrice")));
      });

      if (packaging.price > 0) {
        return { shipping: shippingItem, packaging: packaging, packagingIncluded: true };
      } else {
        return { shipping: shippingItem, packaging: packaging, packagingIncluded: false };
      }
    }).property("selectedOfferCost", "selectedOfferId", "packagingPrices.@each.subTotal"),

    declaration: false,
    declaration1: false,

    paymentConfirmation: false,

    postcodeWatcher: (function () {
      var self = this;
      if (self.get("senderReceiverDetails.recipientPostcode").length >= 3) {
        (0, _icAjax["default"])({
          url: self.get("config.apiURL") + "address/postcode/" + self.get("senderReceiverDetails.recipientPostcode"),
          type: "GET",
          contentType: "application/json",
          crossDomain: true
        }).then(function (data) {
          self.set("postcodeResults", data);
          var selected = data[0];
          if (data.length > 0) {
            self.set("senderReceiverDetails.recipientSuburbId", 0);
            self.set("senderReceiverDetails.recipientSuburb", selected.suburb);
            self.set("senderReceiverDetails.recipientState", selected.state);
            self.set("senderReceiverDetails.recipientLat", selected.latitude);
            self.set("senderReceiverDetails.recipientLong", selected.longitude);
          }
        }, function () {
          self.set("postcodeResults", []);
          self.set("senderReceiverDetails.recipientSuburbId", -1);
          self.set("senderReceiverDetails.recipientSuburb", null);
          self.set("senderReceiverDetails.recipientState", null);
          self.set("senderReceiverDetails.recipientLat", null);
          self.set("senderReceiverDetails.recipientLong", null);
        });
      } else {
        self.set("postcodeResults", []);
        self.set("senderReceiverDetails.recipientSuburbId", -1);
        self.set("senderReceiverDetails.recipientSuburb", null);
        self.set("senderReceiverDetails.recipientState", null);
        self.set("senderReceiverDetails.recipientLat", null);
        self.set("senderReceiverDetails.recipientLong", null);
      }
    }).observes("senderReceiverDetails.recipientPostcode"),

    suburbWatcher: (function () {
      var self = this;
      if (self.get("senderReceiverDetails.recipientSuburbId")) {
        var data = self.get("postcodeResults");
        var selected = data.filter(function (value) {
          return value.id === self.get("senderReceiverDetails.recipientSuburbId");
        });
        if (selected.length > 0) {
          self.set("senderReceiverDetails.recipientSuburb", selected[0].suburb);
          self.set("senderReceiverDetails.recipientState", selected[0].state);
          self.set("senderReceiverDetails.recipientLat", selected[0].latitude);
          self.set("senderReceiverDetails.recipientLong", selected[0].longitude);
        }
      }
    }).observes("senderReceiverDetails.recipientSuburbId"),

    validSenderReceiver: function validSenderReceiver() {
      var self = this;
      self.controllerFor("application").send("showSpinner");

      if (!this.get("senderReceiverDetails.recipientName") || this.get("senderReceiverDetails.recipientName").length === 0) {
        this.set("senderReceiverDetails.recipientName", "See Package");
        this.set("senderReceiverDetailsError.recipientName", null);
      } else {
        this.set("senderReceiverDetailsError.recipientName", null);
      }

      if (this.get("senderReceiverDetails.recipientPostcode").length === 0) {
        this.set("senderReceiverDetailsError.recipientPostcode", ["This is a Required Field"]);
      } else if (this.get("senderReceiverDetails.recipientPostcode").length < 3 || this.get("senderReceiverDetails.recipientPostcode").length > 4) {
        this.set("senderReceiverDetailsError.recipientPostcode", ["Please enter a valid 3 or 4 digit Postcode"]);
      } else if (this.get("senderReceiverDetails.recipientPostcode").length === 4 || this.get("senderReceiverDetails.recipientPostcode").length === 3) {
        this.set("senderReceiverDetailsError.recipientPostcode", null);
      }

      if (this.get("senderReceiverDetails.recipientSuburb") === null) {
        this.set("senderReceiverDetailsError.recipientPostcode", ["Please enter a valid 3 or 4 digit Postcode"]);
      } else if (this.get("senderReceiverDetails.recipientSuburb").length === 0) {
        this.set("senderReceiverDetailsError.recipientSuburb", ["This is a Required Field"]);
      } else {
        this.set("senderReceiverDetailsError.recipientSuburb", null);
      }

      if (this.get("senderReceiverDetails.recipientAddress").length === 0) {
        this.set("senderReceiverDetailsError.recipientAddress", ["This is a Required Field"]);
      } else {
        this.set("senderReceiverDetailsError.recipientAddress", null);
      }

      if (this.get("senderOnParcel")) {
        this.set("senderReceiverDetails.senderName", "See Package");
        this.set("senderReceiverDetailsError.senderName", null);
      } else {
        if (this.get("senderReceiverDetails.senderName").length === 0) {
          this.set("senderReceiverDetailsError.senderName", ["This is a Required Field"]);
        } else {
          this.set("senderReceiverDetailsError.senderName", null);
        }
      }

      self.controllerFor("application").send("hideSpinner");
      return Ember.isEmpty(self.get("senderReceiverDetailsError.recipientName")) && Ember.isEmpty(self.get("senderReceiverDetailsError.recipientAddress")) && Ember.isEmpty(self.get("senderReceiverDetailsError.recipientPostcode")) && Ember.isEmpty(self.get("senderReceiverDetailsError.recipientSuburb")) && Ember.isEmpty(self.get("senderReceiverDetailsError.senderName"));
    },

    validArticles: function validArticles() {
      return true;
    },

    actions: {

      addAnotherConsignment: function addAnotherConsignment() {
        //Add current to cart
        this.send("addCurrentToCart");

        //Reset to start
        this.set("senderDetailsForm", true);
        this.set("senderReceiverDetails.recipientName", "");
        this.set("senderReceiverDetails.recipientPostcode", "");
        this.set("senderReceiverDetails.recipientSuburbId", -1);
        this.set("senderReceiverDetails.recipientSuburb", "");
        this.set("senderReceiverDetails.recipientState", "");
        this.set("senderReceiverDetails.recipientLat", null);
        this.set("senderReceiverDetails.recipientLong", null);
        this.set("senderReceiverDetails.recipientAddress", "");
        this.set("senderReceiverDetails.senderName", "");
        this.set("senderReceiverDetailsError", {
          recipientName: null,
          recipientAddress: null,
          senderName: null
        });
        this.set("postcodeResults", []);
        this.set("articlesForm", false);
        this.set("articles", [{
          code: "P0"
        }]);

        this.set("summary", null);

        this.set("quoteRequest", null);
        this.set("selectedOfferId", null);
        this.set("selectedOfferCost", 0);

        this.set("paymentConfirmation", false);
        this.set("declaration", false);
        this.set("declaration1", false);
        this.set("finalConsignments", null);
        this.set("paymentConfirmation", false);
        this.set("labelPrintDialog", false);
        this.set("finalConsignments", null);

        this.get("packagingPrices").forEach(function (m) {
          m.set("quantity", 0);
        });
      },

      processFinal: function processFinal() {
        this.send("checkout");
      },

      addCurrentToCart: function addCurrentToCart() {
        //Adds the current details to cart including shipping,
        var self = this;
        var selected = Ember.$.grep(this.get("quoteRequest.quote.offers"), function (e) {
          return e.id === self.get("selectedOfferId");
        });

        var shippingItem = {
          price: parseFloat(this.get("selectedOfferCost")),
          courier: selected[0].courier.name,
          location: this.get("senderReceiverDetails.recipientSuburb") + " " + this.get("senderReceiverDetails.recipientPostcode"),
          transaction: this.get("quoteRequest.booking.id"),
          offer: this.get("selectedOfferId")
        };

        var packaging = {
          price: 0,
          items: []
        };

        this.get("packagingPrices").forEach(function (m) {
          if (parseFloat(m.get("quantity")) > 0) {
            packaging.items.pushObject({ id: m.get("id"), quantity: parseInt(m.get("quantity")), price: m.get("dropLocationPrice") });
          }
          packaging.price += Math.round(parseFloat(m.get("quantity")) * parseFloat(m.get("dropLocationPrice")));
        });
        if (packaging.price > 0) {
          this.get("cart").pushObject({ shipping: shippingItem, packaging: packaging, packagingIncluded: true });
        } else {
          this.get("cart").pushObject({ shipping: shippingItem, packaging: packaging, packagingIncluded: false });
        }
      },

      removeCartItem: function removeCartItem(cartItem) {
        this.propertyWillChange("cart");
        this.get("cart").splice(cartItem, 1);
        this.propertyDidChange("cart");
      },

      addArticle: function addArticle() {
        this.get("articles").pushObject({
          code: "P0"
        });
      },

      removeArticle: function removeArticle() {
        if (this.get("articles").length > 1) {
          this.get("articles").popObject();
        } else {
          this.send("reset");
        }
      },

      saveSenderReceiverDetails: function saveSenderReceiverDetails() {
        var self = this;
        if (self.validSenderReceiver()) {
          (0, _icAjax["default"])({
            url: self.get("config.apiURL") + "address/parse?input=" + self.get("senderReceiverDetails.recipientAddress") + ", " + self.get("senderReceiverDetails.recipientSuburb") + ", " + self.get("senderReceiverDetails.recipientPostcode") + ", " + self.get("senderReceiverDetails.recipientState") + ", Australia",
            type: "GET",
            dataType: "json",
            contentType: "application/json",
            crossDomain: true
          }).then(function (data) {
            self.set("summary", {
              recipient: {
                name: self.get("senderReceiverDetails.recipientName"),
                address: data
              },
              sender: {
                name: self.get("senderReceiverDetails.senderName")
              },
              articles: []
            });
            self.set("senderDetailsForm", false);
            self.set("articlesForm", true);
          }, function () /*error*/{
            self.set("summary", {
              recipient: {
                name: self.get("senderReceiverDetails.recipientName"),
                address: {
                  street1: self.get("senderReceiverDetails.recipientAddress"),
                  street2: "",
                  postcode: self.get("senderReceiverDetails.recipientPostcode"),
                  city: self.get("senderReceiverDetails.recipientSuburb"),
                  state: self.get("senderReceiverDetails.recipientState"),
                  country: "Australia"
                }
              },
              sender: {
                name: self.get("senderReceiverDetails.senderName")
              },
              articles: []
            });
            self.set("senderDetailsForm", false);
            self.set("articlesForm", true);
          });
        }
      },

      getQuote: function getQuote() {
        if (this.validArticles()) {
          var self = this;
          var articles = self.get("articles").map(function (a) {
            return { code: a.code };
          });
          self.set("summary.articles", articles);
          self.controllerFor("application").send("showSpinner");
          (0, _icAjax["default"])({
            url: self.get("config.apiURL") + "outbound/quote",
            type: "POST",
            headers: {
              Authorization: "Bearer " + self.get("session.data").authenticated.jwt
            },
            data: JSON.stringify(self.get("summary")),
            contentType: "application/json",
            crossDomain: true
          }).then(function (result) {
            self.set("quoteRequest", result);
            if (result.quote.offers.length > 0) {
              self.set("selectedOfferId", result.quote.offers.objectAt(0).id);
              result.quote.offers.objectAt(0).details.forEach(function (d) {
                self.set("selectedOfferCost", self.get("selectedOfferCost") + d.price.sell);
              });
            }
            self.set("articlesForm", false);
            self.controllerFor("application").send("hideSpinner", 850);
          }, function () /*error*/{
            self.controllerFor("application").send("showError", "Error");
            self.controllerFor("application").send("hideSpinner", 850);
          });
        }
      },

      selectOption: function selectOption(offer) {
        this.set("selectedOfferId", offer.id);
        var p = 0;
        offer.details.forEach(function (d) {
          p += d.price.sell;
        });
        this.set("selectedOfferCost", p);
      },

      cancelCurrent: function cancelCurrent() {
        this.set("senderDetailsForm", true);

        this.set("senderReceiverDetails.recipientName", "");
        this.set("senderReceiverDetails.recipientPostcode", "");
        this.set("senderReceiverDetails.recipientSuburbId", -1);
        this.set("senderReceiverDetails.recipientSuburb", "");
        this.set("senderReceiverDetails.recipientState", "");
        this.set("senderReceiverDetails.recipientLat", null);
        this.set("senderReceiverDetails.recipientLong", null);
        this.set("senderReceiverDetails.recipientAddress", "");
        this.set("senderReceiverDetails.senderName", "");
        this.set("senderReceiverDetailsError", {
          recipientName: null,
          recipientAddress: null,
          senderName: null
        });
        this.set("postcodeResults", []);
        this.set("articlesForm", false);
        this.set("articles", [{
          code: "P0"
        }]);

        this.set("summary", null);

        this.set("quoteRequest", null);
        this.set("selectedOfferId", null);
        this.set("selectedOfferCost", 0);

        this.set("paymentConfirmation", false);
        this.set("declaration", false);
        this.set("declaration1", false);
        this.set("labelPrintDialog", false);
        this.set("finalConsignments", null);

        this.get("packagingPrices").forEach(function (m) {
          m.set("quantity", 0);
        });
      },

      reset: function reset() {
        this.set("senderDetailsForm", true);

        this.set("senderReceiverDetails.recipientName", "");
        this.set("senderReceiverDetails.recipientPostcode", "");
        this.set("senderReceiverDetails.recipientSuburbId", -1);
        this.set("senderReceiverDetails.recipientSuburb", "");
        this.set("senderReceiverDetails.recipientState", "");
        this.set("senderReceiverDetails.recipientLat", null);
        this.set("senderReceiverDetails.recipientLong", null);
        this.set("senderReceiverDetails.recipientAddress", "");
        this.set("senderReceiverDetails.senderName", "");
        this.set("senderReceiverDetailsError", {
          recipientName: null,
          recipientAddress: null,
          senderName: null
        });
        this.set("postcodeResults", []);
        this.set("articlesForm", false);
        this.set("articles", [{
          code: "P0"
        }]);

        this.set("summary", null);

        this.set("quoteRequest", null);
        this.set("selectedOfferId", null);
        this.set("selectedOfferCost", 0);

        this.set("paymentConfirmation", false);
        this.set("declaration", false);
        this.set("declaration1", false);
        this.set("paymentConfirmation", false);
        this.set("labelPrintDialog", false);
        this.set("finalConsignments", null);

        this.get("packagingPrices").forEach(function (m) {
          m.set("quantity", 0);
        });

        this.set("cart", []);

        var self = this;
        (0, _icAjax["default"])({
          url: self.get("config.apiURL") + "consignments/outbound",
          type: "get",
          headers: {
            Authorization: "Bearer " + this.get("session.data").authenticated.jwt
          },
          crossDomain: true
        }).then(function (result) {
          self.set("consignments", result);
        }, function (error) {
          if (error && error.jqXHR && error.jqXHR.status === 401) {
            self.get("session").invalidate();
          }
        });
      },

      checkout: function checkout() {
        this.set("paymentConfirmation", true);
      },

      paymentYes: function paymentYes() {
        var self = this;
        self.controllerFor("application").send("showSpinner");

        var postData = {
          products: [],
          consignments: [],
          total: self.get("cartTotal")
        };

        //pending item
        if (this.get("pendingCartItem")) {
          postData.consignments.pushObject({ transaction: this.get("pendingCartItem").shipping.transaction, offer: this.get("pendingCartItem").shipping.offer });
          this.get("pendingCartItem").packaging.items.forEach(function (packaging) {
            postData.products.pushObject({ productId: packaging.id, productPrice: packaging.price, quantity: packaging.quantity });
          });
        }
        //the rest of the items
        this.get("cart").forEach(function (cartItem) {
          postData.consignments.pushObject({ transaction: cartItem.shipping.transaction, offer: cartItem.shipping.offer });
          cartItem.packaging.items.forEach(function (packaging) {
            postData.products.pushObject({ productId: packaging.id, productPrice: packaging.price, quantity: packaging.quantity });
          });
        });

        console.log(postData);

        (0, _icAjax["default"])({
          url: self.get("config.apiURL") + "outbound/accept",
          type: "POST",
          headers: {
            Authorization: "Bearer " + self.get("session.data").authenticated.jwt
          },
          contentType: "application/json",
          crossDomain: true,
          data: JSON.stringify(postData)
        }).then(function (results) {
          console.log(results);
          self.set("finalConsignments", results);
          if (results.length > 0) {
            self.set("selectedFinalConsignmentId", 0);
          }
          self.set("paymentConfirmation", false);
          self.set("labelPrintDialog", true);

          self.controllerFor("application").send("hideSpinner", 850);
        }, function () /*error*/{
          self.controllerFor("application").send("showError", "Error");
          self.controllerFor("application").send("hideSpinner", 2000);
        })["catch"](function (error) {
          if (error && error.jqXHR && error.jqXHR.status === 401) {
            self.get("session").invalidate();
          } else {
            self.controllerFor("application").send("showError", "An error occurred");
          }
          self.controllerFor("application").send("hideSpinner", 2000);
        });
      },

      paymentNo: function paymentNo() {
        this.set("paymentConfirmation", false);
      },

      nextFinalConsignment: function nextFinalConsignment() {
        this.set("selectedFinalConsignmentId", this.get("selectedFinalConsignmentId") + 1);
      },

      finishLabels: function finishLabels() {
        this.set("labelPrintDialog", false);
        this.send("reset");
      }
    }
  });
});