define("client/routes/consignments/search", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = Ember.Route.extend(_authenticatedRouteMixin["default"], {
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set("consignment", null);
        controller.set("consignmentCardNumber", "");
        controller.set("notFound", false);
      }
    }
  });
});