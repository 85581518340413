define("client/components/signature-pad", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = Ember.Component.extend({
    tagName: "canvas",
    classNames: "signature-pad",
    value: null,

    //SignaturePad Options
    velocityFilterWeight: 0.7,
    minWidth: 0.5,
    maxWidth: 2.5,
    dotSize: 1.5,
    penColor: "black",
    backgroundColor: "rgba(0,0,0,0)",

    //Height And Width Options:
    height: "auto",
    width: "auto",

    didInsertElement: function didInsertElement() {
      var self = this;
      var options = {
        velocityFilterWeight: self.get("velocityFilterWeight"),
        minWidth: self.get("minWidth"),
        maxWidth: self.get("maxWidth"),
        dotSize: self.get("dotSize") || function () {
          return (self.get("minWidth") + self.get("maxWidth")) / 2;
        },
        penColor: self.get("penColor"),
        backgroundColor: self.get("backgroundColor"),
        onEnd: function onEnd() {
          self.set("value", self.getData());
        }
      };
      //TODO: WOrk out why auto doesnt work :(
      if (self.get("width") === "auto") {
        self.$().get(0).style.width = "100%";
        self.$().get(0).width = self.$().get(0).offsetWidth;
      } else {
        self.$().get(0).width = self.get("width");
      }

      if (self.get("height") === "auto") {
        self.$().get(0).style.height = "100%";
        self.$().get(0).height = self.$().get(0).offsetHeight;
      } else {
        self.$().get(0).height = self.get("height");
      }

      //Set up signaturepad
      self._signaturePad = new SignaturePad(self.$().get(0), options);
      self._signaturePad.fromDataURL(self.get("value"));
    },

    getData: function getData() {
      return this._signaturePad.toDataURL();
    },

    valueObserver: Ember.observer("value", function () {
      if (this.get("value") === "") {
        var self = this;
        var options = {
          velocityFilterWeight: self.get("velocityFilterWeight"),
          minWidth: self.get("minWidth"),
          maxWidth: self.get("maxWidth"),
          dotSize: self.get("dotSize") || function () {
            return (self.get("minWidth") + self.get("maxWidth")) / 2;
          },
          penColor: self.get("penColor"),
          backgroundColor: self.get("backgroundColor"),
          onEnd: function onEnd() {
            self.set("value", self.getData());
          }
        };
        //TODO: WOrk out why auto doesnt work :(
        if (self.get("width") === "auto") {
          self.$().get(0).style.width = "100%";
          self.$().get(0).width = self.$().get(0).offsetWidth;
        } else {
          self.$().get(0).width = self.get("width");
        }

        if (self.get("height") === "auto") {
          self.$().get(0).style.height = "100%";
          self.$().get(0).height = self.$().get(0).offsetHeight;
        } else {
          self.$().get(0).height = self.get("height");
        }

        //Set up signaturepad
        self._signaturePad = new SignaturePad(self.$().get(0), options);
        self._signaturePad.fromDataURL(self.get("value"));
      }
    })

  });
});