define("client/templates/reports/_nav", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          revision: "Ember@1.13.13",
          loc: {
            source: null,
            start: {
              line: 2,
              column: 4
            },
            end: {
              line: 2,
              column: 65
            }
          },
          moduleName: "client/templates/reports/_nav.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Checkin");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          revision: "Ember@1.13.13",
          loc: {
            source: null,
            start: {
              line: 3,
              column: 4
            },
            end: {
              line: 3,
              column: 68
            }
          },
          moduleName: "client/templates/reports/_nav.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Check Out");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          revision: "Ember@1.13.13",
          loc: {
            source: null,
            start: {
              line: 4,
              column: 4
            },
            end: {
              line: 4,
              column: 89
            }
          },
          moduleName: "client/templates/reports/_nav.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Awaiting Courier Pickup");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          revision: "Ember@1.13.13",
          loc: {
            source: null,
            start: {
              line: 5,
              column: 4
            },
            end: {
              line: 5,
              column: 79
            }
          },
          moduleName: "client/templates/reports/_nav.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Courier Pickup");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        revision: "Ember@1.13.13",
        loc: {
          source: null,
          start: {
            line: 1,
            column: 0
          },
          end: {
            line: 7,
            column: 0
          }
        },
        moduleName: "client/templates/reports/_nav.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "nav");
        dom.setAttribute(el1, "class", "collection");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(element0, 1, 1);
        morphs[1] = dom.createMorphAt(element0, 3, 3);
        morphs[2] = dom.createMorphAt(element0, 5, 5);
        morphs[3] = dom.createMorphAt(element0, 7, 7);
        return morphs;
      },
      statements: [["block", "link-to", ["reports.checkin"], ["class", "collection-item"], 0, null, ["loc", [null, [2, 4], [2, 77]]]], ["block", "link-to", ["reports.checkout"], ["class", "collection-item"], 1, null, ["loc", [null, [3, 4], [3, 80]]]], ["block", "link-to", ["reports.awaiting-pickup"], ["class", "collection-item"], 2, null, ["loc", [null, [4, 4], [4, 101]]]], ["block", "link-to", ["reports.courier-pickup"], ["class", "collection-item"], 3, null, ["loc", [null, [5, 4], [5, 91]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  })());
});