define("client/helpers/in-array", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.inArray = inArray;
  function inArray(params) {
    var value = params[0];
    var array = params[1].split(",");
    return array.indexOf(value) !== -1;
  }

  exports["default"] = Ember.Helper.helper(inArray);
});