define("client/routes/diagnostics", ["exports", "ic-ajax", "ember-simple-auth/mixins/authenticated-route-mixin"], function (exports, _icAjax, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = Ember.Route.extend(_authenticatedRouteMixin["default"], {
    config: Ember.inject.service("config"),
    session: Ember.inject.service(),

    model: function model() {
      return (0, _icAjax["default"])({
        url: this.get("config.apiURL") + "diagnostics",
        type: "get",
        headers: {
          Authorization: "Bearer " + this.get("session.data").authenticated.jwt
        },
        crossDomain: true
      });
    },

    setupController: function setupController(controller, model) {
      controller.set("diagnostics", model);
      controller.set("browser", controller.browserInfo());
      Ember.run.later(function () {
        controller.reloadPrinterStatus();
      }, 500);
    }
  });
});