define("client/helpers/format-price", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatPrice = formatPrice;
  function formatPrice(params) {
    var price = params[0];
    if (isNaN(parseInt(price))) {
      return "Invalid Price";
    } else {
      return "$" + (parseInt(price) / 100).toFixed(2);
    }
  }

  exports["default"] = Ember.Helper.helper(formatPrice);
});