define("client/routes/consignments/overdue", ["exports", "ic-ajax", "ember-simple-auth/mixins/authenticated-route-mixin"], function (exports, _icAjax, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = Ember.Route.extend(_authenticatedRouteMixin["default"], {
    config: Ember.inject.service("config"),
    session: Ember.inject.service(),

    setupController: function setupController(controller) {
      var self = this;
      var currentDate = new Date();
      var currentDateUnix = currentDate.getTime();
      controller.set("currentDateUnix", currentDateUnix);

      (0, _icAjax["default"])({
        // url: this.get("config.apiURL") + 'consignments?state=awaitingCustomerPickup,notYetCheckedIn&page=' + controller.get('checkedInPage') + '&perPage=' + controller.get('perPage')+'&orderBy=time_waiting&orderDir=ASC&limited=true',
        url: this.get("config.apiURL") + "consignments?state=awaitingCustomerPickup&page=" + controller.get("checkedInPage") + "&perPage=" + controller.get("perPage") + "&orderBy=time_waiting&orderDir=ASC&limited=true",
        type: "get",
        headers: {
          Authorization: "Bearer " + this.get("session.data").authenticated.jwt
        },
        crossDomain: true
      }).then(function (results) {
        controller.set("checkedInConsignments", results.data);
        controller.set("checkedInConsignmentsLoading", false);
        controller.set("checkedInPage", results.page);
        controller.set("checkedInTotalPages", results.totalPages);
      })["catch"](function (error) {
        if (error && error.jqXHR && error.jqXHR.status === 401) {
          controller.get("session").invalidate();
        }
      });

      (0, _icAjax["default"])({
        url: this.get("config.apiURL") + "consignments?state=awaitingCourierPickup&page=" + controller.get("returnRequestPage") + "&perPage=" + controller.get("perPage") + "&orderBy=time_waiting&orderDir=ASC&limited=true",
        type: "get",
        headers: {
          Authorization: "Bearer " + this.get("session.data").authenticated.jwt
        },
        crossDomain: true
      }).then(function (results) {
        controller.set("returnRequestedConsignments", results.data);
        controller.set("returnRequestedConsignmentsLoading", false);
        controller.set("returnRequestPage", results.page);
        controller.set("returnRequestTotalPages", results.totalPages);
      })["catch"](function (error) {
        if (error && error.jqXHR && error.jqXHR.status === 401) {
          self.get("session").invalidate();
        }
      });
    }
  });
});