define("client/helpers/modal-class", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.modalClass = modalClass;
  function modalClass(params) {
    return ["modal", "show"].concat(params);
  }

  exports["default"] = Ember.Helper.helper(modalClass);
});