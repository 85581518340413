define("client/initializers/truth-helpers", ["exports", "ember-truth-helpers/utils/register-helper", "ember-truth-helpers/helpers/and", "ember-truth-helpers/helpers/or", "ember-truth-helpers/helpers/equal", "ember-truth-helpers/helpers/not", "ember-truth-helpers/helpers/is-array", "ember-truth-helpers/helpers/not-equal", "ember-truth-helpers/helpers/gt", "ember-truth-helpers/helpers/gte", "ember-truth-helpers/helpers/lt", "ember-truth-helpers/helpers/lte"], function (exports, _registerHelper, _and, _or, _equal, _not, _isArray, _notEqual, _gt, _gte, _lt, _lte) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() /* container, application */{

    // Do not register helpers from Ember 1.13 onwards, starting from 1.13 they
    // will be auto-discovered.
    if (Ember.Helper) {
      return;
    }

    (0, _registerHelper.registerHelper)("and", _and.andHelper);
    (0, _registerHelper.registerHelper)("or", _or.orHelper);
    (0, _registerHelper.registerHelper)("eq", _equal.equalHelper);
    (0, _registerHelper.registerHelper)("not", _not.notHelper);
    (0, _registerHelper.registerHelper)("is-array", _isArray.isArrayHelper);
    (0, _registerHelper.registerHelper)("not-eq", _notEqual.notEqualHelper);
    (0, _registerHelper.registerHelper)("gt", _gt.gtHelper);
    (0, _registerHelper.registerHelper)("gte", _gte.gteHelper);
    (0, _registerHelper.registerHelper)("lt", _lt.ltHelper);
    (0, _registerHelper.registerHelper)("lte", _lte.lteHelper);
  }

  exports["default"] = {
    name: "truth-helpers",
    initialize: initialize
  };
});