define("client/components/md-table-col", ["exports", "ember-cli-materialize/components/md-table-col"], function (exports, _mdTableCol) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function get() {
      return _mdTableCol["default"];
    }
  });
});