define("client/components/pagination-component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports["default"] = Component.extend({
    tagName: "pagination-component",
    classNames: ["pagination"],

    hasNextPage: (function () {
      return this.get("page") < this.get("totalPages");
    }).property("page", "totalPages"),

    hasPreviousPage: (function () {
      return this.get("page") > 1;
    }).property("page", "totalPages"),

    actions: {
      nextPage: function nextPage() {
        this.get("getPageAction")(this.get("page") + 1);
        this.rerender();
      },
      previousPage: function previousPage() {
        this.get("getPageAction")(this.get("page") - 1);
        this.rerender();
      }
    }
  });
});