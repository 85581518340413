define("client/helpers/gt", ["exports", "ember-truth-helpers/helpers/gt"], function (exports, _gt) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var forExport = null;

  if (Ember.Helper) {
    forExport = Ember.Helper.helper(_gt.gtHelper);
  } else if (Ember.HTMLBars.makeBoundHelper) {
    forExport = Ember.HTMLBars.makeBoundHelper(_gt.gtHelper);
  }

  exports["default"] = forExport;
});