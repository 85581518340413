define("client/services/config", ["exports", "client/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = Ember.Service.extend({
    __config__: Ember.computed(function () {
      var originalConfig = _environment["default"];
      var configOverrides = window.config;
      var finalConfig = Ember.$.extend({}, originalConfig, configOverrides);
      return finalConfig;
    }),

    unknownProperty: function unknownProperty(path) {
      return this.get("__config__." + path);
    }
  });
});