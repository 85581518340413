define("client/components/md-focusinput", ["exports", "ember-cli-materialize/components/md-input-field"], function (exports, _mdInputField) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var MDFocusInput = _mdInputField["default"].extend({
    type: "text",
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this._setupLabel();
    }
  });
  exports["default"] = MDFocusInput;
});