define("client/templates/partials/_nav", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          revision: "Ember@1.13.13",
          loc: {
            source: null,
            start: {
              line: 2,
              column: 4
            },
            end: {
              line: 2,
              column: 123
            }
          },
          moduleName: "client/templates/partials/_nav.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("i");
          dom.setAttribute(el1, "class", "mdi-action-home left");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("  Home");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            revision: "Ember@1.13.13",
            loc: {
              source: null,
              start: {
                line: 5,
                column: 6
              },
              end: {
                line: 5,
                column: 163
              }
            },
            moduleName: "client/templates/partials/_nav.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "mdi-communication-call-made left");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("  Parcels In Store");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            revision: "Ember@1.13.13",
            loc: {
              source: null,
              start: {
                line: 6,
                column: 6
              },
              end: {
                line: 6,
                column: 157
              }
            },
            moduleName: "client/templates/partials/_nav.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "mdi-maps-directions-car left");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("  Return To Courier");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          revision: "Ember@1.13.13",
          loc: {
            source: null,
            start: {
              line: 4,
              column: 4
            },
            end: {
              line: 7,
              column: 4
            }
          },
          moduleName: "client/templates/partials/_nav.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          return morphs;
        },
        statements: [["block", "link-to", ["consignments.checkout"], ["class", "collection-item indigo-border border-lighten-1"], 0, null, ["loc", [null, [5, 6], [5, 175]]]], ["block", "link-to", ["consignments.overdue"], ["class", "collection-item teal-border border-lighten-3"], 1, null, ["loc", [null, [6, 6], [6, 169]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          revision: "Ember@1.13.13",
          loc: {
            source: null,
            start: {
              line: 8,
              column: 32
            },
            end: {
              line: 8,
              column: 200
            }
          },
          moduleName: "client/templates/partials/_nav.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("i");
          dom.setAttribute(el1, "class", "mdi-av-web left");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" Handover to Courier");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [0]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element0);
          return morphs;
        },
        statements: [["element", "action", ["startHandover"], [], ["loc", [null, [8, 124], [8, 151]]]]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          revision: "Ember@1.13.13",
          loc: {
            source: null,
            start: {
              line: 9,
              column: 4
            },
            end: {
              line: 9,
              column: 136
            }
          },
          moduleName: "client/templates/partials/_nav.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("i");
          dom.setAttribute(el1, "class", "mdi-av-web left");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("  Customer Drop Off");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      var child0 = (function () {
        return {
          meta: {
            revision: "Ember@1.13.13",
            loc: {
              source: null,
              start: {
                line: 11,
                column: 4
              },
              end: {
                line: 11,
                column: 137
              }
            },
            moduleName: "client/templates/partials/_nav.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "mdi-content-send left");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("  Book Domestic");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          revision: "Ember@1.13.13",
          loc: {
            source: null,
            start: {
              line: 10,
              column: 4
            },
            end: {
              line: 12,
              column: 4
            }
          },
          moduleName: "client/templates/partials/_nav.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["block", "link-to", ["outbound.send"], ["class", "collection-item blue-border border-lighten-3"], 0, null, ["loc", [null, [11, 4], [11, 149]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child5 = (function () {
      var child0 = (function () {
        return {
          meta: {
            revision: "Ember@1.13.13",
            loc: {
              source: null,
              start: {
                line: 16,
                column: 4
              },
              end: {
                line: 16,
                column: 138
              }
            },
            moduleName: "client/templates/partials/_nav.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "mdi-av-web left");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" Paperless Returns");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          revision: "Ember@1.13.13",
          loc: {
            source: null,
            start: {
              line: 15,
              column: 4
            },
            end: {
              line: 17,
              column: 4
            }
          },
          moduleName: "client/templates/partials/_nav.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["block", "link-to", ["paperless.checkin"], ["class", "collection-item blue-border border-lighten-3"], 0, null, ["loc", [null, [16, 4], [16, 150]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child6 = (function () {
      var child0 = (function () {
        return {
          meta: {
            revision: "Ember@1.13.13",
            loc: {
              source: null,
              start: {
                line: 21,
                column: 4
              },
              end: {
                line: 21,
                column: 147
              }
            },
            moduleName: "client/templates/partials/_nav.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "mdi-maps-flight left");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" Book International");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          revision: "Ember@1.13.13",
          loc: {
            source: null,
            start: {
              line: 20,
              column: 4
            },
            end: {
              line: 22,
              column: 4
            }
          },
          moduleName: "client/templates/partials/_nav.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["block", "link-to", ["international"], ["class", "collection-item light-green-border border-lighten-3"], 0, null, ["loc", [null, [21, 4], [21, 159]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child7 = (function () {
      var child0 = (function () {
        return {
          meta: {
            revision: "Ember@1.13.13",
            loc: {
              source: null,
              start: {
                line: 25,
                column: 133
              },
              end: {
                line: 25,
                column: 237
              }
            },
            moduleName: "client/templates/partials/_nav.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("sup");
            var el2 = dom.createElement("i");
            dom.setAttribute(el2, "class", "mdi-av-new-releases orange-text iconPM");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          revision: "Ember@1.13.13",
          loc: {
            source: null,
            start: {
              line: 25,
              column: 4
            },
            end: {
              line: 25,
              column: 244
            }
          },
          moduleName: "client/templates/partials/_nav.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("i");
          dom.setAttribute(el1, "class", "mdi-action-help left");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("  Agent Support ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "session.data.supportSectionBool", ["loc", [null, [25, 139], [25, 170]]]]], [], 0, null, ["loc", [null, [25, 133], [25, 244]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child8 = (function () {
      return {
        meta: {
          revision: "Ember@1.13.13",
          loc: {
            source: null,
            start: {
              line: 26,
              column: 5
            },
            end: {
              line: 26,
              column: 136
            }
          },
          moduleName: "client/templates/partials/_nav.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("i");
          dom.setAttribute(el1, "class", "mdi-content-content-copy left");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("  Reports");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        revision: "Ember@1.13.13",
        loc: {
          source: null,
          start: {
            line: 1,
            column: 0
          },
          end: {
            line: 38,
            column: 0
          }
        },
        moduleName: "client/templates/partials/_nav.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "nav");
        dom.setAttribute(el1, "class", "collection");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\t");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    \n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2, "id", "manualAdStart");
        dom.setAttribute(el2, "class", "collection-item purple-border border-lighten-3");
        var el3 = dom.createElement("i");
        dom.setAttribute(el3, "class", "mdi-image-panorama left");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" Lock Screen");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n     ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "collection-spacer");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2, "class", "collection-item red-border border-lighten-3");
        var el3 = dom.createElement("i");
        dom.setAttribute(el3, "class", "mdi-action-input left");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  Logout");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "logo");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        dom.setAttribute(el2, "style", "color:white;height: 20px;margin-top: -35px;margin-bottom:15px;");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2, "href", "/");
        var el3 = dom.createElement("img");
        dom.setAttribute(el3, "src", "images/hubbed-white.png");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var element2 = dom.childAt(element1, [5]);
        var element3 = dom.childAt(element1, [15]);
        var element4 = dom.childAt(element1, [23]);
        var morphs = new Array(13);
        morphs[0] = dom.createMorphAt(element1, 1, 1);
        morphs[1] = dom.createMorphAt(element1, 3, 3);
        morphs[2] = dom.createElementMorph(element2);
        morphs[3] = dom.createMorphAt(element2, 0, 0);
        morphs[4] = dom.createMorphAt(element1, 7, 7);
        morphs[5] = dom.createMorphAt(element1, 9, 9);
        morphs[6] = dom.createMorphAt(element1, 11, 11);
        morphs[7] = dom.createMorphAt(element1, 13, 13);
        morphs[8] = dom.createElementMorph(element3);
        morphs[9] = dom.createMorphAt(element1, 17, 17);
        morphs[10] = dom.createMorphAt(element1, 19, 19);
        morphs[11] = dom.createElementMorph(element4);
        morphs[12] = dom.createMorphAt(dom.childAt(fragment, [2, 1]), 1, 1);
        return morphs;
      },
      statements: [["block", "link-to", ["dashboard"], ["class", "collection-item grey-border border-lighten-1"], 0, null, ["loc", [null, [2, 4], [2, 135]]]], ["block", "if", [["subexpr", "user-ability-helper", [["get", "userAbilities", ["loc", [null, [4, 31], [4, 44]]]], "inbound"], [], ["loc", [null, [4, 10], [4, 55]]]]], [], 1, null, ["loc", [null, [4, 4], [7, 11]]]], ["element", "action", ["startHandover"], [], ["loc", [null, [8, 4], [8, 31]]]], ["block", "link-to", ["consignments.handover"], ["class", "collection-item blue-border border-lighten-3"], 2, null, ["loc", [null, [8, 32], [8, 212]]]], ["block", "link-to", ["online.checkin"], ["class", "collection-item blue-border border-lighten-3"], 3, null, ["loc", [null, [9, 4], [9, 148]]]], ["block", "if", [["subexpr", "user-ability-helper", [["get", "userAbilities", ["loc", [null, [10, 31], [10, 44]]]], "outbound"], [], ["loc", [null, [10, 10], [10, 56]]]]], [], 4, null, ["loc", [null, [10, 4], [12, 11]]]], ["block", "if", [["get", "session.data.printAtStore", ["loc", [null, [15, 10], [15, 35]]]]], [], 5, null, ["loc", [null, [15, 4], [17, 11]]]], ["block", "if", [["subexpr", "user-ability-helper", [["get", "userAbilities", ["loc", [null, [20, 31], [20, 44]]]], "outbound-international"], [], ["loc", [null, [20, 10], [20, 70]]]]], [], 6, null, ["loc", [null, [20, 4], [22, 11]]]], ["element", "action", ["startAds"], [], ["loc", [null, [24, 7], [24, 29]]]], ["block", "link-to", ["support"], ["class", "collection-item orange-border border-lighten-3"], 7, null, ["loc", [null, [25, 4], [25, 256]]]], ["block", "link-to", ["reports"], ["class", "collection-item orange-border border-lighten-3"], 8, null, ["loc", [null, [26, 5], [26, 148]]]], ["element", "action", ["showSignOut"], [], ["loc", [null, [29, 7], [29, 32]]]], ["content", "environment-flag", ["loc", [null, [34, 4], [34, 24]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8]
    };
  })());
});