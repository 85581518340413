define("client/templates/dashboard", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              revision: "Ember@1.13.13",
              loc: {
                source: null,
                start: {
                  line: 8,
                  column: 8
                },
                end: {
                  line: 13,
                  column: 8
                }
              },
              moduleName: "client/templates/dashboard.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "style", "text-align:center;");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("br");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("Loading...\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
              return morphs;
            },
            statements: [["inline", "md-loader", [], ["mode", "circular", "size", "small"], ["loc", [null, [10, 12], [10, 54]]]]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    revision: "Ember@1.13.13",
                    loc: {
                      source: null,
                      start: {
                        line: 30,
                        column: 22
                      },
                      end: {
                        line: 33,
                        column: 22
                      }
                    },
                    moduleName: "client/templates/dashboard.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                        ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                        ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("br");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["inline", "moment-format", [["get", "a.currentState.time", ["loc", [null, [31, 40], [31, 59]]]], "MMM Do, HH:mm"], [], ["loc", [null, [31, 24], [31, 78]]]]],
                  locals: ["a"],
                  templates: []
                };
              })();
              var child1 = (function () {
                return {
                  meta: {
                    revision: "Ember@1.13.13",
                    loc: {
                      source: null,
                      start: {
                        line: 40,
                        column: 22
                      },
                      end: {
                        line: 43,
                        column: 22
                      }
                    },
                    moduleName: "client/templates/dashboard.hbs"
                  },
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                        ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                        ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("br");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["inline", "state-helper", [["get", "a.currentState.state", ["loc", [null, [41, 39], [41, 59]]]]], [], ["loc", [null, [41, 24], [41, 61]]]]],
                  locals: ["a"],
                  templates: []
                };
              })();
              return {
                meta: {
                  revision: "Ember@1.13.13",
                  loc: {
                    source: null,
                    start: {
                      line: 26,
                      column: 16
                    },
                    end: {
                      line: 46,
                      column: 16
                    }
                  },
                  moduleName: "client/templates/dashboard.hbs"
                },
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("tr");
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("td");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("td");
                  var el3 = dom.createTextNode("\n");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("                    ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("td");
                  var el3 = dom.createTextNode("\n                      ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                    ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("td");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("td");
                  var el3 = dom.createTextNode("\n");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("                    ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element0 = dom.childAt(fragment, [1]);
                  var element1 = dom.childAt(element0, [3]);
                  var morphs = new Array(6);
                  morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
                  morphs[1] = dom.createAttrMorph(element1, "data-value");
                  morphs[2] = dom.createMorphAt(element1, 1, 1);
                  morphs[3] = dom.createMorphAt(dom.childAt(element0, [5]), 1, 1);
                  morphs[4] = dom.createMorphAt(dom.childAt(element0, [7]), 0, 0);
                  morphs[5] = dom.createMorphAt(dom.childAt(element0, [9]), 1, 1);
                  return morphs;
                },
                statements: [["content", "consignment.articles.length", ["loc", [null, [28, 24], [28, 55]]]], ["attribute", "data-value", ["concat", [["subexpr", "object-helper", [["subexpr", "array-first-helper", [["get", "consignment.articles", ["loc", [null, [29, 72], [29, 92]]]]], [], ["loc", [null, [29, 52], [29, 93]]]], "currentState", "time"], [], ["loc", [null, [29, 36], [29, 117]]]]]]], ["block", "each", [["get", "consignment.articles", ["loc", [null, [30, 30], [30, 50]]]]], [], 0, null, ["loc", [null, [30, 22], [33, 31]]]], ["content", "consignment.receiver.name", ["loc", [null, [36, 22], [36, 51]]]], ["content", "consignment.consignmentNumber", ["loc", [null, [38, 24], [38, 57]]]], ["block", "each", [["get", "consignment.articles", ["loc", [null, [40, 30], [40, 50]]]]], [], 1, null, ["loc", [null, [40, 22], [43, 31]]]]],
                locals: ["consignment"],
                templates: [child0, child1]
              };
            })();
            return {
              meta: {
                revision: "Ember@1.13.13",
                loc: {
                  source: null,
                  start: {
                    line: 14,
                    column: 10
                  },
                  end: {
                    line: 49,
                    column: 10
                  }
                },
                moduleName: "client/templates/dashboard.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("table");
                dom.setAttribute(el1, "class", "sortable");
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("thead");
                var el3 = dom.createTextNode("\n                ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("tr");
                var el4 = dom.createTextNode("\n                  ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("th");
                var el5 = dom.createTextNode("Parcels");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                  ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("th");
                var el5 = dom.createTextNode("Checked In");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                  ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("th");
                var el5 = dom.createTextNode("Customer");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                  ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("th");
                var el5 = dom.createTextNode("Consignment No#");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                  ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("th");
                var el5 = dom.createTextNode("Status");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n                ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n              ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("tbody");
                var el3 = dom.createTextNode("\n");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("              ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3]), 1, 1);
                return morphs;
              },
              statements: [["block", "each", [["get", "consignments", ["loc", [null, [26, 24], [26, 36]]]]], [], 0, null, ["loc", [null, [26, 16], [46, 25]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                revision: "Ember@1.13.13",
                loc: {
                  source: null,
                  start: {
                    line: 49,
                    column: 10
                  },
                  end: {
                    line: 51,
                    column: 10
                  }
                },
                moduleName: "client/templates/dashboard.hbs"
              },
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            No packages currently checked in\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              revision: "Ember@1.13.13",
              loc: {
                source: null,
                start: {
                  line: 13,
                  column: 8
                },
                end: {
                  line: 52,
                  column: 10
                }
              },
              moduleName: "client/templates/dashboard.hbs"
            },
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "consignments", ["loc", [null, [14, 16], [14, 28]]]]], [], 0, 1, ["loc", [null, [14, 10], [51, 17]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            revision: "Ember@1.13.13",
            loc: {
              source: null,
              start: {
                line: 7,
                column: 8
              },
              end: {
                line: 53,
                column: 8
              }
            },
            moduleName: "client/templates/dashboard.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "consignmentsLoading", ["loc", [null, [8, 14], [8, 33]]]]], [], 0, 1, ["loc", [null, [8, 8], [52, 17]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          revision: "Ember@1.13.13",
          loc: {
            source: null,
            start: {
              line: 5,
              column: 6
            },
            end: {
              line: 54,
              column: 6
            }
          },
          moduleName: "client/templates/dashboard.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "pagination-component", [], ["page", ["subexpr", "@mut", [["get", "page", ["loc", [null, [6, 36], [6, 40]]]]], [], []], "totalPages", ["subexpr", "@mut", [["get", "totalPages", ["loc", [null, [6, 52], [6, 62]]]]], [], []], "getPageAction", ["subexpr", "action", ["getPage"], [], ["loc", [null, [6, 77], [6, 95]]]], "class", "finger-pagination"], ["loc", [null, [6, 8], [6, 124]]]], ["block", "md-card-content", [], [], 0, null, ["loc", [null, [7, 8], [53, 28]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        revision: "Ember@1.13.13",
        loc: {
          source: null,
          start: {
            line: 1,
            column: 0
          },
          end: {
            line: 59,
            column: 0
          }
        },
        moduleName: "client/templates/dashboard.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "id", "content");
        dom.setAttribute(el1, "class", "col s12 full-height grey lighten-2");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h1");
        dom.setAttribute(el2, "class", "page-heading center");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("'s");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col s12");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(dom.childAt(element2, [1]), 0, 0);
        morphs[1] = dom.createMorphAt(dom.childAt(element2, [3, 1]), 1, 1);
        return morphs;
      },
      statements: [["content", "dashboard.dropLocation.name", ["loc", [null, [2, 34], [2, 65]]]], ["block", "md-card", [], ["title", "Currently in store", "titleClass", "orange-text", "id", "image-card"], 0, null, ["loc", [null, [5, 6], [54, 18]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});