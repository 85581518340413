define("client/routes/support", ["exports", "ic-ajax", "ember-simple-auth/mixins/authenticated-route-mixin"], function (exports, _icAjax, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = Ember.Route.extend(_authenticatedRouteMixin["default"], {
    config: Ember.inject.service(),
    session: Ember.inject.service("session"),

    setupController: function setupController(controller) {
      var session = this.get("session");
      var userProducts = session.get("data.userProducts");
      var agentLocation = session.get("data.country");

      session.set("data.supportSectionBool", false);

      controller.set("showDropOffDiv", false);

      (0, _icAjax["default"])({
        url: this.get("config.apiURL") + "dashboard",
        type: "get",
        headers: {
          Authorization: "Bearer " + this.get("session.data").authenticated.jwt
        },
        crossDomain: true
      }).then(function (results) {
        agentLocation = results.dropLocation.address.country;
        if (results && results.dropLocation && results.dropLocation.dropLocationProduct && results.dropLocation.dropLocationProduct.length > 0) {
          userProducts = results.dropLocation.dropLocationProduct;
          for (var i = 0; i < userProducts.length; i++) {
            if (userProducts[i].productId && (userProducts[i].productId == 2 || userProducts[i].productId == 3)) {
              controller.set("showDropOffDiv", true);
              break;
            }
          }
        }
      })["catch"](function (error) {
        if (error && error.jqXHR && error.jqXHR.status === 401) {
          // Logout the user if a 401 response is received
          this.get("session").invalidate();
        }
      });

      /**
      ajax({
          url: this.get("config.apiURL") + 'linkedCouriers',
          type: 'get',
          headers: {
            'Authorization': 'Bearer ' + this.get('session.data').authenticated.jwt
          },
          crossDomain: true,
      }).then(function(result){
        var logoArr = [];
        if(result && result.length>0) {
          for(var i=0; i<result.length; i++) {
            if(result[i].logo!=="" && result[i].logo!==null && agentLocation==="Australia" && !result[i].code.toLowerCase().includes("nz")) {
              logoArr.push(result[i]);
            } else if(result[i].logo!=="" && result[i].logo!==null && agentLocation==="New Zealand") {
              logoArr.push(result[i]);
            }
          }
        }  
        controller.set('partnersLogoList', logoArr);
      });
      **/
    }
  });
});