define("client/controllers/consignments/search", ["exports", "ic-ajax"], function (exports, _icAjax) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = Ember.Controller.extend({
    config: Ember.inject.service(),
    session: Ember.inject.service(),

    consignment: null,
    consignmentCardNumber: "",
    notFound: false,

    actions: {
      find: function find() {
        if (this.get("consignmentCardNumber").length > 0) {
          var self = this;
          (0, _icAjax["default"])({
            url: self.get("config.apiURL") + "consignments/cardNumber/" + self.get("consignmentCardNumber"),
            type: "get",
            headers: {
              Authorization: "Bearer " + self.get("session.data").authenticated.jwt
            },
            crossDomain: true
          }).then(function (consignment) {
            self.set("consignment", consignment);
            self.set("consignmentCardNumber", "");
            self.set("notFound", false);
          }, function () /*error*/{
            self.set("consignment", null);
            self.set("consignmentCardNumber", "");
            self.set("notFound", true);
          });
        }
      }
    }
  });
});