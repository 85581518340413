define("client/router", ["exports", "client/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var Router = Ember.Router.extend({
    location: _environment["default"].locationType
  });

  Router.map(function () {
    this.route("dashboard");
    this.route("sign_in");
    this.route("settings");
    this.route("diagnostics");
    this.resource("consignments", function () {
      this.route("search");
      this.route("checkout");
      this.route("overdue");
      this.route("handover");
    });
    this.resource("reports", function () {
      this.route("checkin");
      this.route("checkout");
      this.route("awaiting-pickup");
      this.route("courier-pickup");
    });
    this.resource("outbound", function () {
      this.route("send");
    });
    this.resource("online", function () {
      this.route("checkin");
    });
    this.resource("paperless", function () {
      this.route("paperlessreturn");
      this.route("checkin");
    });
    this.resource("international", function () {
      this.route("archive");
      this.route("book");
    });

    this.route("ads");
    this.route("support");
  });

  exports["default"] = Router;
});