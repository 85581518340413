define("client/components/md-table", ["exports", "ember-cli-materialize/components/md-table"], function (exports, _mdTable) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function get() {
      return _mdTable["default"];
    }
  });
});