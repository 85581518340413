define("client/controllers/online/checkin", ["exports", "ic-ajax"], function (exports, _icAjax) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = Ember.Controller.extend({
    config: Ember.inject.service(),
    session: Ember.inject.service(),

    consignments: [],
    consignmentsLoading: true,
    queryParams: ["page", "perPage"],
    page: 1,
    perPage: 6,
    totalPages: 1,

    // Expected values: null, "miles", "checkin"
    showModal: null,

    milesResults: [],
    milesSelected: null,

    couriers: config.dropoff_couriers,
    consignmentNumber: "",
    articles: [{ barcode: "" }],
    courier: "hub",

    validCheckin: (function () {
      var articles = this.get("articles");
      var validArticles = true;
      articles.forEach(function (a) {
        if (a.barcode.length === 0) {
          validArticles = false;
        }
      });

      //removed toll validation
      return (validArticles || this.get("courier") !== "tolll") && this.get("courier") !== null && this.get("courier").length > 0 && this.get("consignmentNumber") !== null && this.get("consignmentNumber").length > 0;
    }).property("articles.@each.barcode", "courier", "consignmentNumber"),

    actions: {

      milesSelect: function milesSelect(result) {
        if (this.get("milesSelected") === result) {
          this.set("milesSelected", null);
        } else {
          this.set("milesSelected", result);
        }
      },

      checkForConsignment: function checkForConsignment() {
        var self = this;

        var scanned = this.get("consignmentNumber").trim();
        self.controllerFor("application").send("showSpinner");

        (0, _icAjax["default"])({
          type: "GET",
          headers: {
            Authorization: "Bearer " + this.get("session.data").authenticated.jwt
          },
          contentType: "application/json",
          crossDomain: true,
          url: "" + this.get("config.apiURL") + "v2/consumer/drops/search/consignment?consignmentNumber=" + encodeURIComponent(scanned)
        }).then(function (result) {
          if (result.length > 0) {
            for (var i = 0; i < result.length; i++) {
              if (result[i].courier == "toll") {
                result[i].courierName = "TOLL";
              } else if (result[i].courier == "cpl") {
                result[i].courierName = "Courier Please";
              } else if (result[i].courier == "dhl") {
                result[i].courierName = "DHL (Express)";
              } else {
                var courier = result[i].courier;
                if (courier !== undefined && courier.lastIndexOf("_") !== -1) {
                  if (parseInt(courier.substr(courier.lastIndexOf("_") + 1))) {
                    courier = courier.substr(0, courier.lastIndexOf("_"));
                    courier = courier.replace(/_/g, " ");
                  }
                }
                result[i].courierName = courier;
              }
            }
            // Show the consignment modal
            self.set("consignmentResults", result);
            self.set("singleConsignmentBool", false);
            if (result.length == 1) {
              self.set("singleConsignmentBool", true);
              self.set("courier", result[0].courier);
              self.set("consignmentNumber", result[0].consignmentNumber);
              self.set("externalOnlineId", result[0].externalOnlineId);
            }
            self.controllerFor("application").send("hideSpinner");
            self.send("launchCheckinModal");
          } else {
            self.controllerFor("application").send("showError", "For parcels that cannot be scanned, kindly refuse the parcel and direct the customer to the logistics vendor who supplied the shipping label.");
            self.controllerFor("application").send("hideSpinner", 5000);
          }
        }, function (error) {

          if (error && error.jqXHR && error.jqXHR.status === 401) {
            self.get("session").invalidate();
          }
          // We don't want to block progress too much - use the original check-in workflow
          self.controllerFor("application").send("showError", "For parcels that cannot be scanned, kindly refuse the parcel and direct the customer to the logistics vendor who supplied the shipping label.");
          self.controllerFor("application").send("hideSpinner", 5000);
        });
      },
      checkForMilesBarcode: function checkForMilesBarcode() {
        var self = this;

        var scanned = this.get("consignmentNumber");
        if (!scanned) {
          return;
        }
        self.send("launchCheckinModal");
        return;
        //self.controllerFor('application').send('showSpinner');

        (0, _icAjax["default"])({
          url: this.get("config.apiURL") + "v2/consumer/miles?q=" + scanned,
          headers: {
            Authorization: "Bearer " + this.get("session.data").authenticated.jwt
          },
          contentType: "application/json",
          crossDomain: true
        }).then(function (result) {

          if (result.length > 0) {
            // Show the miles modal
            self.set("milesResults", result);
            self.send("launchMilesModal");
          } else {
            // Use the original check-in workflow
            self.send("launchCheckinModal");
          }

          self.controllerFor("application").send("hideSpinner");
        }, function () {
          // We don't want to block progress too much - use the original check-in workflow
          self.send("launchCheckinModal");

          self.controllerFor("application").send("showError", "For parcels that cannot be scanned, kindly refuse the parcel and direct the customer to the logistics vendor who supplied the shipping label.");
          self.controllerFor("application").send("hideSpinner", 5000);
        });
      },

      milesConfirm: function milesConfirm() {
        var self = this;
        self.controllerFor("application").send("showSpinner");

        (0, _icAjax["default"])({
          url: this.get("config.apiURL") + "v2/consumer/miles/" + this.get("milesSelected").id + "/lodgements",
          type: "POST",
          headers: {
            Authorization: "Bearer " + this.get("session.data").authenticated.jwt
          },
          contentType: "application/json",
          crossDomain: true
        }).then(function () {
          self.send("closeMilesModal");
          self.controllerFor("application").send("showSuccess");
          self.controllerFor("application").send("hideSpinner", 850);
        }, function () {
          self.send("closeMilesModal");
          self.controllerFor("application").send("showError", "An error occured while lodging the consignment. Please try again, or advise the customer to contact customer service.");
          self.controllerFor("application").send("hideSpinner", 5000);
        });
      },

      milesReject: function milesReject() {
        // None of these result match the package - fall back to the old workflow
        this.set("milesResults", null);
        this.set("showMilesModal", false);
        this.send("launchCheckinModal");
      },

      launchMilesModal: function launchMilesModal() {
        this.set("showModal", "miles");
      },

      closeMilesModal: function closeMilesModal() {
        this.set("showModal", null);
        this.set("consignmentNumber", "");
        this.set("milesResults", []);
      },

      launchCheckinModal: function launchCheckinModal() {
        this.set("showModal", "checkin");
      },

      closeDropErrorModal: function closeDropErrorModal() {
        this.set("showDropErrorModal", false);
      },

      closeCheckinModal: function closeCheckinModal() {
        this.set("showModal", null);
        this.set("consignmentNumber", "");
        this.set("articles", [{ barcode: "" }]);
        this.set("courier", "hub");
      },

      onAddArticle: function onAddArticle() {
        this.get("articles").pushObject({
          barcode: ""
        });
      },

      onRemoveArticle: function onRemoveArticle(index) {
        var articles = JSON.parse(JSON.stringify(this.get("articles")));
        articles.splice(index, 1);
        this.set("articles", articles);
      },

      confirmCheckIn: function confirmCheckIn(data) {
        var self = this;
        self.set("courier", data.courier);
        self.set("externalOnlineId", data.externalOnlineId);
        self.set("courierName", data.courierName);
        self.set("consignmentNumber", data.consignmentNumber);
        self.send("checkin");
      },

      checkin: function checkin() {
        if (this.get("consignmentNumber").length > 0) {
          var self = this;
          self.controllerFor("application").send("showSpinner");
          var consignmentData = {
            courier: this.get("courier"),
            number: this.get("consignmentNumber"),
            barcodes: this.get("articles").map(function (a) {
              return a.barcode;
            }).filter(function (a) {
              return a && a.length > 0;
            })
          };
          (0, _icAjax["default"])({
            url: self.get("config.apiURL") + "v2/consumer/drops",
            type: "POST",
            headers: {
              Authorization: "Bearer " + self.get("session.data").authenticated.jwt
            },
            data: JSON.stringify(consignmentData),
            contentType: "application/json",
            crossDomain: true
          }).then(function () /*result*/{
            self.send("getPage", 1);
            self.send("closeCheckinModal");
            self.controllerFor("application").send("showSuccess");
            self.controllerFor("application").send("hideSpinner", 850);
          }, function (error) {
            if (error.jqXHR.status === 400) {

              if (error.jqXHR.responseText == "EXPIRED") {
                self.controllerFor("application").send("hideSpinner");
                self.send("closeCheckinModal");
                self.set("showDropErrorModal", true);
              } else {
                self.controllerFor("application").send("showError", error.jqXHR.responseJSON[0].message);
              }
            } else {
              self.controllerFor("application").send("showError", "For parcels that cannot be scanned, kindly refuse the parcel and direct the customer to the logistics vendor who supplied the shipping label.");
            }
            self.send("getPage", 1);
            self.controllerFor("application").send("hideSpinner", 5000);
          });
        }
      },

      getPage: function getPage(page) {
        var self = this;
        self.set("consignmentsLoading", true);
        var url = self.get("config.apiURL") + "v2/consumer/drops?page=" + page + "&perPage=" + this.get("perPage");
        (0, _icAjax["default"])({
          url: url,
          type: "get",
          headers: {
            Authorization: "Bearer " + self.get("session.data").authenticated.jwt
          },
          crossDomain: true
        }).then(function (result) {
          self.set("consignments", result.results);
          self.set("page", result.page);
          self.set("totalPages", result.totalPages);
          self.set("consignmentsLoading", false);
        })["catch"](function (error) {
          self.set("consignmentsLoading", false);
          if (error && error.jqXHR && error.jqXHR.status === 401) {
            self.get("session").invalidate();
          }
        });
      }
    }
  });
});