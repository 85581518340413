define("client/instance-initializers/ember-simple-auth", ["exports", "ember-simple-auth/instance-initializers/setup-session-restoration"], function (exports, _setupSessionRestoration) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = {
    name: "ember-simple-auth",

    initialize: function initialize(instance) {
      (0, _setupSessionRestoration["default"])(instance);
    }
  };
});