define("client/app", ["exports", "ember/resolver", "ember/load-initializers", "client/config/environment"], function (exports, _resolver, _loadInitializers, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var App = void 0;

  Ember.MODEL_FACTORY_INJECTIONS = true;

  App = Ember.Application.extend({
    modulePrefix: _environment["default"].modulePrefix,
    podModulePrefix: _environment["default"].podModulePrefix,
    Resolver: _resolver["default"]
  });

  (0, _loadInitializers["default"])(App, _environment["default"].modulePrefix);

  exports["default"] = App;
});