define("client/controllers/application", ["exports", "ic-ajax"], function (exports, _icAjax) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports["default"] = Ember.Controller.extend({
    config: Ember.inject.service(),
    session: Ember.inject.service(),

    loading: false,
    loadingCount: 0,
    loadingSuccess: false,
    loadingSuccessInfo: false,
    loadingError: false,
    signOutModal: false,
    errorText: "",
    infoText: "",

    previousPath: "",

    quickAuth: false,
    quickAuthPasscode: "",
    quickAuthError: "",

    dailyCodeModal: false,
    dailyCode: "",

    popupDelay: 900000, // 15 minutes
    popupDelayWish: 7200000000, // 120 minutes

    checkInModal: false,
    checkInConsignments: [],
    checkInConsignmentsWish: [],
    checkInImage: "",
    imageUpload: false,
    idUpload: "",
    imagesPart: [],

    checkInModalButtonEnabled: Ember.computed("checkInConsignments.@each.marked", function () {
      return this.get("checkInConsignments").every(function (consignment) {
        return consignment.marked;
      });
    }),

    checkInModalButtonEnabledWish: Ember.computed("checkInConsignmentsWish.@each.marked", function () {
      return this.get("checkInConsignmentsWish").every(function (consignment) {
        return consignment.marked;
      });
    }),

    hexColorsArray: ["#F39C12", "#52B3D9", "#D91E18"],

    userAbilities: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.send("showPopUps");
      this.send("showPopUpsWish");
      this.send("checkLoginForImagePopup");
    },

    actions: {
      showDailyCode: function showDailyCode() {
        var self = this;
        self.send("showSpinner");
        (0, _icAjax["default"])({
          url: self.get("config.apiURL") + "dailyCode",
          type: "GET",
          headers: {
            Authorization: "Bearer " + self.get("session.data").authenticated.jwt
          },
          crossDomain: true
        }).then(function (result) {
          self.set("dailyCodeModal", true);
          self.set("dailyCode", result.dailyCode);
          self.send("hideSpinner");
        }, function () /*error*/{
          self.send("showError");
          self.send("hideSpinner", 850);
        });
      },

      showSignOut: function showSignOut() {
        var self = this;
        self.set("signOutModal", true);
      },
      closeSignOut: function closeSignOut() {
        var self = this;
        self.set("signOutModal", false);
      },

      regenerateDailyCode: function regenerateDailyCode() {
        var self = this;
        self.send("showSpinner");
        (0, _icAjax["default"])({
          url: self.get("config.apiURL") + "dailyCode/generate",
          type: "POST",
          headers: {
            Authorization: "Bearer " + self.get("session.data").authenticated.jwt
          },
          crossDomain: true
        }).then(function (result) {
          self.set("dailyCode", result.dailyCode);
          self.send("hideSpinner");
        }, function () /*error*/{
          self.send("showError");
          self.send("hideSpinner", 850);
        });
      },

      closeDailyCode: function closeDailyCode() {
        this.set("dailyCodeModal", false);
        this.set("dailyCode", "");
      },

      showQuickAuth: function showQuickAuth() {
        this.set("quickAuth", true);
      },

      checkQuickAuth: function checkQuickAuth() {
        if (this.get("quickAuthPasscode").length > 0) {
          var self = this;
          var quickAuthData = {
            dailyCode: self.get("quickAuthPasscode")
          };
          (0, _icAjax["default"])({
            url: self.get("config.apiURL") + "dailyCode",
            type: "POST",
            headers: {
              Authorization: "Bearer " + self.get("session.data").authenticated.jwt
            },
            data: JSON.stringify(quickAuthData),
            contentType: "application/json",
            crossDomain: true
          }).then(function () /*result*/{
            self.set("quickAuth", false);
            self.set("quickAuthPasscode", "");
          }, function () /*error*/{
            self.set("quickAuthPasscode", "");
            self.controllerFor("application").send("showSpinner");
            self.controllerFor("application").send("showError", "Incorrect Auth Code Entered");
            self.controllerFor("application").send("hideSpinner", 1500);
          });
        }
      },

      onCancelWish: function onCancelWish(consignment) {
        Ember.set(consignment, "marked", true);
        Ember.set(consignment, "accept", false);
      },

      onCloseWishPopUp: function onCloseWishPopUp() {
        var _this = this;

        this.set("showHourlyHandoverPopup", false);
        Ember.run.later(this, function () {
          _this.send("showPopUpsWish");
        }, this.get("popupDelayWish"));
      },

      onDiscardWishbutton: function onDiscardWishbutton(consignment) {
        Ember.set(consignment, "marked", true);
        Ember.set(consignment, "accept", true);
      },

      modalDoneCheckInsWish: function modalDoneCheckInsWish() {
        var _this2 = this;

        var self = this;
        self.send("showSpinner");
        var fetchFromPopup = [].concat(_toConsumableArray(this.get("checkInConsignmentsWish"))).filter(function (item) {
          return item.accept == true;
        });
        var alldiscard = [];
        fetchFromPopup.forEach(function (a) {
          alldiscard.push(a.id);
        });
        var data = { consignments: alldiscard };
        (0, _icAjax["default"])({
          url: this.get("config.apiURL") + "consignments/discard",
          type: "POST",
          headers: {
            Authorization: "Bearer " + this.get("session.data").authenticated.jwt
          },
          data: JSON.stringify(data),
          contentType: "application/json",
          crossDomain: true
        }).then(function (result) {
          self.send("hideSpinner");
          self.set("showHourlyHandoverPopup", false);
          if (Ember.getOwner(self).lookup("controller:application").currentPath === "consignments.checkout") {
            window.location.reload(true);
          }
          Ember.run.later(this, function () {
            self.send("showPopUpsWish");
          }, self.get("popupDelayWish"));
        }, function () /* error */{
          self.send("hideSpinner");
          self.set("showHourlyHandoverPopup", false);
          self.send("showError");
          Ember.run.later(_this2, function () {
            _this2.send("showPopUpsWish");
          }, _this2.get("popupDelayWish"));
        });
      },

      startAds: function startAds() {
        this.set("previousPath", this.get("currentPath"));
      },

      stopAds: function stopAds() {
        if (this.get("previousPath") === "ads") {
          this.transitionTo("dashboard");
        } else {
          this.transitionTo(this.get("previousPath"));
        }
        this.send("showQuickAuth");
      },

      showPopUps: function showPopUps() {
        var _this3 = this;

        (0, _icAjax["default"])({
          url: this.get("config.apiURL") + "consignments/awaitingQuickCheckin",
          type: "GET",
          headers: {
            Authorization: "Bearer " + this.get("session.data").authenticated.jwt
          },
          contentType: "application/json",
          crossDomain: true
        }).then(function (result) {
          if (result.length > 0) {

            // Filter out articles with currentState set to "notYetCheckedIn" for each consignment
            result.forEach(function (consignment) {
              consignment.articles = consignment.articles.filter(function (article) {
                return article.currentState.state == "notYetCheckedIn";
              });
            });

            // Remove consignments without any articles
            result = result.filter(function (consignment) {
              return consignment.articles.length > 0;
            });

            _this3.set("checkInConsignments", result);
            _this3.set("checkInModal", true);
          } else {
            Ember.run.later(_this3, function () {
              _this3.send("showPopUps");
            }, _this3.get("popupDelay"));
          }
        }, function () /* error */{
          Ember.run.later(_this3, function () {
            _this3.send("showPopUps");
          }, _this3.get("popupDelay"));
        });
      },

      showPopUpsWish: function showPopUpsWish() {
        var _this4 = this;

        (0, _icAjax["default"])({
          url: this.get("config.apiURL") + "consignments/awaitingToBeDiscarded",
          type: "GET",
          headers: {
            Authorization: "Bearer " + this.get("session.data").authenticated.jwt
          },
          contentType: "application/json",
          crossDomain: true
        }).then(function (result) {
          if (result.length > 0) {
            _this4.set("checkInConsignmentsWish", result);
            _this4.set("showHourlyHandoverPopup", true);
          } else {
            Ember.run.later(_this4, function () {
              _this4.send("showPopUpsWish");
            }, _this4.get("popupDelayWish"));
          }
        }, function () /* error */{
          Ember.run.later(_this4, function () {
            _this4.send("showPopUpsWish");
          }, _this4.get("popupDelayWish"));
        });
      },

      modalAcceptCheckIn: function modalAcceptCheckIn(consignment) {

        Ember.set(consignment, "marked", true);
        Ember.set(consignment, "accept", true);

        var articles = consignment.articles || [];
        articles.forEach(function (article) {
          Ember.set(article, "marked", true);
          Ember.set(article, "accept", true);
        });
      },

      modalRejectCheckIn: function modalRejectCheckIn(consignment) {

        Ember.set(consignment, "marked", true);
        Ember.set(consignment, "accept", false);

        var articles = consignment.articles || [];
        articles.forEach(function (article) {
          Ember.set(article, "marked", true);
          Ember.set(article, "accept", false);
        });
      },

      modalAcceptCheckInBarcodeWise: function modalAcceptCheckInBarcodeWise(consignment, article) {
        var articles = consignment.articles || [];

        var articleIndex = articles.findIndex(function (a) {
          return a.id === article.id;
        });

        if (articleIndex !== -1) {
          Ember.set(articles[articleIndex], "marked", true);
          Ember.set(articles[articleIndex], "accept", true);

          var allArticlesMarked = articles.every(function (a) {
            return a.marked;
          });

          if (allArticlesMarked) {
            Ember.set(consignment, "marked", true);
            Ember.set(consignment, "accept", false);
          }
        }
      },

      modalRejectCheckInBarcodeWise: function modalRejectCheckInBarcodeWise(consignment, article) {
        var articles = consignment.articles || [];

        var articleIndex = articles.findIndex(function (a) {
          return a.id === article.id;
        });

        if (articleIndex !== -1) {
          Ember.set(articles[articleIndex], "marked", true);
          Ember.set(articles[articleIndex], "accept", false);

          var allArticlesMarked = articles.every(function (a) {
            return a.marked;
          });

          if (allArticlesMarked) {
            Ember.set(consignment, "marked", true);
            Ember.set(consignment, "accept", false);
          }
        }
      },

      modalDoneCheckIns: function modalDoneCheckIns() {
        var _this5 = this;

        this.send("showSpinner");
        (0, _icAjax["default"])({
          url: this.get("config.apiURL") + "consignments/checkin/bulk",
          type: "POST",
          headers: {
            Authorization: "Bearer " + this.get("session.data").authenticated.jwt
          },
          // data: JSON.stringify(this.get("checkInConsignments").map((consignment) => {
          //   return {
          //     consignmentId: consignment.id,
          //     accept: consignment.accept
          //   };
          // })),

          data: JSON.stringify(this.get("checkInConsignments").reduce(function (acc, consignment) {
            // Check the courier condition
            if (consignment.courier === "TOLL_AU_1591745841" || consignment.courier === "TOLL_AU_1562909600") {
              // Include each article as a separate object
              consignment.articles.forEach(function (article) {
                acc.push({
                  consignmentId: consignment.id,
                  articleId: article.id,
                  accept: article.accept
                });
              });
            } else {
              // Include the consignment without specific articles
              acc.push({
                consignmentId: consignment.id,
                accept: consignment.accept
              });
            }
            return acc;
          }, [])),

          contentType: "application/json",
          crossDomain: true
        }).then(function () /* success */{
          _this5.set("checkInModal", false);
          _this5.send("hideSpinner");
          if (Ember.getOwner(_this5).lookup("controller:application").currentPath === "consignments.checkout") {
            window.location.reload(true);
          }
          Ember.run.later(_this5, function () {
            _this5.send("showPopUps");
          }, _this5.get("popupDelay"));
        }, function () /* error */{
          _this5.set("checkInModal", false);
          _this5.send("showError");
          _this5.send("hideSpinner");
          Ember.run.later(_this5, function () {
            _this5.send("showPopUps");
          }, _this5.get("popupDelay"));
        });
      },

      showSpinner: function showSpinner(text) {
        this.set("loadingText", "Loading");
        if (text) {
          this.set("loadingText", text);
        }
        this.set("loading", true);
        this.set("loadingCount", this.get("loadingCount") + 1);
        this.set("loadingSuccess", false);
        this.set("loadingSuccessInfo", false);
        this.set("loadingError", false);
      },

      hideSpinner: function hideSpinner(delay) {
        var self = this;
        if (self.get("loadingCount") === 1) {
          setTimeout(function () {
            self.set("loading", false);
            self.set("loadingSuccess", false);
            self.set("loadingSuccessInfo", false);
            self.set("loadingError", false);
          }, delay);
        }
        if (self.get("loadingCount") > 0) {
          self.set("loadingCount", self.get("loadingCount") - 1);
        }
      },

      showError: function showError(text) {
        this.set("loadingSuccess", false);
        this.set("loadingSuccessInfo", false);
        this.set("loadingError", true);
        if (text) {
          this.set("errorText", text);
        } else {
          this.set("errorText", "Error");
        }
      },

      showSuccess: function showSuccess() {
        this.set("loadingSuccess", true);
        this.set("loadingError", false);
        this.set("loadingSuccessInfo", false);
      },

      showSuccessInfo: function showSuccessInfo(text) {
        this.set("loadingSuccessInfo", true);
        this.set("loadingSuccess", false);
        this.set("loadingError", false);
        if (text) {
          this.set("infoText", text);
        } else {
          this.set("infoText", "Error");
        }
      },

      SaveAcknowledgement: function SaveAcknowledgement() {
        var _this6 = this;

        this.send("showSpinner");
        (0, _icAjax["default"])({
          url: this.get("config.apiURL") + "v2/agent/notifications",
          type: "PUT",
          headers: {
            Authorization: "Bearer " + this.get("session.data").authenticated.jwt
          },
          contentType: "application/json",
          crossDomain: true,
          data: JSON.stringify({ notifications: [this.get("idUpload")] })
        }).then(function () {
          _this6.send("hideSpinner");
          _this6.set("imageUpload", false);
          _this6.set("showImagePopUp", false);
          Ember.run.later(_this6, function () {
            _this6.send("showImagePopUp");
          }, _this6.get("popupDelay"));
        })["catch"](function (error) {
          if (error && error.jqXHR && error.jqXHR.status === 401) {
            // Logout the user if a 401 response is received
            _this6.get("session").invalidate();
          } else {
            _this6.set("checkInModal", false);
            _this6.send("showError");
            _this6.send("hideSpinner");
            Ember.run.later(_this6, function () {
              _this6.send("showImagePopUp");
            }, _this6.get("popupDelay"));
          }
        });
      },

      AcknowledgementImage: function AcknowledgementImage(id) {
        var _this7 = this;

        (0, _icAjax["default"])({
          url: this.get("config.apiURL") + "v2/agent/notification/" + id,
          type: "GET",
          headers: {
            Authorization: "Bearer " + this.get("session.data").authenticated.jwt
          },
          contentType: "application/json",
          crossDomain: true
        }).then(function (result) {
          _this7.set("checkInImage", result);
        });
      },

      checkLoginForImagePopup: function checkLoginForImagePopup() {
        var _this8 = this;

        if (this.get("session.data").authenticated.jwt !== undefined) {
          this.send("showImagePopUp");
        } else {
          Ember.run.later(this, function () {
            _this8.send("checkLoginForImagePopup");
          }, 3000);
        }
      },

      showImagePopUp: function showImagePopUp() {
        var _this9 = this;

        (0, _icAjax["default"])({
          url: this.get("config.apiURL") + "v2/agent/notifications",
          type: "GET",
          headers: {
            Authorization: "Bearer " + this.get("session.data").authenticated.jwt
          },
          contentType: "application/json",
          crossDomain: true
        }).then(function (result) {
          if (result.length > 0) {
            _this9.set("checkInModal", false);
            _this9.send("AcknowledgementImage", result[0].id);
            _this9.set("idUpload", result[0].id);
            _this9.set("imageUpload", true);
          } else {
            Ember.run.later(_this9, function () {
              _this9.send("showImagePopUp");
            }, _this9.get("popupDelay"));
          }
        })["catch"](function (error) {
          if (error && error.jqXHR && error.jqXHR.status === 401) {
            // Logout the user if a 401 response is received
            _this9.get("session").invalidate();
          } else {
            Ember.run.later(_this9, function () {
              _this9.send("showImagePopUp");
            }, _this9.get("popupDelay"));
          }
        });
      }
    }
  });
});