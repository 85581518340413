define("client/routes/paperless/paperlessreturn", ["exports", "ic-ajax", "ember-simple-auth/mixins/authenticated-route-mixin"], function (exports, _icAjax, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = Ember.Route.extend(_authenticatedRouteMixin["default"], {
    config: Ember.inject.service("config"),
    session: Ember.inject.service(),

    setupController: function setupController(controller) {
      var currentDate = new Date();
      var currentDateUnix = currentDate.getTime();
      controller.set("currentDateUnix", currentDateUnix);

      controller.set("searchedEcpCodeArr", []);
      var errorLogOptionsArr = [{ name: "I did not receive this parcel", value: "NOT_FOUND" }, { name: "The parcel was damaged when it was delivered", value: "DAMAGED_D" }, { name: "The parcel has been damaged while in store", value: "DAMAGED" }, { name: "The parcel is oversize/over weight and is unsafe to handle", value: "OVER_WEIGHT_SIZE" }, { name: "The parcel has been delivered to the wrong store", value: "WRONG_STORE" }, { name: "Other(Please provide information)", value: "OTHER" }];
      controller.set("errorLogOptionsArr", errorLogOptionsArr);
      controller.set("currentStoreDlb", null);

      controller.set("ebayManualCheckinSelection", true);

      (0, _icAjax["default"])({
        url: this.get("config.apiURL") + "dashboard",
        type: "get",
        headers: {
          Authorization: "Bearer " + this.get("session.data").authenticated.jwt
        },
        crossDomain: true

        // http://13.237.245.207:8889/v2/consumer/paperless?page=1&perPage=6

      }).then(function (results) {
        controller.set("currentStoreDlb", results.dropLocation.hubbedLocationData.locationCode);
      })["catch"](function (error) {
        if (error && error.jqXHR && error.jqXHR.status === 401) {
          self.get("session").invalidate();
        }
      });

      (0, _icAjax["default"])({
        url: this.get("config.apiURL") + "v2/consumer/paperless?&page=" + controller.get("page") + "&perPage=" + controller.get("perPage"),
        type: "get",
        headers: {
          Authorization: "Bearer " + this.get("session.data").authenticated.jwt
        },
        crossDomain: true
      }).then(function (results) {
        console.log("router", results);
        controller.set("consignments", results);
        controller.set("consignmentsLoading", false);
        controller.set("page", results.page);
        controller.set("totalPages", results.totalPages);
      })["catch"](function (error) {
        if (error && error.jqXHR && error.jqXHR.status === 401) {
          self.get("session").invalidate();
        }
      });

      (0, _icAjax["default"])({
        url: this.get("config.apiURL") + "couriers",
        type: "GET",
        headers: {
          Authorization: "Bearer " + this.get("session.data").authenticated.jwt
        },
        crossDomain: true
      }).then(function (results) {
        controller.set("couriers", results);
      })["catch"](function (error) {
        if (error && error.jqXHR && error.jqXHR.status === 401) {
          self.get("session").invalidate();
        }
      });
    }
  });
});