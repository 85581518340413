define("client/helpers/date-diff", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.datediff = datediff;
	function datediff(params) {
		var articles = params[2];
		var article = articles[0];

		var states = params[1].split(",");
		var search = article.states.filter(function (obj) {
			return states.indexOf(obj.state) > -1;
		});
		var articleDate = 0;
		if (search.length > 0) {
			articleDate = search[0].time;
		}
		var currentDate = params[0];
		var timeDiff = Math.abs(currentDate - articleDate);
		var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
		return diffDays;
	}

	exports["default"] = Ember.Helper.helper(datediff);
});