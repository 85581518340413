define("client/controllers/dashboard", ["exports", "ic-ajax"], function (exports, _icAjax) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = Ember.Controller.extend({
    config: Ember.inject.service(),
    session: Ember.inject.service(),

    dashboard: null,
    dashboardLoading: true,

    consignments: [],
    consignmentsLoading: true,
    queryParams: ["page", "perPage"],
    page: 1,
    perPage: 6,
    totalPages: 1,

    autoReload: (function () {
      var self = this;
      Ember.run.later(self, function () {
        (0, _icAjax["default"])({
          url: self.get("config.apiURL") + "consignments?state=awaitingCustomerPickup,notYetCheckedIn,awaitingCourierPickup&page=1&perPage=" + this.get("perPage") + "&limited=true",
          type: "GET",
          headers: {
            Authorization: "Bearer " + self.get("session.data").authenticated.jwt
          },
          crossDomain: true
        }).then(function (result) {
          self.set("consignments", result.data);
          self.set("page", result.page);
          self.set("totalPages", result.totalPages);
        })["catch"](function (error) {
          if (error && error.jqXHR && error.jqXHR.status === 401) {
            self.get("session").invalidate();
          }
        });
      }, self.get("config.autoRefreshSpeed"));
    }).on("init"),

    actions: {
      getPage: function getPage(page) {
        var self = this;
        self.set("consignmentsLoading", true);
        (0, _icAjax["default"])({
          url: self.get("config.apiURL") + "consignments?state=awaitingCustomerPickup,notYetCheckedIn,awaitingCourierPickup&page=" + page + "&perPage=" + this.get("perPage") + "&limited=true",
          type: "GET",
          headers: {
            Authorization: "Bearer " + self.get("session.data").authenticated.jwt
          },
          crossDomain: true
        }).then(function (result) {
          self.set("consignments", result.data);
          self.set("page", result.page);
          self.set("totalPages", result.totalPages);
          self.set("consignmentsLoading", false);
        })["catch"](function (error) {
          if (error && error.jqXHR && error.jqXHR.status === 401) {
            self.get("session").invalidate();
          }
          self.set("consignmentsLoading", false);
        });
      }
    }
  });
});