define("client/components/version-checker", ["exports", "ic-ajax"], function (exports, _icAjax) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = Ember.Component.extend({
    config: Ember.inject.service(),
    session: Ember.inject.service(),
    versionCheckDelay: 60000, //1 minute
    reloadVersion: false,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.timerVersionCheckerStart();
    },

    timerVersionCheckerStart: function timerVersionCheckerStart() {
      Ember.run.later(this, this.checkVersionTrigger, this.versionCheckDelay);
    },

    checkVersionTrigger: function checkVersionTrigger() {
      var self = this;
      (0, _icAjax["default"])({
        url: self.get("config.apiURL") + "version/client",
        type: "GET",
        crossDomain: true
      }).then(function (result) {
        var resultVersions = result.split(".").map(function (a) {
          return parseInt(a);
        });
        var configVersions = self.get("config.version").split(".").map(function (a) {
          return parseInt(a);
        });
        for (var i = 0; i < resultVersions.length; i++) {
          if (resultVersions[i] > configVersions[i]) {
            self.set("reloadVersion", true);
            break;
          }
        }
        self.timerVersionCheckerStart();
      }, function () {
        self.timerVersionCheckerStart();
      });
    }

  });
});