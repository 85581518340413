define("client/helpers/international-label-url", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.domesticLabelUrl = domesticLabelUrl;
  function domesticLabelUrl(params) {
    var baseUrl = params[0];
    var consignment = params[1];
    var labelType = params[2];
    var token = params[3];
    return baseUrl + "v2/outbound/international/" + consignment + "/" + labelType + "/pdf?token=" + token;
  }

  exports["default"] = Ember.Helper.helper(domesticLabelUrl);
});