define("client/components/slick-slider", ["exports", "ember-cli-slick/components/slick-slider"], function (exports, _slickSlider) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function get() {
      return _slickSlider["default"];
    }
  });
});