define("client/routes/international/book", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (exports, _authenticatedRouteMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports["default"] = Ember.Route.extend(_authenticatedRouteMixin["default"], {
        actions: {
            willTransition: function willTransition() {
                this.controller.send("cancel");
                return true;
            }
        }
    });
});