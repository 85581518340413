define("client/helpers/to-milliseconds", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.toMilliseconds = toMilliseconds;
  function toMilliseconds(params) {
    var time = params[0];
    return parseInt(time) * 1000;
  }

  exports["default"] = Ember.Helper.helper(toMilliseconds);
});