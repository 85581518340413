define("client/components/pin-pad", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = Ember.Component.extend({
    classNames: ["pin-pad"],
    value: null,

    placeholder: Ember.computed("value", "length", function () {
      return Array(this.get("length") - this.get("value.length") + 1).join("_");
    }),

    actions: {
      insert: function insert(char) {
        if (this.get("value").length < this.get("length")) {
          this.set("value", this.get("value") + char);
        }
      },
      "delete": function _delete() {
        this.set("value", this.get("value").slice(0, -1));
      }
    }
  });
});