define("client/routes/paperless/checkin", ["exports", "ic-ajax", "ember-simple-auth/mixins/authenticated-route-mixin"], function (exports, _icAjax, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = Ember.Route.extend(_authenticatedRouteMixin["default"], {
    config: Ember.inject.service("config"),
    session: Ember.inject.service(),

    setupController: function setupController(controller) {
      (0, _icAjax["default"])({
        url: this.get("config.apiURL") + "v2/consumer/paperless?page=1&perPage=" + controller.get("perPage"),
        type: "get",
        headers: {
          Authorization: "Bearer " + this.get("session.data").authenticated.jwt
        },
        crossDomain: true
      }).then(function (results) {
        controller.set("consignments", results.results);
        controller.set("consignmentsLoading", false);
        controller.set("page", results.page);
        controller.set("totalPages", results.totalPages);
      })["catch"](function (error) {
        if (error && error.jqXHR && error.jqXHR.status === 401) {
          // Handle 401 Unauthorized
          controller.get("session").invalidate();
        } else {
          controller.set("consignmentsLoading", false);
          self.controllerFor("application").send("showError", "An error occurred while fetching consignments.");
        }
      });
    }
  });
});