define("client/controllers/consignments/checkout", ["exports", "ic-ajax", "client/helpers/product-helper"], function (exports, _icAjax, _productHelper) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = Ember.Controller.extend({
    config: Ember.inject.service(),
    session: Ember.inject.service(),

    consignments: [],
    consignmentsLoading: true,
    duplicateEbayParcel: false,
    queryParams: ["page", "perPage"],
    page: 1,
    perPage: 6,
    totalPages: 1,
    custumflag: false,
    cardNumber: "",
    logErrorConsignmentDetails: null,
    eBayManualCheckoutDetails: null,
    hubbedManualCheckoutDetails: null,
    enableEbayManualCheckInBool: false,
    showEbayManualBtnBool: false,
    showErrorForInfo: false,
    filterName: "",
    searchedEcpCodeArr: [],
    filterShow: false,
    checkOutWishConsignmentId: 0,

    idTypes: [{
      value: "Driver's Licence"
    }, {
      value: "Passport"
    }, {
      value: "Other"
    }],

    /* for delay seaching while typing */
    events: Ember.A(),

    inputTextObservor: (function () {
      Ember.run.debounce(this, this.searchText, 1000);
    }).observes("filterName"),

    searchText: function searchText() {
      //console.info(this.get('filterName'));
      this.send("getPage", 1);
    },
    /* for delay seaching while typing ENDS */

    autoReload: (function () {
      var self = this;
      Ember.run.later(self, function () {
        var url = self.get("config.apiURL") + "consignments?state=awaitingCustomerPickup,notYetCheckedIn,awaitingCourierPickup&page=" + this.get("page") + "&perPage=" + this.get("perPage") + "&limited=true&orderBy=time_waiting&orderDir=DESC";
        if (this.get("filterName")) {
          url += "&name=" + encodeURIComponent(this.get("filterName"));
        }
        (0, _icAjax["default"])({
          url: url,
          type: "GET",
          headers: {
            Authorization: "Bearer " + self.get("session.data").authenticated.jwt
          },
          crossDomain: true
        }).then(function (results) {
          for (var i = 0; i < results.data.length; i++) {
            var art = results.data[i].articles;
            for (var j = 0; j < art.length; j++) {
              var state = art[j].currentState.state;
              results.data[i].currentState = state;
            }
          }
          self.set("consignments", results.data);
          self.set("page", results.page);
          self.set("totalPages", results.totalPages);
          self.autoReload();
        })["catch"](function (error) {
          if (error && error.jqXHR && error.jqXHR.status === 401) {
            self.get("session").invalidate();
          }
        });
      }, self.get("config.autoRefreshSpeed"));
    }).on("init"),

    currentArticlesAllScanned: (function () {
      var articles = this.get("regularCheckout.articles");
      return articles.filterBy("verified", true).get("length") === articles.get("length");
    }).property("regularCheckout.articles.@each.verified"),

    validateReceiver: function validateReceiver() {
      this.set("checkoutDataErrors.receiver", !this.get("checkoutData.receiver") || this.get("checkoutData.receiver").length === 0);
      this.set("checkoutData.verified", true);

      return !this.get("checkoutDataErrors.receiver");
    },

    validateSignatureOrPin: function validateSignatureOrPin() {
      return this.get("checkoutCommon.signature").length !== 0 || this.get("checkoutCommon.pin").length === 4;
    },

    validEmPod: (function () {
      var articles = this.get("emPodDetails.articles");
      var validArticles = true;
      articles.forEach(function (a) {
        if (a.barcode.length === 0) {
          validArticles = false;
        }
      });
      if (this.get("emPodDetails.courier") === "tnt") {
        var validateBool = this.get("emPodDetails.courier") !== null && this.get("emPodDetails.courier").length > 0 && this.get("emPodDetails.consignmentNumber") !== null && this.get("emPodDetails.consignmentNumber").length > 0;
      } else {
        var validateBool = validArticles && this.get("emPodDetails.courier") !== null && this.get("emPodDetails.courier").length > 0 && this.get("emPodDetails.consignmentNumber") !== null && this.get("emPodDetails.consignmentNumber").length > 0 && this.get("checkoutCommon.receiver") !== null && this.get("checkoutCommon.receiver").length > 0 && this.get("emPodDetails.receiver_address") !== null && this.get("emPodDetails.receiver_address").length > 0 && this.get("emPodDetails.receiver_phone") !== null && this.get("emPodDetails.receiver_phone").length > 0;
      }
      return validateBool;
    }).property("checkoutCommon.receiver", "checkoutCommon.idType", "checkoutCommon.idLast4", "emPodDetails.articles.@each.barcode", "emPodDetails.courier", "emPodDetails.consignmentNumber", "emPodDetails.receiver_address", "emPodDetails.receiver_phone"),

    validEbaySearch: (function () {
      var validateBool = true && this.get("eBayManualCheckoutDetails.firstname") !== null && this.get("eBayManualCheckoutDetails.firstname") !== "" && this.get("eBayManualCheckoutDetails.firstname").length > 0 && this.get("eBayManualCheckoutDetails.lastname") !== null && this.get("eBayManualCheckoutDetails.lastname") !== "" && this.get("eBayManualCheckoutDetails.lastname").length > 0;
      return validateBool;
    }).property("eBayManualCheckoutDetails.firstname", "eBayManualCheckoutDetails.lastname"),

    validEbayManualCheckout: (function () {

      var regexOld = RegExp("eCP:[a-zA-Z0-9]{8}");
      var regex = RegExp("ebay:[a-zA-Z0-9]{7}");

      var ecpCodeStr = this.get("eBayManualCheckoutDetails.ecpCode");

      var validateBool = true && this.get("eBayManualCheckoutDetails.firstname") !== null && this.get("eBayManualCheckoutDetails.firstname") !== "" && this.get("eBayManualCheckoutDetails.firstname").length > 0 && ecpCodeStr !== null && ecpCodeStr !== "" && ecpCodeStr.length > 0 && ecpCodeStr.length === 12 && (regex.test(ecpCodeStr) || regexOld.test(ecpCodeStr)) && this.get("eBayManualCheckoutDetails.lastname") !== null && this.get("eBayManualCheckoutDetails.lastname") !== "" && this.get("eBayManualCheckoutDetails.lastname").length > 0;

      return validateBool;
    }).property("eBayManualCheckoutDetails.firstname", "eBayManualCheckoutDetails.ecpCode", "eBayManualCheckoutDetails.lastname"),

    validHubbedManualCheckout: (function () {
      var regexFullName = /^[a-zA-Z]+(?:\s[a-zA-Z]+)+$/;
      var regexEmail = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
      var fullNameStr = this.get("hubbedManualCheckoutDetails.fullname");
      var emailStr = this.get("hubbedManualCheckoutDetails.email");
      var phoneStr = this.get("hubbedManualCheckoutDetails.phone");
      var validateBool = true && fullNameStr !== null && fullNameStr !== "" && fullNameStr.length > 0 && regexFullName.test(fullNameStr) && this.get("hubbedManualCheckoutDetails.hubbed_id") !== null && this.get("hubbedManualCheckoutDetails.hubbed_id") !== "" && this.get("hubbedManualCheckoutDetails.hubbed_id").length > 0 && this.get("hubbedManualCheckoutDetails.barcode") !== null && this.get("hubbedManualCheckoutDetails.barcode") !== "" && this.get("hubbedManualCheckoutDetails.barcode").length > 0 && this.get("hubbedManualCheckoutDetails.consignment_number") !== null && this.get("hubbedManualCheckoutDetails.consignment_number") !== "" && this.get("hubbedManualCheckoutDetails.consignment_number").length > 0 && emailStr !== null && emailStr !== "" && emailStr.length > 0 && regexEmail.test(emailStr) && !isNaN(phoneStr);
      return validateBool;
    }).property("hubbedManualCheckoutDetails.fullname", "hubbedManualCheckoutDetails.hubbed_id", "hubbedManualCheckoutDetails.barcode", "hubbedManualCheckoutDetails.consignment_number", "hubbedManualCheckoutDetails.phone", "hubbedManualCheckoutDetails.email"),

    couriers: [],

    checkoutWorkflow: null,
    checkoutCommon: {
      receiver: "",
      idType: "",
      idLast4: "",
      signature: "",
      pin: ""
    },
    manualCheckout: {
      reason: "",
      consignment: null
    },

    actions: {
      refreshAuthToken: function refreshAuthToken(methodToCall) {
        var self = this;
        (0, _icAjax["default"])({
          url: self.get("config.apiURL") + "v2/admin/auth/validateToken",
          type: "GET",
          headers: {
            Authorization: "Bearer " + self.get("session.data").authenticated.jwt
          },
          contentType: "application/json",
          crossDomain: true
        }).then(function (response) {
          var responseData = JSON.parse(response);
          self.get("session").set("data.authenticated.dotnet_auth", responseData.responseMessage);
          self.send(methodToCall);
        }, function (error) {
          self.controllerFor("application").send("showError", "Error while submitting your request");
          self.controllerFor("application").send("hideSpinner", 850);
          self.set("logErrorConsignmentDetails", null);
          self.set("showErrorForInfo", false);
          self.set("showModal", null);
        });
      },
      openLogErrorModal: function openLogErrorModal(consignmentDetails) {
        this.set("showErrorForInfo", false);
        this.set("logErrorConsignmentDetails", {
          event_id: 5,
          ecpCode: null,
          error: "",
          reason: "",
          consignment_id: null,
          consignmentNumber: consignmentDetails.consignmentNumber,
          carrier: consignmentDetails.courierName,
          productType: (0, _productHelper.productHelper)([consignmentDetails.productType])
        });

        if (consignmentDetails && consignmentDetails.consignmentNumber) {
          this.set("logErrorConsignmentDetails.consignment_id", consignmentDetails.id);
        }
        if (consignmentDetails.ebayData && consignmentDetails.ebayData.ecpNumber) {
          this.set("logErrorConsignmentDetails.ecpCode", consignmentDetails.ebayData.ecpNumber);
        }
        this.set("showModal", "logErrorModal");
      },
      logParcelError: function logParcelError() {
        var _this = this;

        this.set("showErrorForInfo", false);
        if (this.get("logErrorConsignmentDetails.error") !== "") {
          if (this.get("logErrorConsignmentDetails.error") === "OTHER" && this.get("logErrorConsignmentDetails.reason") === "") {
            this.set("showErrorForInfo", true);
          } else {
            var self = this;
            self.controllerFor("application").send("showSpinner");
            var postData = {
              eventId: 5,
              ecpCode: this.get("logErrorConsignmentDetails.ecpCode"),
              consignmentId: this.get("logErrorConsignmentDetails.consignment_id"),
              storeDlb: this.get("currentStoreDlb"),
              storeName: this.get("session.data").authenticated.name,
              consignmentNumber: this.get("logErrorConsignmentDetails.consignmentNumber"),
              carrier: this.get("logErrorConsignmentDetails.carrier"),
              productType: this.get("logErrorConsignmentDetails.productType")
            };

            if (this.get("logErrorConsignmentDetails.error") === "OTHER" && this.get("logErrorConsignmentDetails.reason") !== "") {
              postData.notificationText = this.get("logErrorConsignmentDetails.reason");
            } else {
              this.get("errorLogOptionsArr").forEach(function (o) {
                if (o.value === _this.get("logErrorConsignmentDetails.error")) {
                  postData.notificationText = o.name;
                }
              });
            }

            if (this.get("logErrorConsignmentDetails.error") === "DAMAGED_D") {
              postData.notificationType = "DAMAGED";
            } else {
              postData.notificationType = this.get("logErrorConsignmentDetails.error");
            }
            (0, _icAjax["default"])({
              url: self.get("config.ebayApiURL") + "reportError",
              type: "POST",
              headers: {
                Authorization: "Bearer " + self.get("session.data").authenticated.dotnet_auth
              },
              data: JSON.stringify(postData),
              contentType: "application/json",
              crossDomain: true
            }).then(function () /*result*/{
              self.set("logErrorConsignmentDetails", null);
              self.set("showErrorForInfo", false);
              self.set("showModal", null);
              self.controllerFor("application").send("showSuccessInfo", "Logged Error Successfully!");
              self.controllerFor("application").send("hideSpinner", 550);
            }, function (error) {
              if (error.jqXHR.status === 401) {
                self.send("refreshAuthToken", "logParcelError");
                self.controllerFor("application").send("hideSpinner", 1500);
              } else {
                self.controllerFor("application").send("showError", "Error while submitting your request");
                self.controllerFor("application").send("hideSpinner", 850);
                self.set("logErrorConsignmentDetails", null);
                self.set("showErrorForInfo", false);
                self.set("showModal", null);
              }
            });
          }
        } else {
          this.set("showPin", true);
          this.set("showEmPod", false);
        }
      },
      closeLogErrorModal: function closeLogErrorModal() {
        this.set("showErrorForInfo", false);
        this.set("logErrorConsignmentDetails", null);
        this.set("showModal", null);
      },
      openMoreInformationModal: function openMoreInformationModal() {
        this.set("showModal", "moreInformationModal");
      },
      eBayManualSearch: function eBayManualSearch() {
        var self = this;
        var trackingNumberObject = "";
        if (this.get("eBayManualCheckoutDetails.trackingnumber")) {
          trackingNumberObject = "&trackingNumber=" + this.get("eBayManualCheckoutDetails.trackingnumber");
        }

        self.controllerFor("application").send("showSpinner");
        var url = self.get("config.ebayApiURL") + "findEcps?storeId=" + this.get("currentStoreDlb") + "&storeName=" + self.get("session.data").authenticated.name + "&buyerFirstName=" + this.get("eBayManualCheckoutDetails.firstname") + "&buyerLastName=" + this.get("eBayManualCheckoutDetails.lastname") + trackingNumberObject;

        self.set("enableEbayManualCheckInBool", false);
        (0, _icAjax["default"])({
          url: url,
          type: "get",
          headers: {
            Authorization: "Bearer " + self.get("session.data").authenticated.dotnet_auth
          },
          crossDomain: true
        }).then(function (responseData) {
          var result = responseData.result;
          if (result && result.buyerPickups && result.buyerPickups.length > 0) {
            var responseDataArr = [];
            for (var m = 0; m < result.buyerPickups.length; m++) {
              responseDataArr.push(result.buyerPickups[m].ecpCode);
            }
            self.set("searchedEcpCodeArr", responseDataArr);

            self.set("autoPopulateBool", false);
            self.set("eBayManualCheckoutDetails", {
              firstname: "",
              lastname: "",
              ecpCode: ""
            });
            self.set("showModal", null);
            self.controllerFor("application").send("hideSpinner", 850);
            self.set("filterName", "");
            self.send("getPage", 1);
          } else {
            self.set("showEbayManualBtnBool", true);
            self.controllerFor("application").send("showError", "No ecp found. Notification has been sent to HUBBED/ebay customer support");
            self.controllerFor("application").send("hideSpinner", 3000);
          }
        }, function (error) {
          if (error.jqXHR.status === 401) {
            self.send("refreshAuthToken", "eBayManualSearch");
            self.controllerFor("application").send("hideSpinner", 1500);
          } else {
            self.set("showEbayManualBtnBool", true);
            self.controllerFor("application").send("showError", "No ecp found. Notification has been sent to HUBBED/ebay customer support");
            self.controllerFor("application").send("hideSpinner", 3000);
          }
        });
      },
      enableEbayManualCheckin: function enableEbayManualCheckin() {
        this.set("showEbayManualBtnBool", false);
        this.set("enableEbayManualCheckInBool", true);
      },

      hubbedManualCheckout: function hubbedManualCheckout() {
        var self = this;

        self.controllerFor("application").send("showSpinner");
        (0, _icAjax["default"])({
          url: self.get("config.apiURL") + "v2/cnc/consignments/manualCheckin",
          type: "POST",
          headers: {
            Authorization: "Bearer " + self.get("session.data").authenticated.jwt
          },
          data: JSON.stringify({
            fullName: this.get("hubbedManualCheckoutDetails.fullname"),
            hubbedId: this.get("hubbedManualCheckoutDetails.hubbed_id"),
            barcode: this.get("hubbedManualCheckoutDetails.barcode"),
            consignmentNumber: this.get("hubbedManualCheckoutDetails.consignment_number"),
            phoneNumber: this.get("hubbedManualCheckoutDetails.phone"),
            email: this.get("hubbedManualCheckoutDetails.email")
          }),
          contentType: "application/json",
          crossDomain: true
        }).then(function (result) {
          self.set("filterName", self.get("hubbedManualCheckoutDetails.hubbed_id"));
          self.send("getPage", 1);
          self.set("autoPopulateBool", false);
          self.set("hubbedManualCheckoutDetails", null);
          self.set("showModal", null);
          self.controllerFor("application").send("showSuccessInfo", "Successfully saved the Hubbed Manual CheckIn data in system!");
          self.controllerFor("application").send("hideSpinner", 2000);
        }, function (error) {
          if (error && error.jqXHR && error.jqXHR.status === 401) {
            // Logout the user if a 401 response is received
            self.get("session").invalidate();
          }

          var jqXHR = error.jqXHR;
          self.set("filterName", "");
          self.send("getPage", 1);
          self.set("logErrorConsignmentDetails", null);
          self.set("showErrorForInfo", false);
          self.set("showModal", null);
          if (jqXHR.responseText !== null) {
            self.controllerFor("application").send("showError", jqXHR.responseText);
          } else {
            self.controllerFor("application").send("showError", "Error while submitting your request");
          }
          self.controllerFor("application").send("hideSpinner", 3000);
        });
      },
      eBayManualCheckout: function eBayManualCheckout() {
        var self = this;
        self.controllerFor("application").send("showSpinner");
        (0, _icAjax["default"])({
          url: self.get("config.apiURL") + "v2/ebay/consignments/manualCheckin",
          type: "POST",
          headers: {
            Authorization: "Bearer " + self.get("session.data").authenticated.jwt
          },
          data: JSON.stringify({
            firstName: this.get("eBayManualCheckoutDetails.firstname"),
            lastName: this.get("eBayManualCheckoutDetails.lastname"),
            ecpCode: this.get("eBayManualCheckoutDetails.ecpCode"),
            trackingNumber: this.get("eBayManualCheckoutDetails.trackingnumber")
          }),
          contentType: "application/json",
          crossDomain: true
        }).then(function (result) {
          self.set("filterName", self.get("eBayManualCheckoutDetails.ecpCode"));
          self.send("getPage", 1);
          self.set("autoPopulateBool", false);
          self.set("eBayManualCheckoutDetails", null);
          self.set("showModal", null);
          self.controllerFor("application").send("showSuccessInfo", "Successfully saved the Ebay Manual CheckIn data in system!");
          self.controllerFor("application").send("hideSpinner", 2000);
        }, function (error) {
          if (error && error.jqXHR && error.jqXHR.status === 401) {
            self.get("session").invalidate();
          }

          var jqXHR = error.jqXHR;
          self.set("filterName", "");
          self.send("getPage", 1);
          self.set("logErrorConsignmentDetails", null);
          self.set("showErrorForInfo", false);
          self.set("showModal", null);
          if (jqXHR.responseText !== null) {
            self.controllerFor("application").send("showError", jqXHR.responseText);
          } else {
            self.controllerFor("application").send("showError", "Error while submitting your request");
          }
          self.controllerFor("application").send("hideSpinner", 3000);
        });
      },
      closeMoreInformationModal: function closeMoreInformationModal() {
        this.set("showModal", null);
      },
      openEbayManualCheckoutModal: function openEbayManualCheckoutModal(autoPopulateVar) {

        this.set("ebayManualCheckinSelection", true);
        this.set("showEbayManualBtnBool", false);
        this.set("enableEbayManualCheckInBool", false);
        this.set("autoPopulateBool", false);
        if (autoPopulateVar) {
          this.set("autoPopulateBool", autoPopulateVar);
        }

        if (this.get("consignments") && this.get("consignments").length > 0 && this.get("duplicateEbayParcelDetails")) {
          var firstname = this.get("duplicateEbayParcelDetails.firstname");
          var lastname = this.get("duplicateEbayParcelDetails.lastname");

          this.set("eBayManualCheckoutDetails", {
            firstname: firstname,
            lastname: lastname,
            ecpCode: this.get("duplicateEbayParcelDetails.ecpCode")
          });
        } else {
          this.set("eBayManualCheckoutDetails", {
            firstname: "",
            lastname: "",
            ecpCode: ""
          });
        }
        this.set("hubbedManualCheckoutDetails", {
          fullname: "",
          hubbed_id: "",
          consignment_number: "",
          barcode: "",
          phone: "",
          email: ""
        });

        this.set("showModal", "eBayManualCheckoutModal");
      },
      switchManualCheckin: function switchManualCheckin() {
        this.set("ebayManualCheckinSelection", !this.get("ebayManualCheckinSelection"));
      },
      closeEbayManualCheckoutModal: function closeEbayManualCheckoutModal() {
        this.set("autoPopulateBool", false);
        this.set("enableEbayManualCheckInBool", false);
        this.set("ebayManualCheckinSelection", true);
        this.set("eBayManualCheckoutDetails", {
          firstname: "",
          lastname: "",
          ecpCode: ""
        });
        this.set("hubbedManualCheckoutDetails", {
          fullname: "",
          hubbed_id: "",
          consignment_number: "",
          barcode: "",
          phone: "",
          email: ""
        });
        this.set("showModal", null);
      },
      showFilter: function showFilter() {
        this.set("filterShow", !this.get("filterShow"));

        if (this.get("filterName")) {
          this.set("filterName", "");
          this.send("getPage", 1);
        } else {
          this.set("searchedEcpCodeArr", []);
          this.send("getPage", 1);
        }
      },

      filter: function filter() {
        this.send("getPage", 1);
      },

      getPage: function getPage(page) {
        var self = this;
        self.set("consignmentsLoading", true);
        self.set("duplicateEbayParcel", false);
        self.set("duplicateEbayParcelDetails", null);
        var url = self.get("config.apiURL") + "consignments?state=awaitingCustomerPickup,notYetCheckedIn,awaitingCourierPickup&page=" + page + "&perPage=" + this.get("perPage") + "&limited=true&orderBy=time_waiting&orderDir=DESC";
        if (this.get("filterName")) {
          url += "&name=" + encodeURIComponent(this.get("filterName"));
          //url += "&filter=" + this.get('filterName');
        } else if (self.get("searchedEcpCodeArr") && self.get("searchedEcpCodeArr").length > 0) {
          url += "&ecpCodes=" + encodeURIComponent(self.get("searchedEcpCodeArr").toString());
        }
        (0, _icAjax["default"])({
          url: url,
          type: "get",
          headers: {
            Authorization: "Bearer " + self.get("session.data").authenticated.jwt
          },
          crossDomain: true
        }).then(function (result) {
          if (result && result.data) {
            for (var i = 0; i < result.data.length; i++) {
              if (self.get("filterName") && result.data[i].ebayData && result.data[i].ebayData.ecpNumber) {
                self.set("duplicateEbayParcel", true);
                self.set("duplicateEbayParcelDetails", {
                  firstname: result.data[i].ebayData.buyerFirstName,
                  lastname: result.data[i].ebayData.buyerLastName,
                  ecpCode: result.data[i].ebayData.ecpNumber
                });
                var articles = result.data[i].articles;
                var states = articles.map(function (a) {
                  return a.currentState.state;
                });
                if (states.indexOf("notYetCheckedIn") > -1) {
                  self.set("duplicateEbayParcel", false);
                  break;
                }
              }
            }
          }

          for (var i = 0; i < result.data.length; i++) {
            var art = result.data[i].articles;
            for (var j = 0; j < art.length; j++) {
              var state = art[j].currentState.state;
              result.data[i].currentState = state;
            }
          }
          self.set("consignments", result.data);

          self.set("page", result.page);
          self.set("totalPages", result.totalPages);
          self.set("consignmentsLoading", false);
        })["catch"](function (error) {
          if (error && error.jqXHR && error.jqXHR.status === 401) {
            self.get("session").invalidate();
          }
          self.set("consignmentsLoading", false);
        });
      },

      //EMPod
      onFullManualCheckout: function onFullManualCheckout() {
        this.set("checkoutWorkflow", "emPod");
        this.set("showEmPod", true);
        this.set("checkoutCommon", {
          receiver: "",
          idType: "",
          idLast4: "",
          signature: "",
          pin: ""
        });
        this.set("emPodDetails", {
          courier: null,
          consignmentNumber: null,
          articles: [{
            barcode: ""
          }],
          receiver_address: "",
          receiver_phone: null
        });
      },

      onCloseEmPod: function onCloseEmPod() {
        this.set("showEmPod", false);
        this.set("checkoutWorkflow", null);
      },

      onEmPodAddArticle: function onEmPodAddArticle() {
        this.get("emPodDetails").articles.pushObject({
          barcode: ""
        });
      },

      onEmPodRemoveArticle: function onEmPodRemoveArticle(index) {
        var articles = JSON.parse(JSON.stringify(this.get("emPodDetails").articles));
        articles.splice(index, 1);
        this.set("emPodDetails.articles", articles);
      },

      //Manual override
      onManualCheckout: function onManualCheckout(consginmentId) {
        var self = this;
        var phoneNumber = this.get("phone");
        self.controllerFor("application").send("showSpinner");
        (0, _icAjax["default"])({
          url: self.get("config.apiURL") + "consignments/" + consginmentId,
          type: "get",
          headers: {
            Authorization: "Bearer " + self.get("session.data").authenticated.jwt
          },
          crossDomain: true
        }).then(function (consignment) {
          console.log(consignment, "Consignment");
          var receiverNameStr = "";
          if (consignment && consignment.receiver && consignment.receiver.name && consignment.receiver.name !== "") {
            receiverNameStr = consignment.receiver.name;
          }
          if (consignment && consignment.receiver && consignment.receiver.name == "") {
            self.set("custumflag", true);
          } else {
            self.set("custumflag", false);
          }
          self.set("checkoutWorkflow", "manual");
          self.set("manualCheckout", {
            reason: "",
            consignment: consignment
          });
          self.set("findProductId", consignment.productType);
          self.set("checkoutCommon", {
            receiver: receiverNameStr,
            idType: "Other",
            idLast4: "9999",
            signature: "",
            pin: "",
            phone: phoneNumber
          });
          self.set("showManualReason", true);
          self.controllerFor("application").send("hideSpinner");
        }, function () /*error*/{
          self.controllerFor("application").send("showError");
          self.controllerFor("application").send("hideSpinner", 1500);
        });
      },

      onManualCheckoutBarcodeWise: function onManualCheckoutBarcodeWise(consginmentId, articleNumber) {
        var self = this;
        var phoneNumber = this.get("phone");
        self.controllerFor("application").send("showSpinner");
        (0, _icAjax["default"])({
          url: self.get("config.apiURL") + "consignments/" + consginmentId,
          type: "get",
          headers: {
            Authorization: "Bearer " + self.get("session.data").authenticated.jwt
          },
          crossDomain: true
        }).then(function (consignment) {
          // Filter the article array based on the provided articleNumber
          if (consignment && consignment.articles && consignment.articles.length > 0 && articleNumber) {
            consignment.articles = consignment.articles.filter(function (article) {
              return article.barcode === articleNumber;
            });
          }

          var receiverNameStr = "";
          if (consignment && consignment.receiver && consignment.receiver.name && consignment.receiver.name !== "") {
            receiverNameStr = consignment.receiver.name;
          }
          if (consignment && consignment.receiver && consignment.receiver.name == "") {
            self.set("custumflag", true);
          } else {
            self.set("custumflag", false);
          }
          self.set("checkoutWorkflow", "manual");
          self.set("manualCheckout", {
            reason: "",
            consignment: consignment
          });
          self.set("findProductId", consignment.productType);
          self.set("checkoutCommon", {
            receiver: receiverNameStr,
            idType: "Other",
            idLast4: "9999",
            signature: "",
            pin: "",
            phone: phoneNumber
          });
          self.set("showManualReason", true);
          self.controllerFor("application").send("hideSpinner");
        }, function () /*error*/{
          self.controllerFor("application").send("showError");
          self.controllerFor("application").send("hideSpinner", 1500);
        });
      },

      onCloseManualReason: function onCloseManualReason() {
        this.set("checkoutWorkflow", null);
        this.set("showManualReason", false);
      },

      //Regular Checkout
      onCheckoutSearch: function onCheckoutSearch() {
        var cardNumber = this.get("cardNumber");
        if (cardNumber.length > 0) {
          var self = this;
          self.controllerFor("application").send("showSpinner");
          (0, _icAjax["default"])({
            url: self.get("config.apiURL") + "consignments/cardNumberPost",
            type: "post",
            headers: {
              Authorization: "Bearer " + self.get("session.data").authenticated.jwt
            },
            crossDomain: true,
            data: cardNumber
          }).then(function (consignment) {
            self.set("checkoutCommon", {
              receiver: consignment.receiver.name,
              idType: "",
              idLast4: "",
              signature: "",
              pin: ""
            });
            self.set("regularCheckout", {
              consignment: consignment,
              articles: consignment.articles.map(function (article) {
                return {
                  barcode: article.barcode,
                  verified: false
                };
              })
            });
            self.controllerFor("application").send("hideSpinner");
            self.set("checkoutWorkflow", "normal");
            self.set("showReceiver", true);
          }, function () /*error*/{
            self.controllerFor("application").send("showError", "Reference Number Not Found!");
            self.controllerFor("application").send("hideSpinner", 1500);
          });
        }
      },

      onCloseReceiver: function onCloseReceiver() {
        this.set("showReceiver", false);
        self.set("receiverFormCourier", null);
      },

      onRequestArticles: function onRequestArticles() {
        this.set("showArticles", true);
        this.set("showReceiver", false);
      },

      onCloseArticles: function onCloseArticles() {
        this.set("showArticles", false);
      },

      onWishCheckout: function onWishCheckout(consignmentId) {
        this.set("showWishDiscardPopup", true);
        this.set("checkOutWishConsignmentId", consignmentId);
      },

      onCloseWish: function onCloseWish() {
        this.set("showWishDiscardPopup", false);
      },

      onDiscardWishbutton: function onDiscardWishbutton() {
        var self = this;
        var ConsignmnetNum = this.get("checkOutWishConsignmentId");
        var data = { consignments: [ConsignmnetNum] };
        (0, _icAjax["default"])({
          url: this.get("config.apiURL") + "consignments/discard",
          type: "POST",
          headers: {
            Authorization: "Bearer " + this.get("session.data").authenticated.jwt
          },
          data: JSON.stringify(data),
          contentType: "application/json",
          crossDomain: true
        }).then(function (result) {
          if (result.consignments.length > 0 && result.consignments[0].success == true) {
            self.send("onCloseWish");
            self.send("reload");
          } else {
            self.controllerFor("application").send("showSpinner");
            self.controllerFor("application").send("hideSpinner", 1500);
            self.controllerFor("application").send("showError", result.consignments[0].error);
            self.send("onCloseWish");
          }
        });
      },

      onVerifyArticle: function onVerifyArticle() {
        if (this.get("verifyArticle") && this.get("verifyArticle").length > 0) {
          this.set("verifyArticleErrorMsg", this.get("verifyArticle"));
          for (var i = 0; i < this.get("regularCheckout.articles").length; i += 1) {
            if (this.get("regularCheckout.articles")[i].barcode.toUpperCase() === this.get("verifyArticle").toUpperCase() || "J" + this.get("regularCheckout.articles")[i].barcode.toUpperCase() === this.get("verifyArticle").toUpperCase()) {
              this.set("regularCheckout.articles." + i + ".verified", true);
              this.set("verifyArticleErrorMsg", null);
            }
          }
          this.set("verifyArticle", null);
        }
      },

      //Common Checkout Parts
      onRequestPin: function onRequestPin(consignmentId) {
        var couponFlag = this.get("custumflag");
        this.set("showManualReason", false);
        this.set("showArticles", false);
        var phoneNo = this.get("checkoutCommon.phone");
        if (this.get("checkoutWorkflow") === "emPod" && this.get("emPodDetails.receiver_phone") && this.get("emPodDetails.receiver_phone").length > 0) {
          this.set("emPodDetails.pin_phone", this.get("emPodDetails.receiver_phone"));
          var self = this;
          self.controllerFor("application").send("showSpinner");
          (0, _icAjax["default"])({
            url: self.get("config.apiURL") + "v2/consignments/manualPinSend",
            type: "post",
            headers: {
              Authorization: "Bearer " + self.get("session.data").authenticated.jwt
            },
            data: JSON.stringify({
              phone: self.get("emPodDetails.pin_phone"),
              courier: self.get("emPodDetails.courier")
            }),
            contentType: "application/json",
            crossDomain: true
          }).then(function () {
            self.controllerFor("application").send("hideSpinner", 0);
            self.set("showPin", true);
            self.set("showEmPod", false);
          }, function () {
            self.controllerFor("application").send("showError", "Error");
            self.controllerFor("application").send("hideSpinner", 850);
          });
        } else if (couponFlag) {
          var self = this;
          self.controllerFor("application").send("showSpinner");
          (0, _icAjax["default"])({
            url: self.get("config.apiURL") + "consignments/" + consignmentId + "/resendPin",
            type: "post",
            headers: {
              Authorization: "Bearer " + self.get("session.data").authenticated.jwt
            },
            data: JSON.stringify({
              mobile: phoneNo
            }),
            contentType: "application/json",
            crossDomain: true
          }).then(function () {
            self.controllerFor("application").send("hideSpinner", 0);
            self.set("showPin", true);
            self.set("showEmPod", false);
          }, function () {
            self.controllerFor("application").send("showError", "Invalid Phone number");
            self.controllerFor("application").send("hideSpinner", 850);
            self.set("showManualReason", true);
          });
        } else {
          this.set("showPin", true);
          this.set("showEmPod", false);
        }
      },

      onClosePin: function onClosePin() {
        this.set("showPin", false);
        this.set("checkoutWorkflow", null);
      },

      onConfirmPin: function onConfirmPin() {
        if (this.validateSignatureOrPin()) {
          var self = this;
          if (this.get("checkoutWorkflow") === "emPod") {
            self.controllerFor("application").send("showSpinner");
            (0, _icAjax["default"])({
              url: self.get("config.apiURL") + "v2/consignments/manualPinValidate",
              type: "post",
              headers: {
                Authorization: "Bearer " + self.get("session.data").authenticated.jwt
              },
              data: JSON.stringify({
                phone: self.get("emPodDetails.pin_phone"),
                pin: self.get("checkoutCommon.pin")
              }),
              contentType: "application/json",
              crossDomain: true
            }).then(function () /*result*/{
              self.controllerFor("application").send("hideSpinner", 0);
              self.set("showPin", false);
              self.set("showCheckoutConfirmation", true);
            }, function () {
              self.controllerFor("application").send("showError", "Error");
              self.controllerFor("application").send("hideSpinner", 850);
            });
          } else {
            self.controllerFor("application").send("showSpinner");
            var consignmentId = this.get("checkoutWorkflow") === "manual" ? self.get("manualCheckout.consignment.id") : self.get("regularCheckout.consignment.id");
            (0, _icAjax["default"])({
              url: self.get("config.apiURL") + "consignments/" + consignmentId + "/validatePin",
              type: "post",
              headers: {
                Authorization: "Bearer " + self.get("session.data").authenticated.jwt
              },
              data: JSON.stringify({
                pin: self.get("checkoutCommon.pin")
              }),
              contentType: "application/json",
              crossDomain: true
            }).then(function () /*result*/{
              self.controllerFor("application").send("hideSpinner", 0);
              self.set("showPin", false);
              self.set("showCheckoutConfirmation", true);
            }, function () {
              self.controllerFor("application").send("showError", "Invalid Pin");
              self.controllerFor("application").send("hideSpinner", 850);
            });
          }
        }
      },

      onRequestSignature: function onRequestSignature() {
        this.set("showEmPod", false);
        this.set("showSignature", true);
        this.set("showPin", false);
      },

      onClearSignature: function onClearSignature() {
        this.set("checkoutCommon.signature", "");
      },

      onConfirmSignature: function onConfirmSignature() {
        if (this.validateSignatureOrPin()) {
          this.set("showSignature", false);
          this.set("showCheckoutConfirmation", true);
        }
      },

      onPinResend: function onPinResend() {
        this.set("showPinResend", true);
        if (this.get("checkoutWorkflow") === "emPod" && this.get("emPodDetails.receiver_phone") && this.get("emPodDetails.receiver_phone").length > 0) {
          this.set("resendMobile", this.get("emPodDetails.receiver_phone"));
        }
        this.set("showPin", false);
      },

      onCloseSignature: function onCloseSignature() {
        this.set("showSignature", false);
        this.set("checkoutWorkflow", null);
      },

      onClosePinResend: function onClosePinResend() {
        this.set("showPinResend", false);
        this.set("checkoutWorkflow", null);
      },

      onRequestResendPin: function onRequestResendPin() {
        if (this.get("resendMobile").length > 8) {
          var self = this;
          if (this.get("checkoutWorkflow") === "emPod") {
            this.set("emPodDetails.pin_phone", this.get("resendMobile"));
            self.controllerFor("application").send("showSpinner");
            (0, _icAjax["default"])({
              url: self.get("config.apiURL") + "v2/consignments/manualPinSend",
              type: "post",
              headers: {
                Authorization: "Bearer " + self.get("session.data").authenticated.jwt
              },
              data: JSON.stringify({
                phone: self.get("emPodDetails.pin_phone"),
                courier: self.get("emPodDetails.courier")
              }),
              contentType: "application/json",
              crossDomain: true
            }).then(function () /*result*/{
              self.controllerFor("application").send("hideSpinner", 0);
              self.set("showPin", true);
              self.set("showPinResend", false);
            }, function () {
              self.controllerFor("application").send("showError", "Error");
              self.controllerFor("application").send("hideSpinner", 850);
            });
          } else {
            self.controllerFor("application").send("showSpinner");
            var consignmentId = this.get("checkoutWorkflow") === "manual" ? self.get("manualCheckout.consignment.id") : self.get("regularCheckout.consignment.id");
            (0, _icAjax["default"])({
              url: self.get("config.apiURL") + "consignments/" + consignmentId + "/resendPin",
              type: "post",
              headers: {
                Authorization: "Bearer " + self.get("session.data").authenticated.jwt
              },
              data: JSON.stringify({
                mobile: self.get("resendMobile")
              }),
              contentType: "application/json",
              crossDomain: true
            }).then(function () /*result*/{
              self.controllerFor("application").send("showSuccess", "Pin Resent");
              self.controllerFor("application").send("hideSpinner", 550);
              self.set("showPinResend", false);
              self.set("showPin", true);
            }, function () {
              self.controllerFor("application").send("showError", "Unknown Error");
              self.controllerFor("application").send("hideSpinner", 850);
            });
          }
        }
      },
      checkInTNTParcel: function checkInTNTParcel() {
        this.set("showManualReason", false);
        this.set("showArticles", false);

        if (this.get("emPodDetails.consignmentNumber") && this.get("emPodDetails.consignmentNumber").length > 0) {
          var self = this;
          self.controllerFor("application").send("showSpinner");
          (0, _icAjax["default"])({
            url: self.get("config.apiURL") + "v2/consignments/manualCheckin",
            type: "post",
            headers: {
              Authorization: "Bearer " + self.get("session.data").authenticated.jwt
            },
            data: JSON.stringify({
              consignmentDetails: {
                consignmentNumber: this.get("emPodDetails.consignmentNumber"),
                courier: this.get("emPodDetails.courier"),
                articles: [],
                receiverName: this.get("checkoutCommon.receiver"),
                receiverAddress: this.get("emPodDetails.receiver_address"),
                receiverPhone: null,
                receiverEmail: null
              }
            }),
            contentType: "application/json",
            crossDomain: true
          }).then(function () /*result*/{
            self.controllerFor("application").send("showSuccess");
            self.controllerFor("application").send("hideSpinner", 850);
            self.set("showEmPod", false);
            self.send("reload");
          }, function () {
            self.controllerFor("application").send("showError", "Error");
            self.controllerFor("application").send("hideSpinner", 850);
          });
        } else {
          this.set("showEmPod", false);
        }
      },

      onCheckoutBarcodeWise: function onCheckoutBarcodeWise(manualCheckout) {

        var self = this;

        if (this.get("checkoutWorkflow") === "manual" || this.get("checkoutWorkflow") === "normal") {
          var consignmentId = this.get("checkoutWorkflow") === "manual" ? this.get("manualCheckout.consignment.id") : this.get("regularCheckout.consignment.id");
          self.controllerFor("application").send("showSpinner");

          var articleRequests = manualCheckout.consignment.articles.map(function (article) {
            return {
              barcode: article.barcode,
              id: article.id
            };
          });

          articleRequests.forEach(function (articleData) {
            var data = self.get("checkoutWorkflow") === "manual" ? {
              pin: self.get("checkoutCommon.pin").length > 0 ? self.get("checkoutCommon.pin") : null,
              receiver: self.get("checkoutCommon.receiver"),
              identityType: self.get("checkoutCommon.idType"),
              identityLast4: self.get("checkoutCommon.idLast4"),
              reason: self.get("manualCheckout.reason"),
              phone: self.get("checkoutCommon.phone"),
              articles: [{
                barcode: articleData.barcode
              }],
              signature: self.get("checkoutCommon.signature").length > 0 ? self.get("checkoutCommon.signature") : null
            } : {
              receiver: self.get("checkoutCommon.receiver"),
              identityType: self.get("checkoutCommon.idType"),
              identityLast4: self.get("checkoutCommon.idLast4"),
              pin: self.get("checkoutCommon.pin").length > 0 ? self.get("checkoutCommon.pin") : null,
              articles: [{
                barcode: articleData.barcode
              }],
              signature: self.get("checkoutCommon.signature").length > 0 ? self.get("checkoutCommon.signature") : null
            };
            (0, _icAjax["default"])({
              url: self.get("config.apiURL") + "consignments/" + consignmentId + "/checkout/" + articleData.id,
              type: "post",
              headers: {
                Authorization: "Bearer " + self.get("session.data").authenticated.jwt
              },
              data: JSON.stringify(data),
              contentType: "application/json",
              crossDomain: true
            }).then(function () /*result*/{
              self.controllerFor("application").send("showSuccess");
              self.controllerFor("application").send("hideSpinner", 850);
              self.set("showCheckoutConfirmation", false);
              self.send("reload");
            }, function () /*error*/{
              self.controllerFor("application").send("showError");
              self.controllerFor("application").send("hideSpinner", 850);
            });
          });
        } else if (this.get("checkoutWorkflow") === "emPod") {
          self.controllerFor("application").send("showSpinner");
          (0, _icAjax["default"])({
            url: self.get("config.apiURL") + "v2/consignments/manualCheckout",
            type: "post",
            headers: {
              Authorization: "Bearer " + self.get("session.data").authenticated.jwt
            },
            data: JSON.stringify({
              consignmentDetails: {
                consignmentNumber: this.get("emPodDetails.consignmentNumber"),
                courier: this.get("emPodDetails.courier"),
                articles: this.get("emPodDetails.articles").map(function (a) {
                  return [a.barcode];
                }),
                receiverName: this.get("checkoutCommon.receiver"),
                receiverAddress: this.get("emPodDetails.receiver_address"),
                receiverPhone: this.get("emPodDetails.pin_phone"),
                receiverEmail: null
              },
              checkoutDetails: {
                receiver: this.get("checkoutCommon.receiver"),
                identityType: "Other",
                identityLast4: "9999",
                signature: this.get("checkoutCommon.signature").length > 0 ? this.get("checkoutCommon.signature") : null,
                pin: this.get("checkoutCommon.pin").length > 0 ? this.get("checkoutCommon.pin") : null
              }
            }),
            contentType: "application/json",
            crossDomain: true
          }).then(function () /*result*/{
            self.controllerFor("application").send("showSuccess");
            self.controllerFor("application").send("hideSpinner", 850);
            self.set("showCheckoutConfirmation", false);
            self.send("reload");
          }, function () /*error*/{
            self.controllerFor("application").send("showError");
            self.controllerFor("application").send("hideSpinner", 850);
          });
        }
      },

      onCheckout: function onCheckout() {
        var self = this;
        if (this.get("checkoutWorkflow") === "manual" || this.get("checkoutWorkflow") === "normal") {
          var consignmentId = this.get("checkoutWorkflow") === "manual" ? this.get("manualCheckout.consignment.id") : this.get("regularCheckout.consignment.id");
          self.controllerFor("application").send("showSpinner");
          var data = this.get("checkoutWorkflow") === "manual" ? {
            pin: this.get("checkoutCommon.pin").length > 0 ? this.get("checkoutCommon.pin") : null,
            receiver: this.get("checkoutCommon.receiver"),
            identityType: this.get("checkoutCommon.idType"),
            identityLast4: this.get("checkoutCommon.idLast4"),
            reason: this.get("manualCheckout.reason"),
            phone: this.get("checkoutCommon.phone"),
            articles: this.get("manualCheckout.consignment.articles").map(function (a) {
              return {
                barcode: a.barcode
              };
            }),
            signature: this.get("checkoutCommon.signature").length > 0 ? this.get("checkoutCommon.signature") : null
          } : {
            receiver: this.get("checkoutCommon.receiver"),
            identityType: this.get("checkoutCommon.idType"),
            identityLast4: this.get("checkoutCommon.idLast4"),
            pin: this.get("checkoutCommon.pin").length > 0 ? this.get("checkoutCommon.pin") : null,
            articles: this.get("regularCheckout.articles").map(function (a) {
              return {
                barcode: a.barcode
              };
            }),
            signature: this.get("checkoutCommon.signature").length > 0 ? this.get("checkoutCommon.signature") : null
          };
          (0, _icAjax["default"])({
            url: self.get("config.apiURL") + "consignments/" + consignmentId + "/checkout",
            type: "post",
            headers: {
              Authorization: "Bearer " + self.get("session.data").authenticated.jwt
            },
            data: JSON.stringify(data),
            contentType: "application/json",
            crossDomain: true
          }).then(function () /*result*/{
            self.controllerFor("application").send("showSuccess");
            self.controllerFor("application").send("hideSpinner", 850);
            self.set("showCheckoutConfirmation", false);
            self.send("reload");
          }, function () /*error*/{
            self.controllerFor("application").send("showError");
            self.controllerFor("application").send("hideSpinner", 850);
          });
        } else if (this.get("checkoutWorkflow") === "emPod") {
          self.controllerFor("application").send("showSpinner");
          (0, _icAjax["default"])({
            url: self.get("config.apiURL") + "v2/consignments/manualCheckout",
            type: "post",
            headers: {
              Authorization: "Bearer " + self.get("session.data").authenticated.jwt
            },
            data: JSON.stringify({
              consignmentDetails: {
                consignmentNumber: this.get("emPodDetails.consignmentNumber"),
                courier: this.get("emPodDetails.courier"),
                articles: this.get("emPodDetails.articles").map(function (a) {
                  return [a.barcode];
                }),
                receiverName: this.get("checkoutCommon.receiver"),
                receiverAddress: this.get("emPodDetails.receiver_address"),
                receiverPhone: this.get("emPodDetails.pin_phone"),
                receiverEmail: null
              },
              checkoutDetails: {
                receiver: this.get("checkoutCommon.receiver"),
                identityType: "Other",
                identityLast4: "9999",
                signature: this.get("checkoutCommon.signature").length > 0 ? this.get("checkoutCommon.signature") : null,
                pin: this.get("checkoutCommon.pin").length > 0 ? this.get("checkoutCommon.pin") : null
              }
            }),
            contentType: "application/json",
            crossDomain: true
          }).then(function () /*result*/{
            self.controllerFor("application").send("showSuccess");
            self.controllerFor("application").send("hideSpinner", 850);
            self.set("showCheckoutConfirmation", false);
            self.send("reload");
          }, function () /*error*/{
            self.controllerFor("application").send("showError");
            self.controllerFor("application").send("hideSpinner", 850);
          });
        }
      },

      onQuickCheckin: function onQuickCheckin(consignment) {
        var self = this;
        self.controllerFor("application").send("showSpinner");
        var acceptCheckInData = { articleBarcode: null, ecpId: null };
        if (consignment.ebayData && consignment.ebayData.ecpId) {
          acceptCheckInData.ecpId = consignment.ebayData.ecpId;
        }
        consignment.articles.forEach(function (a) {
          acceptCheckInData.articleBarcode = a.barcode;
          (0, _icAjax["default"])({
            url: self.get("config.apiURL") + "articles/checkin",
            type: "POST",
            headers: {
              Authorization: "Bearer " + self.get("session.data").authenticated.jwt
            },
            data: JSON.stringify(acceptCheckInData),
            contentType: "application/json",
            crossDomain: true
          }).then(function () /*result*/{
            self.send("getPage", 1);
            self.controllerFor("application").send("showSuccess");
            self.controllerFor("application").send("hideSpinner", 850);
          }, function () /*error*/{
            self.controllerFor("application").send("hideSpinner", 0);
          });
        });
      },

      onQuickCheckinBarcode: function onQuickCheckinBarcode(barcode) {
        var self = this;
        self.controllerFor("application").send("showSpinner");
        var acceptCheckInData = { articleBarcode: null, ecpId: null };
        acceptCheckInData.articleBarcode = barcode;
        (0, _icAjax["default"])({
          url: self.get("config.apiURL") + "articles/checkin",
          type: "POST",
          headers: {
            Authorization: "Bearer " + self.get("session.data").authenticated.jwt
          },
          data: JSON.stringify(acceptCheckInData),
          contentType: "application/json",
          crossDomain: true
        }).then(function () /*result*/{
          self.send("getPage", 1);
          self.controllerFor("application").send("showSuccess");
          self.controllerFor("application").send("hideSpinner", 850);
        }, function () /*error*/{
          self.controllerFor("application").send("hideSpinner", 0);
        });
      },

      reload: function reload() {
        var self = this;
        this.set("cardNumber", "");
        var urlStr = self.get("config.apiURL") + "consignments?state=awaitingCustomerPickup,notYetCheckedIn,awaitingCourierPickup&page=1&perPage=" + this.get("perPage") + "&limited=true&orderBy=time_waiting&orderDir=DESC";
        if (this.get("filterName")) {
          urlStr += "&name=" + encodeURIComponent(this.get("filterName"));
        }
        (0, _icAjax["default"])({
          url: urlStr,
          type: "get",
          headers: {
            Authorization: "Bearer " + self.get("session.data").authenticated.jwt
          },
          contentType: "application/json",
          crossDomain: true
        }).then(function (consignments) {
          for (var i = 0; i < consignments.data.length; i++) {
            var art = consignments.data[i].articles;
            for (var j = 0; j < art.length; j++) {
              var state = art[j].currentState.state;
              consignments.data[i].currentState = state;
            }
          }
          console.log(consignments.data);
          self.set("consignments", consignments.data);
          self.set("page", consignments.page);
          self.set("totalPages", consignments.totalPages);
          self.set("consignmentNumber", "");
        })["catch"](function (error) {
          if (error && error.jqXHR && error.jqXHR.status === 401) {
            self.get("session").invalidate();
          }
        });
      }
    }
  });
});