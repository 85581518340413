define("client/controllers/international/book", ["exports", "ic-ajax", "client/utils/countries-list"], function (exports, _icAjax, _countriesList) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = Ember.Controller.extend({
    session: Ember.inject.service(),
    config: Ember.inject.service("config"),
    state: "getQuotes",
    // "getQuotes", "hasQuotes",
    // "bookingIdCheck", "senderDetails",
    // "deliveryDetails", "customsDetails",
    // "customsDeclaration", "confirm", "payment", "print"

    countries: _countriesList.countries,

    //Step 0: Get Quote
    quoteEnquiry: {
      country: null,
      suburb: "",
      state: "",
      postcode: "",
      articles: [{ type: "Satchel M" }]
    },
    quoteStepValid: (function () {
      return this.get("quoteEnquiry.country") && this.get("quoteEnquiry.country") !== null && this.get("quoteEnquiry.suburb") && this.get("quoteEnquiry.suburb").length > 0 && this.get("quoteEnquiry.state") && this.get("quoteEnquiry.state").length > 0 && this.get("quoteEnquiry.postcode") && this.get("quoteEnquiry.postcode").length > 0;
    }).property("quoteEnquiry.country", "quoteEnquiry.suburb", "quoteEnquiry.state", "quoteEnquiry.postcode"),

    packageTypes: ["Satchel M", "Satchel L", "Satchel XL", "Box XS", "Box S", "Box M", "Box L", "Box XL", "Box XXL"],

    //Step 1: Display Quotes
    quotes: [],

    currentQuote: null,
    transactionId: null,

    //Step 2: Capture Id
    id: {
      base64Image: "",
      captured: false,
      loaded: false
    },

    //Step 3: Sender Details
    sender: {
      type: "residential",
      businessName: "",
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      streetAddress: "",
      state: "",
      suburb: "",
      postcode: "",
      country: { iso: "AU", country: "AUSTRALIA" }
    },

    senderPhoneUpdated: Ember.observer("sender.phone", function () {
      this.set("sender.phoneErrors", this.get("errorForPhoneField")(this.get("sender.phone")));
    }),
    senderEmailUpdated: Ember.observer("sender.email", function () {
      this.set("sender.emailErrors", this.get("errorForEmailField")(this.get("sender.email")));
    }),

    senderStepValid: (function () {
      return this.get("sender.firstNameErrors").length === 0 && this.get("sender.lastNameErrors").length === 0 && this.get("sender.emailErrors").length === 0 && this.get("sender.phoneErrors").length === 0 && this.get("sender.streetAddressErrors").length === 0 && this.get("sender.postcodeErrors").length === 0 && this.get("sender.stateErrors").length === 0 && this.get("sender.suburbErrors").length === 0 && this.get("sender.countryErrors").length === 0 && this.get("sender.businessNameErrors").length === 0;
    }).property("sender.firstNameErrors", "sender.lastNameErrors", "sender.emailErrors", "sender.phoneErrors", "sender.streetAddressErrors", "sender.postcodeErrors", "sender.stateErrors", "sender.suburbErrors", "sender.countryErrors", "sender.businessNameErrors"),

    //Step 4: Delivery Details
    deliveryDetails: {
      type: "residential",
      businessName: "",
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      streetAddress: "",
      specialInstructions: ""
    },

    deliveryDetailsPhoneUpdated: Ember.observer("deliveryDetails.phone", function () {
      this.set("deliveryDetails.phoneErrors", this.get("errorForPhoneField")(this.get("deliveryDetails.phone")));
    }),
    deliveryDetailsEmailUpdated: Ember.observer("deliveryDetails.email", function () {
      this.set("deliveryDetails.emailErrors", this.get("errorForEmailField")(this.get("deliveryDetails.email")));
    }),

    deliveryDetailsStepValid: (function () {
      return this.get("deliveryDetails.firstNameErrors").length === 0 && this.get("deliveryDetails.lastNameErrors").length === 0 && this.get("deliveryDetails.emailErrors").length === 0 && this.get("deliveryDetails.phoneErrors").length === 0 && this.get("deliveryDetails.streetAddressErrors").length === 0 && this.get("deliveryDetails.businessNameErrors").length === 0;
    }).property("deliveryDetails.firstNameErrors", "deliveryDetails.lastNameErrors", "deliveryDetails.emailErrors", "deliveryDetails.phoneErrors", "deliveryDetails.streetAddressErrors", "deliveryDetails.businessNameErrors"),

    //Step 5: Customs Declaration
    shipmentTypes: ["Document", "Gift", "Merchandise", "Sample", "Other"],
    exportTypes: ["Permanent", "Temporary", "ReExport"],

    customsDetails: {
      shipmentType: "",
      exportType: "Permanent",
      natureOfGoods: "",
      insurance: false,
      items: [{
        description: "",
        country: "",
        hsCode: "",
        quantity: 1,
        unitPrice: 0
      }]
    },

    declaration: false,
    declaration1: false,

    errorForValidatedField: function errorForValidatedField(length, field) {
      if (field.length === 0) {
        return ["Required Field"];
      } else if (field.length > length) {
        return ["Field must be less than " + length + " characters"];
      } else {
        return [];
      }
    },

    errorForValidatedFieldWithRegex: function errorForValidatedFieldWithRegex(length, field, regex) {
      if (field.length === 0) {
        return ["Required Field"];
      } else if (field.length > length) {
        return ["Field must be less than " + length + " characters"];
      } else if (!regex.test(field)) {
        return ["Field contains disallowed characters"];
      } else {
        return [];
      }
    },

    errorForPhoneField: function errorForPhoneField(field) {
      if (/ /.test(field)) {
        return ["Please enter valid Phone no. without spaces eg: 029999999999"];
      } else if (field.length > 0 && !/^(\d){1,20}$/.test(field)) {
        return ["Please enter valid Phone no. eg: 029999999999"];
      } else {
        return [];
      }
    },

    errorForEmailField: function errorForEmailField(field) {
      //CP Standard REGEX
      var emailRegex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

      if (field.length > 0 && !emailRegex.test(field)) {
        return ["Please enter valid email. eg: email@example.com"];
      } else {
        return [];
      }
    },

    actions: {

      addQuoteArticle: function addQuoteArticle() {
        this.get("quoteEnquiry.articles").pushObject({ type: "Satchel M" });
      },

      removeQuoteArticle: function removeQuoteArticle() {
        if (this.get("quoteEnquiry.articles").length > 1) {
          this.get("quoteEnquiry.articles").popObject();
        }
      },

      getQuotes: function getQuotes() {
        if (this.get("quoteStepValid")) {
          this.controllerFor("application").send("showSpinner", "Processing");
          var self = this;
          var articles = [];
          this.get("quoteEnquiry").articles.forEach(function (a) {
            articles.pushObject({ type: a.type });
          });
          var quote = {
            country: this.get("quoteEnquiry.country.country"),
            countryCode: this.get("quoteEnquiry.country.iso"),
            suburb: this.get("quoteEnquiry.suburb"),
            postcode: this.get("quoteEnquiry.postcode"),
            articles: articles
          };
          (0, _icAjax["default"])({
            url: self.get("config.apiURL") + "v2/outbound/international/quote",
            type: "post",
            headers: {
              Authorization: "Bearer " + this.get("session.data").authenticated.jwt
            },
            contentType: "application/json",
            data: JSON.stringify(quote),
            crossDomain: true,
            timeout: 300000
          }).then(function (response) {
            self.set("state", "hasQuotes");
            self.set("quotes", response.items);
            self.set("transactionId", response.id.value);
            self.controllerFor("application").send("hideSpinner", 1000);
          }, function () {
            self.controllerFor("application").send("showError", "Error Invalid Details");
            self.controllerFor("application").send("hideSpinner", 1000);
          })["catch"](function (error) {
            if (error && error.jqXHR && error.jqXHR.status === 401) {
              self.get("session").invalidate();
            }
          });
        } else {
          if (!(this.get("quoteEnquiry.suburb") && this.get("quoteEnquiry.suburb").length > 0)) {
            this.set("quoteEnquiry.suburbError", ["Required Field"]);
          }
          if (!(this.get("quoteEnquiry.postcode") && this.get("quoteEnquiry.postcode").length > 0)) {
            this.set("quoteEnquiry.postcodeError", ["Required Field"]);
          }
          if (!(this.get("quoteEnquiry.state") && this.get("quoteEnquiry.state").length > 0)) {
            this.set("quoteEnquiry.stateError", ["Required Field"]);
          }
          if (!this.get("quoteEnquiry.country")) {
            this.set("quoteEnquiry.countryError", ["Required Field"]);
          }
        }
      },

      bookQuote: function bookQuote(quote) {
        this.set("currentQuote", quote);
        this.set("state", "bookingIdCheck");
      },

      printID: function printID() {
        var self = this;
        self.controllerFor("application").send("showSpinner", "Processing");
        var request = {
          imageBase64Png: this.get("id.base64Image")
        };
        (0, _icAjax["default"])({
          url: self.get("config.apiURL") + "v2/outbound/international/" + this.get("transactionId") + "/senderId",
          type: "post",
          headers: {
            Authorization: "Bearer " + this.get("session.data").authenticated.jwt
          },
          contentType: "application/json",
          data: JSON.stringify(request),
          crossDomain: true,
          timeout: 300000
        }).then(function () {
          self.set("id.loaded", true);
          self.controllerFor("application").send("hideSpinner", 1000);
        }, function () {
          self.controllerFor("application").send("showError", "Error Invalid Details");
          self.controllerFor("application").send("hideSpinner", 1000);
        });
      },

      finishedId: function finishedId() {
        this.set("state", "senderDetails");
      },

      goToSenderDetails: function goToSenderDetails() {
        this.set("state", "senderDetails");
      },

      saveSenderDetails: function saveSenderDetails() {
        this.set("sender.firstNameErrors", this.get("errorForValidatedFieldWithRegex")(50, this.get("sender.firstName"), /^[a-zA-Z' \-]*$/));
        this.set("sender.lastNameErrors", this.get("errorForValidatedFieldWithRegex")(50, this.get("sender.lastName"), /^[a-zA-Z' \-]*$/));
        this.set("sender.emailErrors", this.get("errorForValidatedField")(250, this.get("sender.email")).concat(this.get("errorForEmailField")(this.get("sender.email"))));
        this.set("sender.phoneErrors", this.get("errorForValidatedField")(20, this.get("sender.phone")).concat(this.get("errorForPhoneField")(this.get("sender.phone"))));
        this.set("sender.streetAddressErrors", this.get("errorForValidatedFieldWithRegex")(35, this.get("sender.streetAddress"), /^[a-zA-Z0-9,#'~* _\-;+]*$/));
        this.set("sender.postcodeErrors", this.get("errorForValidatedFieldWithRegex")(10, this.get("sender.postcode"), /^(0[289][0-9]{2})|([1345689][0-9]{3})|(2[0-8][0-9]{2})|(290[0-9])|(291[0-4])|(7[0-4][0-9]{2})|(7[8-9][0-9]{2})$/));
        this.set("sender.stateErrors", this.get("errorForValidatedField")(50, this.get("sender.state")));
        this.set("sender.suburbErrors", this.get("errorForValidatedField")(50, this.get("sender.suburb")));

        this.set("sender.countryErrors", this.get("sender.country") ? [] : ["Required Field"]);

        if (this.get("sender.type") === "business") {
          this.set("sender.businessNameErrors", this.get("errorForValidatedFieldWithRegex")(250, this.get("sender.businessName"), /^[a-zA-Z0-9,.@&#' \-+]*$/));
        } else {
          this.set("sender.businessNameErrors", []);
        }

        if (this.get("senderStepValid")) {
          this.set("state", "deliveryDetails");
        }
      },

      saveDeliveryDetails: function saveDeliveryDetails() {
        this.set("deliveryDetails.firstNameErrors", this.get("errorForValidatedFieldWithRegex")(50, this.get("deliveryDetails.firstName"), /^[a-zA-Z' \-]*$/));
        this.set("deliveryDetails.lastNameErrors", this.get("errorForValidatedFieldWithRegex")(50, this.get("deliveryDetails.lastName"), /^[a-zA-Z' \-]*$/));
        this.set("deliveryDetails.emailErrors", this.get("errorForValidatedField")(250, this.get("deliveryDetails.email")).concat(this.get("errorForEmailField")(this.get("deliveryDetails.email"))));
        this.set("deliveryDetails.phoneErrors", this.get("errorForValidatedField")(20, this.get("deliveryDetails.phone")).concat(this.get("errorForPhoneField")(this.get("deliveryDetails.phone"))));
        this.set("deliveryDetails.streetAddressErrors", this.get("errorForValidatedFieldWithRegex")(35, this.get("deliveryDetails.streetAddress"), /^[a-zA-Z0-9,#'~* _\-;+]*$/));

        if (this.get("deliveryDetails.type") === "business") {
          this.set("deliveryDetails.businessNameErrors", this.get("errorForValidatedFieldWithRegex")(250, this.get("deliveryDetails.businessName"), /^[a-zA-Z0-9,.@&#' \-+]*$/));
        } else {
          this.set("deliveryDetails.businessNameErrors", []);
        }

        if (this.get("deliveryDetailsStepValid")) {
          this.set("state", "customsDetails");
        }
      },

      addCustomsItem: function addCustomsItem() {
        if (this.get("customsDetails.items").length < 4) {
          this.get("customsDetails.items").pushObject({
            description: "",
            country: null,
            hsCode: "",
            quantity: 1,
            unitPrice: 0,
            subTotal: 0
          });
        }
      },

      removeCustomsItem: function removeCustomsItem() {
        if (this.get("customsDetails.items").length > 1) {
          this.get("customsDetails.items").popObject();
        }
      },

      closeCustomsDeclaration: function closeCustomsDeclaration() {
        this.set("state", "customsDetails");
      },

      cancelDeclaration: function cancelDeclaration() {
        this.set("state", "customsDetails");
      },

      customsDeclaration: function customsDeclaration() {
        var shipmentTypeInValid = this.get("shipmentTypes").indexOf(this.get("customsDetails.shipmentType")) < 0;
        var exportTypeInValid = this.get("exportTypes").indexOf(this.get("customsDetails.exportType")) < 0;
        this.set("shipmentTypeError", []);
        this.set("exportTypeError", []);

        var validCustomsDetails = true;

        this.get("customsDetails.items").forEach(function (a) {
          var itemValid = a.description && a.description.length > 0 && a.quantity && parseInt(a.quantity) > 0 && a.country && a.country.iso.length > 0 && a.unitPrice && parseFloat(a.unitPrice) > 0;
          if (itemValid) {
            Ember.set(a, "error", null);
          } else {
            Ember.set(a, "error", "Invalid Item Declaration");
          }
          validCustomsDetails = validCustomsDetails && itemValid;
        });

        if (shipmentTypeInValid || exportTypeInValid || !validCustomsDetails) {
          if (shipmentTypeInValid) {
            this.set("shipmentTypeError", ["Shipment Type Invalid"]);
          }
          if (exportTypeInValid) {
            this.set("exportTypeError", ["Export Type Invalid"]);
          }
        } else {
          this.set("state", "customsDeclaration");
        }
      },

      closeConfirmModal: function closeConfirmModal() {
        this.set("state", "customsDeclaration");
      },

      cancelOrder: function cancelOrder() {
        this.set("state", "customsDeclaration");
      },

      acceptDeclaration: function acceptDeclaration() {
        this.set("declaration", false);
        this.set("declaration1", false);
        this.set("state", "confirm");
      },

      confirmPayment: function confirmPayment() {
        this.controllerFor("application").send("showSpinner", "Processing Order");
        var self = this;
        var articles = [];
        this.get("quoteEnquiry").articles.forEach(function (a) {
          articles.pushObject({ type: a.type });
        });
        var customs = [];
        this.get("customsDetails.items").forEach(function (a) {
          customs.pushObject({
            itemDescription: a.description,
            numItems: parseInt(a.quantity),
            countryOfOrigin: a.country.iso,
            unitPrice: parseFloat(a.unitPrice),
            HSCode: a.hsCode
          });
        });
        var createRequest = {
          contact: {
            isBusiness: this.get("sender.type") === "business",
            companyName: this.get("sender.businessName"),
            firstName: this.get("sender.firstName"),
            lastName: this.get("sender.lastName"),
            phone: this.get("sender.phone"),
            email: this.get("sender.email"),
            address1: this.get("sender.streetAddress"),
            state: this.get("sender.state"),
            suburb: this.get("sender.suburb"),
            postcode: this.get("sender.postcode"),
            country: this.get("sender.country.country"),
            countryCode: this.get("sender.country.iso")
          },
          destination: {
            isBusiness: this.get("deliveryDetails.type") === "business",
            companyName: this.get("deliveryDetails.businessName"),
            firstName: this.get("deliveryDetails.firstName"),
            lastName: this.get("deliveryDetails.lastName"),
            phone: this.get("deliveryDetails.phone"),
            email: this.get("deliveryDetails.email"),
            address1: this.get("deliveryDetails.streetAddress"),
            state: this.get("quoteEnquiry.state"),
            suburb: this.get("quoteEnquiry.suburb"),
            postcode: this.get("quoteEnquiry.postcode"),
            country: this.get("quoteEnquiry.country.country"),
            countryCode: this.get("quoteEnquiry.country.iso")
          },
          insurance: false,
          natureOfGoods: this.get("customsDetails.natureOfGoods"),
          rateCardId: this.get("currentQuote.code"),
          shipmentType: this.get("customsDetails.shipmentType"),
          specialInstructions: this.get("deliveryDetails.specialInstructions"),
          typeOfExport: this.get("customsDetails.exportType"),
          items: articles,
          customsDeclarations: customs
        };

        //Check Auth
        var quickAuthData = {
          dailyCode: self.get("quickAuth")
        };
        (0, _icAjax["default"])({
          url: self.get("config.apiURL") + "dailyCode",
          type: "POST",
          headers: {
            Authorization: "Bearer " + self.get("session.data").authenticated.jwt
          },
          data: JSON.stringify(quickAuthData),
          contentType: "application/json",
          crossDomain: true,
          timeout: 300000
        }).then(function () /*result*/{
          (0, _icAjax["default"])({
            url: self.get("config.apiURL") + "v2/outbound/international/" + self.get("transactionId") + "/create",
            type: "post",
            headers: {
              Authorization: "Bearer " + self.get("session.data").authenticated.jwt
            },
            contentType: "application/json",
            data: JSON.stringify(createRequest),
            crossDomain: true,
            timeout: 300000
          }).then(function (response) {
            self.set("state", "print");
            self.set("completedConsignment", response);
            self.controllerFor("application").send("hideSpinner", 1000);
          }, function () {
            self.controllerFor("application").send("showError", "Error");
            self.controllerFor("application").send("hideSpinner", 1000);
          });
        }, function () /*error*/{
          self.set("quickAuth", "");
          self.controllerFor("application").send("showError", "Incorrect Auth Code Entered");
          self.controllerFor("application").send("hideSpinner", 1200);
        });
      },

      confirmOrder: function confirmOrder() {
        this.set("state", "payment");
      },

      cancel: function cancel() {
        this.set("quickAuth", "");
        this.set("quoteEnquiry", {
          country: null,
          suburb: "",
          state: "",
          postcode: "",
          articles: [{ type: "Satchel M" }]
        });
        this.set("quotes", []);
        this.set("currentQuote", null);
        this.set("transactionId", null);
        this.set("id", {
          base64Image: "",
          captured: false,
          loaded: false
        });
        this.set("sender", {
          type: "residential",
          businessName: "",
          firstName: "",
          lastName: "",
          phone: "",
          email: "",
          streetAddress: "",
          state: "",
          suburb: "",
          postcode: "",
          country: null
        });
        this.set("deliveryDetails", {
          type: "residential",
          businessName: "",
          firstName: "",
          lastName: "",
          phone: "",
          email: "",
          streetAddress: "",
          specialInstructions: ""
        });
        this.set("customsDetails", {
          shipmentType: "",
          exportType: "Permanent",
          natureOfGoods: "",
          insurance: false,
          items: [{
            description: "",
            country: null,
            hsCode: "",
            quantity: 1,
            unitPrice: 0
          }]
        });
        this.set("state", "");
        this.set("state", "getQuotes");
        this.set("shipmentTypeError", []);
        this.set("exportTypeError", []);
      },

      printComplete: function printComplete() {
        this.send("cancel");
        this.transitionTo("international.archive");
      },

      closePaymentModal: function closePaymentModal() {
        this.set("state", "customsDetails");
      },

      closeBookingIdCheck: function closeBookingIdCheck() {
        this.set("state", "hasQuotes");
      }

    }
  });
});