define("client/authenticators/jwt", ["exports", "ember-simple-auth/authenticators/base", "npm:jwt-decode", "ic-ajax"], function (exports, _base, _npmJwtDecode, _icAjax) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = Ember.RSVP;
  exports["default"] = _base["default"].extend({
    config: Ember.inject.service("config"),

    refreshLeeway: 10000, //Refresh 10 seconds before expiry
    refreshTokenTimeout: null,

    restore: function restore(data) {
      var _this = this;

      var dataObject = Ember.Object.create(data);

      return new RSVP.Promise(function (resolve, reject) {
        var now = _this.getCurrentTime();
        var jwtPayload = (0, _npmJwtDecode["default"])(dataObject.get("jwt"));
        var expiresAt = jwtPayload.exp * 1000;

        if (expiresAt > now + _this.refreshLeeway) {
          var wait = expiresAt - now - _this.refreshLeeway;

          if (wait > 0) {
            _this.scheduleJWTRefresh(expiresAt);
            resolve(data);
          } else {
            reject(new Error("unable to refresh JWT"));
          }
        } else {
          reject(new Error("JWT is expired"));
        }
      });
    },
    authenticate: function authenticate(identification, password) {
      var _this2 = this;

      return new RSVP.Promise(function (resolve, reject) {
        var data = {
          email: identification,
          password: password
        };
        var self = _this2;

        (0, _icAjax["default"])({
          url: _this2.get("config.apiURL") + "v2/login",
          type: "POST",
          data: JSON.stringify(data),
          contentType: "application/json",
          crossDomain: true
        }).then(function (sessionData) {
          var newData = self.validateNewApplicationToken(sessionData);
          Ember.run(null, resolve, newData);
        }, function (error) {
          if (error.jqXHR.status === 401) {
            Ember.run(null, reject, "Invalid Credentials");
          } else if (error.jqXHR.status === 429) {
            Ember.run(null, reject, "You have been locked out of the system after too many failed login attempts. This will reset in 30 minutes.");
          } else {
            Ember.run(null, reject, "Connection Failed");
          }
        })["catch"](function () {
          Ember.run(null, reject, "Connection Failed");
        });
      });
    },
    invalidate: function invalidate() {
      Ember.run.cancel(this._refreshTokenTimeout);
      delete this._refreshTokenTimeout;
      return RSVP.resolve();
    },
    validateNewApplicationToken: function validateNewApplicationToken(sessionData) {
      var _this3 = this;

      return new RSVP.Promise(function (resolve, reject) {
        _this3.processAuthResponse(sessionData);
        var newSessionData = sessionData;

        (0, _icAjax["default"])({
          url: _this3.get("config.apiURL") + "v2/admin/auth/validateToken",
          type: "GET",
          headers: { Authorization: "Bearer " + sessionData.jwt },
          contentType: "application/json",
          crossDomain: true
        }).then(function (response) {
          var responseData = JSON.parse(response);
          newSessionData.dotnet_auth = responseData.responseMessage;
          resolve(newSessionData);
        }, function (error) {
          resolve(newSessionData);
        })["catch"](function () {
          resolve(newSessionData);
        });
      });
    },
    processAuthResponse: function processAuthResponse(sessionData) {
      var jwtPayload = (0, _npmJwtDecode["default"])(sessionData.jwt);
      var expiresAt = jwtPayload.exp * 1000;
      this.scheduleJWTRefresh(expiresAt);
    },
    refreshJWT: function refreshJWT() {
      var self = this;
      (0, _icAjax["default"])({
        url: this.get("config.apiURL") + "v2/refreshToken",
        type: "POST",
        crossDomain: true
      }).then(function (sessionData) {
        self.processAuthResponse(sessionData);
        self.trigger("sessionDataUpdated", sessionData);
      }, function () {
        self.trigger("sessionDataInvalidated");
      });
    },
    scheduleJWTRefresh: function scheduleJWTRefresh(expiresAt) {
      var now = this.getCurrentTime();
      var wait = expiresAt - now - this.refreshLeeway;

      if (wait > 0) {
        Ember.run.cancel(this._refreshTokenTimeout);
        delete this._refreshTokenTimeout;
        this._refreshTokenTimeout = Ember.run.later(this, this.refreshJWT, wait);
      }
    },
    getCurrentTime: function getCurrentTime() {
      return new Date().getTime();
    }
  });
});