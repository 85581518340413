define("client/routes/dashboard", ["exports", "ic-ajax", "ember-simple-auth/mixins/authenticated-route-mixin"], function (exports, _icAjax, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = Ember.Route.extend(_authenticatedRouteMixin["default"], {
    config: Ember.inject.service(),
    session: Ember.inject.service("session"),

    setupController: function setupController(controller) {
      var session = this.get("session");
      controller.set("userAbilities", this.controllerFor("application").get("userAbilities"));

      // Fetch dashboard data
      (0, _icAjax["default"])({
        url: this.get("config.apiURL") + "dashboard",
        type: "get",
        headers: {
          Authorization: "Bearer " + this.get("session.data").authenticated.jwt
        },
        crossDomain: true
      }).then(function (results) {
        controller.set("dashboard", results);
        controller.set("dashboardLoading", false);
        session.set("data.country", results.dropLocation.address.country);
        session.set("data.printAtStore", results.dropLocation.droplocationAdditionalAttributes[0].printAtStore);
        if (results && results.dropLocation && results.dropLocation.dropLocationProduct && results.dropLocation.dropLocationProduct.length > 0) {
          session.set("data.userProducts", results.dropLocation.dropLocationProduct);
        }
      })["catch"](function (error) {
        if (error && error.jqXHR && error.jqXHR.status === 401) {
          session.invalidate();
        }
      });

      // Fetch consignments data
      (0, _icAjax["default"])({
        url: this.get("config.apiURL") + "consignments?state=awaitingCustomerPickup,notYetCheckedIn,awaitingCourierPickup&page=" + controller.get("page") + "&perPage=" + controller.get("perPage") + "&limited=true",
        type: "get",
        headers: {
          Authorization: "Bearer " + this.get("session.data").authenticated.jwt
        },
        crossDomain: true
      }).then(function (result) {
        controller.set("consignments", result.data);
        controller.set("page", result.page);
        controller.set("totalPages", result.totalPages);
        controller.set("consignmentsLoading", false);
      })["catch"](function (error) {
        if (error && error.jqXHR && error.jqXHR.status === 401) {
          session.invalidate();
        }
      });
    }
  });
});