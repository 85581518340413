define("client/components/video-tag", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = Ember.Component.extend({
    tagName: "video",
    attributeBindings: ["autoplay", "src", "id", "type", "muted"],
    src: "",
    autoplay: true,
    muted: true,
    count: 0,

    ended: function ended(event) {
      if (this.get("count") > 1) {
        this.sendAction("endedAction", event);
      } else {
        this.$()[0].play();
      }
    },

    didInsertElement: function didInsertElement() {
      this.$().on("ended", Ember.$.proxy(this.ended, this));
    },

    changed: (function () /*event*/{
      if (this.get("autoplay")) {
        this.$()[0].load();
      }
    }).observes("src")

  });
});