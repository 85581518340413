define("client/controllers/reports/checkin", ["exports", "moment", "ic-ajax"], function (exports, _moment, _icAjax) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = Ember.Controller.extend({
    config: Ember.inject.service("config"),
    session: Ember.inject.service(),

    url: Ember.computed(function () {
      var start = (0, _moment["default"])().startOf("day").subtract(30, "days").unix();
      var end = (0, _moment["default"])().endOf("day").unix();
      return this.get("config.apiURL") + "consignments/report/print?states=awaitingCustomerPickup,notYetCheckedIn&startDate=" + start + "&endDate=" + end + "&token=" + this.get("session.data").authenticated.jwt;
    }),

    queryParams: ["page", "perPage"],
    page: 1,
    perPage: 8,
    totalPages: 1,
    report: null,

    actions: {
      getPage: function getPage(page) {
        var self = this;
        var start = (0, _moment["default"])().startOf("day").subtract(30, "days").unix();
        var end = (0, _moment["default"])().endOf("day").unix();
        (0, _icAjax["default"])({
          url: self.get("config.apiURL") + "consignments/report?states=awaitingCustomerPickup,notYetCheckedIn&startDate=" + start + "&endDate=" + end + "&page=" + page + "&perPage=" + this.get("perPage"),
          type: "get",
          headers: {
            Authorization: "Bearer " + self.get("session.data").authenticated.jwt
          },
          crossDomain: true
        }).then(function (result) {
          self.set("report", result);
          self.set("page", result.consignments.page);
          self.set("totalPages", result.consignments.totalPages);
        })["catch"](function (error) {
          if (error && error.jqXHR && error.jqXHR.status === 401) {
            self.get("session").invalidate();
          }
        });
      }
    }

  });
});