define("client/components/js-barcode", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports["default"] = Ember.Component.extend({
    tagName: "canvas",
    classNames: "js-barcode",
    value: null,

    //Options
    width: 2,
    height: 100,
    quite: 10,
    format: "CODE128",
    displayValue: true,
    font: "serif",
    textAlign: "center",
    fontSize: 12,
    backgroundColor: "",
    lineColor: "#000",

    onValueChange: (function () {
      var self = this;
      var options = {
        width: self.get("width"),
        height: self.get("height"),
        quite: self.get("quite"),
        format: self.get("format"),
        displayValue: self.get("displayValue"),
        font: self.get("font"),
        textAlign: self.get("textAlign"),
        fontSize: self.get("fontSize"),
        backgroundColor: self.get("backgroundColor"),
        lineColor: self.get("lineColor")
      };

      //Set up jsBarcode
      self._jsBarcode = self.$().JsBarcode(self.get("value"), options);
    }).observes("value"),

    didInsertElement: function didInsertElement() {
      var self = this;
      var options = {
        width: self.get("width"),
        height: self.get("height"),
        quite: self.get("quite"),
        format: self.get("format"),
        displayValue: self.get("displayValue"),
        font: self.get("font"),
        textAlign: self.get("textAlign"),
        fontSize: self.get("fontSize"),
        backgroundColor: self.get("backgroundColor"),
        lineColor: self.get("lineColor")
      };

      //Set up jsBarcode
      self._jsBarcode = self.$().JsBarcode(self.get("value"), options);
    }

  });
});