define("client/helpers/fa-icon", ["exports", "ember-cli-font-awesome/helpers/fa-icon"], function (exports, _faIcon) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function get() {
      return _faIcon["default"];
    }
  });
  Object.defineProperty(exports, "faIcon", {
    enumerable: true,
    get: function get() {
      return _faIcon.faIcon;
    }
  });
});